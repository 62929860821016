import React from 'react';
import Navbar2 from './common/Navbar2';
import Footer from './common/Footer';
import TitlePage from './common/TitlePage';
import { withTranslate } from 'react-redux-multilingual'

class UsPromo extends React.Component {

    render() {

        const margin = {
            marginTop: "-6px"
        }

        const { translate } = this.props;

        return <div className="absolute-nav">
            <Navbar2 />
            <TitlePage title={"Promotion"} />

            <div style={{ padding: "0", display: "flex", justifyContent: "center", color: "#203341", fontSize: "18px", fontWeight: 600, flexDirection: "column", alignItems: "center" }}>
                 <img src="/assets/img/us-promo.jpeg" width="100%" alt=""/>
            </div>
             <iframe id="JotFormIFrame-210775543548058" title="General Inquiry Contact Form" onload="window.parent.scrollTo(0,0)" allowtransparency="true" allowfullscreen="true" allow="geolocation; microphone; camera" src="https://form.jotform.com/210775543548058" frameborder="0" style={{minWidth: "100%", height:"539px", border:"none"}} scrolling="no" > </iframe>
            <div style={margin}>
                <Footer />
            </div>
        </div>
    }

}

export default withTranslate(UsPromo)