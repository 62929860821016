import React from 'react';
import Navbar2 from './common/Navbar2';
import Footer from './common/Footer';
import TitlePage from './common/TitlePage';
import { withTranslate } from 'react-redux-multilingual'

class About extends React.Component {

    render(){
        let publicUrl = process.env.PUBLIC_URL + '/'
        const containerVideo ={
            objectFit:"cover"
        }

        const margin={
            marginTop:"-6px"
        }

        const {translate} = this.props;

        return <div className="absolute-nav">
        <Navbar2 />
        <TitlePage title={translate('product.about')}/>
            <div>
                <div className="about  pt-5">
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-12">
                        </div>
                        <div className="col-md-6 col-sm-12 p-5">
                            <b><h2 className="title">{translate('aboutUs.title')}</h2></b>
                            <b><h3 className="subtitle">{translate('aboutUs.subTitle')}</h3></b>
                            <p>{translate('aboutUs.text1')}</p>
                            <p>{translate('aboutUs.text2')}</p>
                            <p>{translate('aboutUs.text3')}</p>
                            <p>{translate('aboutUs.text4')}</p>
                            <br/>
                            <div className="mt-5">
                                <p className="ceo">{translate('aboutUs.ceo')}</p>
                                <p className="ceo">{translate('aboutUs.ceoTitle')}</p>
                                <img src={publicUrl + 'assets/img/favicon.png'} className="img-about-us-finger" alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12">
                        </div>
                    </div>
                </div>
            </div>
            <video className="container-video col-12" src="assets/video/stilepersonale.mp4" controls autoPlay loop style={containerVideo}></video>
         <div style={margin}>
            <Footer/>
        </div>
    </div>
    }

}

export default withTranslate(About)