/** @format */

const ALL_FETCHING = "articles/ALL_FETCHING";
const ALL_FETCH_SUCCESS = "articles/ALL_FETCH_SUCCESS";
const ALL_FETCH_SUCCESS_SLIDER = "articles/ALL_FETCH_SUCCESS_SLIDER";
const ALL_FETCH_FAILURE = "articles/ALL_FETCH_FAILURE";

const MORE_ALL_FETCHING = "articles/MORE_ALL_FETCHING";
const MORE_ALL_FETCH_SUCCESS = "articles/MORE_ALL_FETCH_SUCCESS";
const MORE_ALL_FETCH_FAILURE = "articles/MORE_ALL_FETCH_FAILURE";

const FETCHING = "articles/FETCHING";
const FETCH_SUCCESS = "articles/FETCH_SUCCESS";
const FETCH_FAILURE = "articles/FETCH_FAILURE";

const MORE_FETCHING = "articles/MORE_FETCHING";
const MORE_FETCH_SUCCESS = "articles/MORE_FETCH_SUCCESS";
const MORE_FETCH_FAILURE = "articles/MORE_FETCH_FAILURE";

const CLEAN_PRODUCT = "articles/CLEAN";

export {
  ALL_FETCHING,
  ALL_FETCH_SUCCESS,
  ALL_FETCH_FAILURE,
  MORE_ALL_FETCHING,
  MORE_ALL_FETCH_SUCCESS,
  MORE_ALL_FETCH_FAILURE,
  FETCHING,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  MORE_FETCHING,
  MORE_FETCH_SUCCESS,
  MORE_FETCH_FAILURE,
  CLEAN_PRODUCT,
  ALL_FETCH_SUCCESS_SLIDER
};
