
/*******************************************
 ************** US STRIPE KEYS ****************
 *******************************************/

export const stripeSecretKey = process.env.SP_US_STRIPE_SK;
export const stripePublicKey = process.env.SP_US_STRIPE_PK;

/*******************************************
************** MX STRIPE KEYS ****************
*******************************************/

export const stripeMXSecretKey = process.env.SP_MX_STRIPE_SK;
export const stripeMXPublicKey = process.env.SP_MX_STRIPE_PK;


 /*******************************************
 ************** GiftUp KEYS ****************
 *******************************************/

export const giftUpAuthKey = "Bearer "+process.env.SP_GIFTHUP_AUTH_KEY;


 /*******************************************
 ************** Panama KEYS ****************
 *******************************************/

export const PAcclw = process.env.SP_PA_PAYMENT_CCLW;
export const PAkey = process.env.SP_PA_PAYMENT_KEY;


 /*******************************************
 ************** AFFIRM KEYS ****************
 *******************************************/


export const affirmPKey = process.env.SP_AFFIRM_PUBLIC_KEY;
