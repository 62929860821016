import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslate, IntlActions } from 'react-redux-multilingual';
import { connect } from 'react-redux';

class PostList extends Component {
  render() {
	const {translate,blogs} = this.props;
    return (
     <div className="blog margin-top-60 stone-go-top">
        <div className="container mb-5">
           { blogs.map((item,index) => {
               return(
                   <div className="row mt-4" key={index}>
                      <div className="col-lg-4 offset-lg-2">
                         <img className="img-blog" src={item.image.src} alt=""/>
                      </div>
                      <div className="col-lg-5">
                        <h3>{item.title}</h3>
                        <span className="paragraph">{item.content.substr(0, 400) + "..."}</span>
                        <Link to={`/single-blog/${item.id}`}><p className="read-more">Read more...</p></Link>
                      </div>
                   </div>
               )
             })
           }
        </div>
	 </div>
    )
  }
}


const mapStateToProps = (state) => {
    return {
      blogs: state.articles.list,
    }
};
  
export default connect(mapStateToProps)(withTranslate(PostList));
