import React, { Component } from 'react';
import { withTranslate } from 'react-redux-multilingual'
import ContactInfo from './ContactInfo'

class ContactForm extends Component {
	constructor(props){
		super(props);

		this.state = {
			subject:"",
			email:"",
			message:""
		}
	}

	onChange =(event)=>{
		this.setState({
			[event.target.name]: event.target.value
		});
	}

	sendData = (event)=>{
		event.preventDefault();
		alert('Asunto:' + this.state.subject + ', Email:' + this.state.email + ', Mensaje:' + this.state.message);
	}

    render() {
		const {translate} = this.props;

    return <div className="contact-form text-center padding-top-30 padding-bottom-80">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-6">
				    <div className="text-center mb-3 col-lg-10 offset-lg-2">
						<h4>{translate('contacto.title')}</h4>
			        </div>
			        <form className="collection-style-04">
			            <div className="form-group col-lg-10 offset-lg-2">
						  <select name="subject"  className="form-control" onChange={this.onChange} >
							<option value="" defaultValue>Subject</option> 
							<option value="opcion 1" >opcion 1</option>
							<option value="opcion 2">opcion 2</option>
					      </select>
			            </div>
			            <div className="form-group col-lg-10 offset-lg-2">
			              <input type="email" className="form-control" name="email" placeholder="Email contact*" onChange={this.onChange} />
			            </div>
			            <div className="form-group col-lg-10 offset-lg-2">
			              <textarea name="message" rows={8} placeholder="Message" defaultValue={""}  onChange={this.onChange}/>
			            </div>
						<div className="form-group col-lg-10 offset-lg-2">
					    	<button type="submit" className="btn btn-collection2" onClick={this.sendData}>{translate('contacto.buttonSend')}</button>
			            </div>
			        </form>
			      </div>
				  <div className="col-lg-6 mt-3">
				 	 <ContactInfo />
				  </div>
			    </div>
			  </div>
			</div>

        }
}

export default withTranslate(ContactForm)