import React, { Component } from 'react';
import Navbar from './common/Navbar';
import Footer from './common/FooterMobile';
import Background from './common/HomeBackground';
import CollectionSlider from './common/CollectionSlider';
import Slider from './common/Slider';
import AboutOurClothes from './common/aboutOurClothes';
import {
    urlSections, urlSectionsText,
} from "../../constants/proxy";
import { withTranslate } from 'react-redux-multilingual'

import { connect } from 'react-redux';

class Home extends Component {

    state = {
        showAffirm: false,
        sections: [],
        sectionsText: [],
        pais: "en_US"
    };
    componentDidMount() {

        let country = this.props.country;
        this.setState({ pais: country })
        let data = {
            device: window.innerWidth > 800 ? "desktop" : "mobile",
            country: country
        };

        fetch(urlSections, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        }).then(data => data.json())
            .then(info => this.setState({ sections: info.data }));

        fetch(urlSectionsText, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        }).then(data => data.json())
            .then(info => this.setState({ sectionsText: info.data }));


        if (window.location.origin === "https://www.stilepersonale.com" || window.location.origin === "http://localhost:3000") {
            this.setState({ showAffirm: true });
        }
    }


    render() {

        const { country } = this.props;
        const { pais, showAffirm, sections, sectionsText } = this.state;

        let TextoButton = "";
        sectionsText.forEach(sec => {
            if (sec.code === "TXTBOBA") {
                TextoButton = sec.texto;
            }
        });

        if (country !== pais) {
            let country = this.props.country;

            let data = {
                device: window.innerWidth > 800 ? "desktop" : "mobile",
                country: country
            };

            fetch(urlSections, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            }).then(data => data.json())
                .then(info => this.setState({ sections: info.data }));

            fetch(urlSectionsText, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            }).then(data => data.json())
                .then(info => this.setState({ sectionsText: info.data }));

            this.setState({ pais: country })

        }

        return <div>
            <Navbar sectionsText={sectionsText} />
            <Background sections={sections} sectionsText={sectionsText} />
            <div style={{ textAlign: "center" }}>
                <AboutOurClothes sections={sections} />
            </div>
            <CollectionSlider sections={sections} />
            <div style={{ display: "flex", justifyContent: "center", padding: "10px", flexDirection: 'column', alignItems: "center" }}>
                <div
                    dangerouslySetInnerHTML={{ __html: TextoButton }}
                    style={{
                        width: "100%",
                        maxWidth: "650px",
                        padding: "35px",
                        background: "#707070",
                        color: "white",
                        fontSize: "11pt",
                        textAlign: "center"
                    }}
                ></div>

                {
                    showAffirm &&
                    <img src="/assets/img/affirm_banner.png" alt="Affirm Banner" />

                }

            </div>
            <hr />
            <Slider />
            <hr />
            <iframe src="/giftCard.html" width="100%" title="GiftCard" height="980" className="gift-up-iframe" frameBorder="0" data-hj-allow-iframe=""></iframe>
            <Footer />
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        country: state.Intl.locale
    }
}

export default connect(mapStateToProps)(withTranslate(Home));
