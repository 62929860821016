import React, { Component } from 'react';
import { withTranslate, IntlActions } from 'react-redux-multilingual';
import LazyLoad from 'react-lazyload';

class Fabric extends Component {

    constructor(props) {
        super(props);

        const { elements } = this.props;

        let filterColor = [];
        let filterMaterial = [];
        let filterPattern = [];
        let filterSeason = [];

        elements.forEach(tmpEOP => {
            filterColor.push(tmpEOP.tag_color);
            filterMaterial.push(tmpEOP.tag_material);
            filterPattern.push(tmpEOP.tag_pattern);
            filterSeason.push(tmpEOP.tag_season);
        });

        filterColor.push("ALL");
        filterMaterial.push("ALL");
        filterPattern.push("ALL");
        filterSeason.push("ALL");

        const onlyUnique = (value, index, self) => {
            return self.indexOf(value) === index;
        }

        this.state = {
            filterMaterial: filterMaterial.filter(onlyUnique),
            filterPattern: filterPattern.filter(onlyUnique),
            filterSeason: filterSeason.filter(onlyUnique),
            filterColor: filterColor.filter(onlyUnique),
            selectMaterial: "ALL",
            selectPattern: "ALL",
            selectSeason: "ALL",
            selectColor: "ALL",
            showFilters: false
        }
    }




    render() {
        const { translate, elements, selectedFabric, selected } = this.props;
        const { selectColor, selectMaterial, selectPattern, selectSeason } = this.state;
        const { filterColor, filterMaterial, filterPattern, filterSeason, showFilters } = this.state;

        const changeFilterFabric = (e) => {
            this.setState({ [e.target.name]: e.target.value });
        }


        const filterFabricApply = (element) => {
            if (element.tag_color !== selectColor && selectColor !== "ALL") {
                return false;
            }
            if (element.tag_material !== selectMaterial && selectMaterial !== "ALL") {
                return false;
            }
            if (element.tag_pattern !== selectPattern && selectPattern !== "ALL") {
                return false;
            }
            if (element.tag_season !== selectSeason && selectSeason !== "ALL") {
                return false;
            }

            return true;
        }

        return (
            <>
               
                    <div className="filtros row">
                        <div className="col-6 px-3">
                            <span style={{fontWeight: "bold", fontSize: "14pt"}}>Filters</span>   
                            <img src="/assets/img/filters.png" 
                                style={{
                                    width:"20pt", 
                                    height: "15pt", 
                                    margin: "5px"
                                }} 
                                onClick={()=> this.setState( {showFilters: !showFilters})} 
                                alt=""
                            />
                        </div>
                    </div> 

                {
                    showFilters &&
                    <div className="filtros row">
                        <div className="col-6 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label htmlFor="selectColor">Color</label>
                                <select
                                    name="selectColor"
                                    className="form-control"
                                    value={selectColor}
                                    onChange={(e) => changeFilterFabric(e)}
                                >
                                    {
                                        filterColor.map((filtro) =>
                                            <option key={"color-" + filtro}>
                                                {filtro}
                                            </option>
                                        )
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-6 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label htmlFor="selectMaterial">Material</label>
                                <select
                                    name="selectMaterial"
                                    className="form-control"
                                    value={selectMaterial}
                                    onChange={(e) => changeFilterFabric(e)}
                                >
                                    {
                                        filterMaterial.map((filtro) =>
                                            <option key={"material-" + filtro}>
                                                {filtro}
                                            </option>
                                        )
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-6 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label htmlFor="selectPattern">Pattern</label>
                                <select
                                    name="selectPattern"
                                    className="form-control"
                                    value={selectPattern}
                                    onChange={(e) => changeFilterFabric(e)}
                                >
                                    {
                                        filterPattern.map((filtro) =>
                                            <option key={"pattern-" + filtro}>
                                                {filtro}
                                            </option>
                                        )
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-6 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label htmlFor="selectSeason">Season</label>
                                <select
                                    name="selectSeason"
                                    className="form-control"
                                    value={selectSeason}
                                    onChange={(e) => changeFilterFabric(e)}
                                >
                                    {
                                        filterSeason.map((filtro) =>
                                            <option key={"season-" + filtro}>
                                                {filtro}
                                            </option>
                                        )
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                }
                <div className="content-fabric">
                    {
                        elements &&
                        elements.map(fabric =>
                            filterFabricApply(fabric) &&
                            <div
                                key={fabric.id + fabric.code + "fb"}
                                className={selected.id == fabric.id ? "tela selected" : "tela"}
                                style={{ maxHeight: "135px", width: "104px"}}
                            >
                                <LazyLoad height={104} offset={104} resize={true} once={true} overflow={true} style={{display: "flex", flexDirection: "column" }} >
                                    <img src={fabric.url_image} onClick={() => selectedFabric(fabric)} />
                                    <span style={{fontSize: "8px", fontWeight: "600"}}>{fabric.txt_desc_en}</span>
                                </LazyLoad>
                            </div>
                        )
                    }
                </div>
            </>
        )
    }
}


export default withTranslate(Fabric);