import React, { Component } from 'react';
import { withTranslate, IntlActions } from 'react-redux-multilingual'

import { Link } from 'react-router-dom';

class aboutOurClothes extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { translate } = this.props;
        let img = "";

        this.props.sections.forEach(sec => {
            if (sec.code === "MD001") {
                img = sec.img;
            }
        });

        return (
            <div className="row" style={{ display: "flex", justifyContent: "center" }}>
                <Link to="/about-our-clothes">
                    <img src={img} alt="About Our Clothes" />
                </Link>
            </div>
        )
    }
}



export default withTranslate(aboutOurClothes);