import React from 'react';
import Navbar2 from './common/Navbar2';
import Footer from './common/Footer';
import TitlePage from './common/TitlePage';
import { withTranslate } from 'react-redux-multilingual'

class AboutOurClothes extends React.Component {

    render() {

        const margin = {
            marginTop: "-6px"
        }

        const { translate } = this.props;

        return <div className="absolute-nav">
            <Navbar2 />
            <TitlePage title={translate('aboutClothes.title')} />
            
            <div style={{padding:"30px", display:"flex", justifyContent: "center", color: "#203341", fontSize: "18px", fontWeight:600, flexDirection: "column", alignItems: "center"}}>
            <h3>
            {translate('aboutClothes.subTitle')}
            </h3>
            <table style={{ maxWidth: "750px" }}>
                <tr>
                    <td style={{width:"70px", marginBottom: "15px", paddingRight:"15px"}}><img style={{width: "51px"}} src="/assets/img/about-clothes/1.png" alt=""/></td>
                    <td style={{fontSize: "10pt"}}>{translate('aboutClothes.text1')}</td>
                </tr>
                <tr>
                    <td style={{width:"70px", marginBottom: "15px", paddingRight:"15px"}}><img style={{width: "51px"}} src="/assets/img/about-clothes/2.png" alt=""/></td>
                    <td style={{fontSize: "10pt"}}>{translate('aboutClothes.text2')}</td>
                </tr>
                <tr>
                    <td style={{width:"70px", marginBottom: "15px", paddingRight:"15px"}}><img style={{width: "51px"}} src="/assets/img/about-clothes/3.png" alt=""/></td>
                    <td style={{fontSize: "10pt"}}>{translate('aboutClothes.text3')}</td>
                </tr>
                <tr>
                    <td style={{width:"70px", marginBottom: "15px", paddingRight:"15px"}}><img style={{width: "51px"}} src="/assets/img/about-clothes/4.png" alt=""/></td>
                    <td style={{fontSize: "10pt"}}>{translate('aboutClothes.text4')}</td>
                </tr>
                <tr>
                    <td style={{width:"70px", marginBottom: "15px", paddingRight:"15px"}}><img style={{width: "51px"}} src="/assets/img/about-clothes/5.png" alt=""/></td>
                    <td style={{fontSize: "10pt"}}>{translate('aboutClothes.text5')}</td>
                </tr>
                <tr>
                    <td style={{width:"70px", marginBottom: "15px", paddingRight:"15px"}}><img style={{width: "51px"}} src="/assets/img/about-clothes/6.png" alt=""/></td>
                    <td style={{fontSize: "10pt"}}>{translate('aboutClothes.text6')}</td>
                </tr>
            </table>
            </div>
            
            <div style={margin}>
                <Footer />
            </div>
        </div>
    }

}

export default withTranslate(AboutOurClothes)