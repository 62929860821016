import React from 'react';
import Navbar2 from './common/Navbar2';
import TitlePage from './common/TitlePage';
import ContentCheckout from './ContainCheckout';
import Footer from './common/Footer';
import { withTranslate, IntlActions } from 'react-redux-multilingual'

class Checkout extends React.Component {

    render(){
        const {translate} = this.props;
        return <div>
        <Navbar2 />
        <TitlePage title={translate('product.checkout')}/>
        <ContentCheckout />
        <Footer />
    </div>
    }
}

export default withTranslate(Checkout)