import CheckoutForm from './CheckoutForm';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import React from 'react';

export default function InjectedCheckoutForm() {
    return (
      <ElementsConsumer>
        {({stripe, elements }) => (
          <CheckoutForm  stripe={stripe} elements={elements} />
        )}
      </ElementsConsumer>
    );
  }