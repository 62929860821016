/** @format */
import Client from "graphql-js-client";

import typeBundle from "./types";

const configureClient = () => {
  const client = new Client(typeBundle, {
    url: "https://stile-personale.myshopify.com/api/2023-07/graphql",
    fetcherOptions: {
      headers: {
        "X-Shopify-Storefront-Access-Token": "02b78b5e05e8afef39b2bbd088452b47",
      },
    },
  });
  return client;
};

export default configureClient;
