import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { connect } from 'react-redux';
import CardSection from './CardSection';
import { urlNewSale, urlNewOrder, urlGiftUp, headerGiftUp } from '../../constants/proxy';
import { stripeSecretKey } from '../../constants/managementKeys';

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: ""
    }
  }
  handleSubmit = async (event) => {

    document.getElementById("button-payment").disabled = true;
    event.preventDefault();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const { country } = this.props;

    let orderDetail = JSON.parse(localStorage.orderDetail);
    let cartDetailAll = JSON.parse(localStorage.cart);

    let cartDetail = cartDetailAll.filter(cart => cart.country === country);

    let orderIdSend = localStorage.orderId || "";
    let giftCardCode = (localStorage.giftCardCode !== undefined ? JSON.parse(localStorage.giftCardCode) : null);
    let descuentosAplicados = (localStorage.descuentosAplicados !== undefined ? JSON.parse(localStorage.descuentosAplicados) : null);

    if (orderDetail.firstName === "" || orderDetail.lastName === "" || orderDetail.address1 === "" || orderDetail.country === "" || orderDetail.province === ""
      || orderDetail.zip === "" || orderDetail.email === "" || orderDetail.phone === ""
    ) {
      this.setState({
        message: "Fill in the required fields"
      })
    }

    else {
      let lineItems = [];

      cartDetail.forEach(item => {
        lineItems.push({
          "originalUnitPrice": item.price,
          "quantity": item.qty,
          "taxable": true,
          "title": item.name
        });
      });

      var rawOrder = JSON.stringify({
        "email": orderDetail.email,
        "lineItems": lineItems,
        "billingAddress": {
          "address1": orderDetail.address1Pay,
          "address2": orderDetail.address2Pay,
          "city": orderDetail.cityPay,
          "firstName": orderDetail.firstNamePay,
          "lastName": orderDetail.lastNamePay,
          "zip": orderDetail.zipPay,
          "phone": orderDetail.phonePay,
          "province": orderDetail.provincePay,
          "country": orderDetail.countryPay
        },
        "shippingAddress": {
          "address1": orderDetail.address1,
          "address2": orderDetail.address2,
          "city": orderDetail.city,
          "firstName": orderDetail.firstName,
          "lastName": orderDetail.lastName,
          "zip": orderDetail.zip,
          "phone": orderDetail.phone,
          "province": orderDetail.province,
          "country": orderDetail.country
        }
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: rawOrder
      };


      fetch(urlNewOrder, requestOptions)
        .then(response => response.json())
        .then(result => {
          orderIdSend = result.data.draftOrderCreate.draftOrder.legacyResourceId;
          localStorage.setItem('orderId', result.data.draftOrderCreate.draftOrder.legacyResourceId);
        })
        .catch(error => console.log('error', error));



      var url = 'https://api.stripe.com/v1/payment_intents';
      let dataIntent = [];
      let amount = 0.00;
      let total = 0.00;

      cartDetail.forEach(item => {
        amount += (item.qty * item.price);
        total += (item.qty * item.price);
      });

      let giftCardCodeExist = false;

      if (giftCardCode !== null) {


        await fetch(urlGiftUp + giftCardCode.code,
          {
            method: 'GET',
            headers: headerGiftUp
          })
          .then(response => response.text())
          .then(result => {
            let giftCardCodeJson = JSON.parse(result)
            let giftValue = giftCardCodeJson.remainingValue;
            amount -= parseFloat(giftValue);
            giftCardCodeExist = true;
          })
          .catch(error => console.log(error));
      }

      if (descuentosAplicados != null) {
        let totalD = 0;
        if (descuentosAplicados.all == true) {
            if (descuentosAplicados.tipo_descuento == "value") {
                totalD += parseFloat(descuentosAplicados.valor);
            }
            else if (descuentosAplicados.tipo_descuento == "percentage") {
                let porcentaje = parseFloat(parseFloat(descuentosAplicados.valor) / 100);
                totalD = parseFloat(total * porcentaje);
            }
        }
        else {
            descuentosAplicados.productos.forEach(prodCode => {
              cartDetail.forEach(item => { 
                    if (prodCode == item.code) {
                        if (descuentosAplicados.tipo_descuento == "value") {
                            totalD += parseFloat(descuentosAplicados.valor);
                        }
                        else if (descuentosAplicados.tipo_descuento == "percentage") {
                            let totalItem = (item.qty * item.price);
                            let porcentaje = parseFloat(parseFloat(descuentosAplicados.valor) / 100);
                            totalD = parseFloat(totalItem * porcentaje);
                        }
                    }
                });

            });
        }

        amount -= parseFloat(totalD);
    }

      amount = parseFloat(amount).toFixed(2).replace('.', '');

      await fetch(url, {
        method: 'POST',
        body: "amount=" + amount + "&currency=usd&metadata[integration_check]=accept_a_payment",
        headers: {
          Authorization: "Bearer " + stripeSecretKey,
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(response => dataIntent = response);


      event.preventDefault();

      const { stripe, elements } = this.props

      if (!stripe || !elements) {
        return;
      }

      const result = await stripe.confirmCardPayment(dataIntent.client_secret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            email: orderDetail.email,
            phone: orderDetail.phonePay,
            name: orderDetail.firstName + " " + orderDetail.lastName,
          },
        }
      });

      if (result.error) {
        alert(result.error.message);
      } else {
        if (result.paymentIntent.status === 'succeeded') {

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
              clienteId: orderDetail.email,
              orderId: orderIdSend,
              orderDetail: orderDetail,
              cart: cartDetail
            })
          };

          fetch(urlNewSale, requestOptions)
            .then(response => response.json())
            .then(result => {

              if (giftCardCodeExist) {

                fetch(urlGiftUp + giftCardCode.code + "/redeem-in-full",
                  {
                    method: 'POST',
                    headers: headerGiftUp,
                    body: JSON.stringify({ "reason": "Pay Order" }),
                    redirect: 'follow'
                  }
                )
                  .then(response => response.text())
                  .then(result => console.log(result))
                  .catch(error => console.log('error', error));
              }

              alert("Thank you for your purchase..!");
              localStorage.removeItem("cart");
              localStorage.removeItem("descuentosAplicados");
              localStorage.removeItem("orderDetail");
              localStorage.removeItem("orderId");
              localStorage.removeItem("giftCardCode");
              localStorage.removeItem("orderMX");
              window.location.href = window.location.origin;
            })
            .catch(error => console.log('error', error));
        }
      }
    }

  };

  render() {
    return (
      <form onSubmit={this.handleSubmit} className="w-100">
        <CardSection />
        <button disabled={!this.props.stripe} className="btn btn-checkout" id="button-payment">Pay Now</button>
        <div className="text-center message mt-3">
          <span>{this.state.message}</span>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  symbol: state.data.symbol,
  country: state.Intl.locale
})

export default connect(mapStateToProps)(CheckoutForm)