import React, { Component } from 'react';
import TitlePage from './common/TitlePage';
import Navbar2 from './common/Navbar2';
import Footer from '../pages/common/Footer';
import { withTranslate } from 'react-redux-multilingual'
import { connect } from 'react-redux';

class SingleBlog extends Component {

  constructor(props){
    super(props);

    this.state ={
      blogs : this.props.blogs,
    }
  }

  findBlog = () => {
    let {id} = this.props;
    let blog;
    this.state.blogs.slider.forEach(item => {
      if(item.id === id ){
        blog = item;
      }
    });

    this.state.blogs.list.forEach(item => {
      if(item.id === id ){
        blog = item;
      }
    });

    return blog;
  }

	render() {
    const {translate} = this.props;
    let blog = this.findBlog();

		return(
			<div>
         <Navbar2 />
         <TitlePage title={translate('product.blogDetail')} />
         <div className="container">
           <div className="d-flex justify-content-center mt-4">
              <h3>{blog.title}</h3>
           </div>
           <div className="d-flex justify-content-center mb-5">
              <span className="description-single-blog" dangerouslySetInnerHTML={{__html: blog.contentHtml}}></span>
           </div>
         </div>
         <Footer />
			</div>
		);
  }
}


const mapStateToProps = (state,ownProps) => {
  let id = ownProps.match.params.id;
  return {
   blogs: state.articles,
   id: id
  }
};

export default connect(mapStateToProps)(withTranslate(SingleBlog));