import {
    CHANGE_CURRENCY } from "../constants/ActionTypes";


const initialState = {
    symbol: '$'
};

const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_CURRENCY:
            return { ...state,
                symbol: action.symbol };
        default:
            return state;
    }
};
export default productReducer;