import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Navbar2 from './common/Navbar2';
import TitlePage from './common/TitlePage';
import Footer from './common/Footer';
import { connect } from 'react-redux';
import { withTranslate } from 'react-redux-multilingual';
import {
  urlConversion
} from "../../constants/proxy";


class TypesProducts extends Component {
  constructor(props) {
    super(props)
    const { codeCategory, products } = this.props;

    this.state = {
      adminProducts: JSON.parse(localStorage.products),
      products: products.list.filter(x => x.vendor === codeCategory),
      conversions: []
    };
  }

  componentDidMount() {
    fetch(urlConversion)
        .then(response => response.json())
        .then(data => {
            this.setState({conversions: data.data})
            localStorage.setItem('conversion', JSON.stringify(data.data));
        });
    window.scroll(0, 0);
  }

  render() {
    const { products, adminProducts, conversions } = this.state;
    const { translate, country } = this.props;

    const filterAvalible = (productsList) => {
      let list = [];
      productsList.forEach(element => {
        adminProducts.forEach(pr => {
          if (pr.code === element.productType) {
            pr.countries.forEach(ct => {
              if (ct.code === country && ct.avalible === true) {
                list.push(element);
              }
            });
          }
        });
      });
      return list;
    }

    const filterPrice = (product) => {
      let price = product.price;
      let currency = "";
      let exchange_rate = 1;

      if (conversions !== undefined) {
        conversions.forEach(conver => {
          if (conver.code_country === country) {
            currency = conver.code_currency;
            exchange_rate = conver.exchange_rate;
          }
        });
      }
      adminProducts.forEach(pr => {
        if (pr.code === product.productType) {
          pr.countries.forEach(ct => {
            if (ct.code === country) {
              price = (ct.min_price * exchange_rate) + " - " + (ct.max_price * exchange_rate);
            }
          });
        }
      });

      return currency + " " + price;
    };

    return <div>
      <Navbar2 />
      <TitlePage title={translate('product.selectGarment')} />
      <div className="collection-area margin-top-20">
        <div className="container">
          <div className="row flex-row-reverse">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="tab-content">
                <div className="tab-pane fade in show active" id="one">
                  <div className="row">
                    {
                      filterAvalible(products).map((item, index) =>
                        <div key={index} className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4 p-0">
                          <div className="product-style-03 margin-top-40">
                            <div className="thumb">
                              <Link to={`/product/${item.productType}`}> <img src={item.defaultImage} alt="" /></Link>
                            </div>
                            <div className="content text-center d-none">
                              <span>{item.title}</span>
                              <br />
                              <span>{filterPrice(item)}</span>
                              <h6 className="title stone-go-top"><Link to="/product-details"></Link></h6>
                              <div className="content-price d-flex align-self-center justify-content-center">
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  }
}

const mapStateToProps = (state, ownProps) => {
  let code = ownProps.match.params.id;
  return {
    codeCategory: code,
    products: state.products,
    collections: state.category,
    country: state.Intl.locale
  }
};

export default connect(mapStateToProps)(withTranslate(TypesProducts));
