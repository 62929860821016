import { combineReducers } from 'redux';
import { IntlReducer as Intl } from 'react-redux-multilingual'

// Import custom components
import productosReducer from './productos';
import articlesReducer from './blog';
import cartsReducer from './cart';
import userReducer from './user';
import vendorReducer from './vendors';
import productReducer from './products';



const rootReducer = combineReducers({
    data: productReducer,
    products: productosReducer,
    articles: articlesReducer,
    cart: cartsReducer,
    carts: cartsReducer,
    user: userReducer,
    vendor: vendorReducer,
    Intl
});

export default rootReducer;