/**
 * created by Inspire UI @author(dang@inspireui.com)
 * @format
 */

const LOGIN_FETCHING = "user/LOGIN_FETCHING";
const LOGIN_SUCCESS = "user/LOGIN_SUCCESS";
const LOGIN_FAILURE = "user/LOGIN_FAILURE";

const REGISTER_FETCHING = "user/REGISTER_FETCHING";
const REGISTER_SUCCESS = "user/REGISTER_SUCCESS";
const REGISTER_FAILURE = "user/REGISTER_FAILURE";

const USER_INFO_FETCHING = "user/INFO_FETCHING";
const USER_INFO_SUCCESS = "user/INFO_SUCCESS";
const USER_INFO_FAILURE = "user/INFO_FAILURE";

const USER_CREATE_ADDRESS_FETCHING = "user/address/CREATE_FETCHING";
const USER_CREATE_ADDRESS_SUCCESS = "user/address/CREATE_SUCCESS";
const USER_CREATE_ADDRESS_FAILURE = "user/address/CREATE_FAILURE";

const USER_UPDATE_ADDRESS_FETCHING = "user/address/UPDATE_FETCHING";
const USER_UPDATE_ADDRESS_SUCCESS = "user/address/UPDATE_SUCCESS";
const USER_UPDATE_ADDRESS_FAILURE = "user/address/UPDATE_FAILURE";

const USER_UPDATE_DEFAULT_ADDRESS_FETCHING =
  "user/address/UPDATE_DEFAULT_FETCHING";
const USER_UPDATE_DEFAULT_ADDRESS_SUCCESS =
  "user/address/UPDATE_DEFAULT_SUCCESS";
const USER_UPDATE_DEFAULT_ADDRESS_FAILURE =
  "user/address/UPDATE_DEFAULT_FAILURE";

const USER_DELETE_ADDRESS_FETCHING = "user/address/DELETE_FETCHING";
const USER_DELETE_ADDRESS_SUCCESS = "user/address/DELETE_SUCCESS";
const USER_DELETE_ADDRESS_FAILURE = "user/address/DELETE_FAILURE";

const LOGOUT = "user/LOGOUT";

export {
  LOGIN_FETCHING,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  REGISTER_FETCHING,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  USER_INFO_FETCHING,
  USER_INFO_SUCCESS,
  USER_INFO_FAILURE,
  USER_CREATE_ADDRESS_FETCHING,
  USER_CREATE_ADDRESS_SUCCESS,
  USER_CREATE_ADDRESS_FAILURE,
  USER_UPDATE_ADDRESS_FETCHING,
  USER_UPDATE_ADDRESS_SUCCESS,
  USER_UPDATE_ADDRESS_FAILURE,
  USER_UPDATE_DEFAULT_ADDRESS_FETCHING,
  USER_UPDATE_DEFAULT_ADDRESS_SUCCESS,
  USER_UPDATE_DEFAULT_ADDRESS_FAILURE,
  USER_DELETE_ADDRESS_FETCHING,
  USER_DELETE_ADDRESS_SUCCESS,
  USER_DELETE_ADDRESS_FAILURE,
  LOGOUT,
};
