import React from 'react';
import Navbar2 from './common/Navbar2';
import PostList from './common/PostList';
import Footer from '../pages/common/Footer';
import TitlePage from './common/TitlePage';
import { withTranslate, IntlActions } from 'react-redux-multilingual';
import store from '../../store';
import {
    fetchAllArticles
} from '../../reducers/blog/operations';

class About extends React.Component {

    componentDidMount(){
        store.dispatch(fetchAllArticles(249));
        
    }
    render(){
    const {translate} = this.props;
    return <div>
        <Navbar2 />
        <TitlePage title={translate('product.blog')}/>
        <PostList />
        <Footer />
    </div>
    }
   
}

export default withTranslate(About)