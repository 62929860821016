import React, { Component } from 'react';
import { login } from "../../reducers/user/operations"
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import Navbar from './common/Navbar2';
import Footer from './common/Footer';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
var md5 = require('md5');

class Login extends Component {

  constructor(props) {
    super(props)

    let state = {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      message: "",
      messageError: "Email or password incorrect",
      url: "",
      redirect: false,
      valueUrl: ""
    };

    this.state = state;

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleForgotPassword = this.handleForgotPassword.bind(this);
    this.getUrlVars = this.getUrlVars.bind(this);
  }

  componentDidMount() {
    let url = this.getUrlVars()["redirect"];
    if (url !== null && url !== undefined) {
      this.setState({
        url: url,
        redirect: true
      });
    }
  }

  getUrlVars() {
    var vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      console.log(m);
      vars[key] = value;
    });
    return vars;
  }

  handleSubmit(event) {
    let messageForgot = document.getElementById("forgot");
    messageForgot.classList.add("d-none");

    const { user } = this.props;
    event.preventDefault();
    let social = false;

    var datos = {
      email: this.state.email,
      password: md5(this.state.password),
      firstName: this.state.firstName,
      lastName: this.state.lastName
    };

    this.props.login(datos, social, this.state.redirect, this.state.url);

    if (user.error === "Unidentified customer") {
      var messageError = document.getElementById("error");
      messageError.classList.remove("d-none");
    }
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleForgotPassword(email) {
    var messageForgot = document.getElementById("forgot");
    messageForgot.classList.remove("d-none");

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({ "email": email });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch("https://shopify.luxedressing.com/api/luxe/customer/recover", requestOptions)
      .then(response => response.text(), this.setState({ message: "We've just sent you an e-mail to reset your password." }))
      .catch((error) => this.setState({ message: "" }));
  }

  render() {
    function parseJwt(token) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    }

    const responseGoogle = async (response) => {
      console.log(response);
      const profile = parseJwt(response.credential);
      console.log(profile);
      let social = true;
      const datos = {
        email: profile.email,
        password: md5(profile.email),
        lastName: profile.family_name,
        firstName: profile.given_name
      };

      this.props.login(datos, social, this.state.redirect, this.state.url);
    }

    return (
      <React.Fragment>
        <Navbar />
        <br />
        <br />
        <div className="container register login mt-5 pt-4">
          <div className="row margin">
            <img src={`${process.env.PUBLIC_URL}/assets/img/banner/stile-personale-1.gif`} className="img-fluid img-fondo" alt="" />
            <form className="div-form" onSubmit={this.handleSubmit} autoComplete="off">
              <div className="center">
                <div className="div-login">
                  <img className="img-logo" src={`${process.env.PUBLIC_URL}/assets/img/logo.png`} alt="" />
                  <span className="create-your-account">Login or create your account</span>
                </div>
                <input
                  id="email"
                  type="email"
                  name="email"
                  placeholder="EMAIL"
                  required={true}
                  onChange={this.handleChange}
                />
                <input
                  type="password"
                  name="password"
                  placeholder="PASSWORD"
                  required={true}
                  onChange={this.handleChange}
                />
                <div className="forgot" onClick={() => {
                  let messageError = document.getElementById("error");
                  messageError.classList.add("d-none");

                  let email = document.getElementById("email").value;
                  let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
                  if (emailRegex.test(email)) {
                    this.handleForgotPassword(email);
                  }
                }}>
                </div>
                <p id="forgot" className="message d-none">{this.state.message}</p>
                <p id="error" className="message d-none">{this.state.messageError}</p>
                <div className="sign">
                  <input className="title" type="submit" value="LOG IN" />
                </div>
                <Link to={(this.state.valueUrl === "item-sell") ? "/register/?redirect=item-sell" : "/register"}>
                  <div className="sign">
                    <span className="title" >REGISTER</span>
                  </div>
                </Link>
                <br />
                <GoogleOAuthProvider clientId="160661769409-q55okhcuc4jd1tbqqt9jtegogslm59pr.apps.googleusercontent.com">
                  <GoogleLogin
                    className="sign-google"
                    onSuccess={responseGoogle}
                    onError={() => {
                      console.log('Login Failed');
                    }}
                  />
                </GoogleOAuthProvider>
              </div>
            </form>
          </div>
        </div>
        <Footer />
      </React.Fragment>

    )
  }
}

const mapStateToProps = (state) => {
  return {
    symbol: state.data.symbol,
    user: state.user
  }
}

export default connect(mapStateToProps, { login })(Login);