/**
 * created by Inspire UI @author(dang@inspireui.com)
 * @format
 */

import GraphqlAPI from "../../services/Graphql";
import { Languages } from "../../common";
import * as actions from "./actions";

const PER_PAGE = 249;
/**
 * fetch all product
 */
export const fetchAllArticles = (pageSize = PER_PAGE) => (dispatch) => {
  dispatch(actions.productAllPending());

  GraphqlAPI.getArticles({ pageSize })
    .then((json) => {
      dispatch(actions.productAllSuccess(json));
    })
    .catch((error) => {
      dispatch(actions.productAllFailure(error));
    });
};

export const fetchAllArticlesSlider = (pageSize = PER_PAGE) => (dispatch) => {
  dispatch(actions.productAllPending());

  GraphqlAPI.getArticlesSlider({ pageSize })
    .then((json) => {
      dispatch(actions.productAllSuccessSlider(json));
    })
    .catch((error) => {
      dispatch(actions.productAllFailure(error));
    });
};
