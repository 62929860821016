var module = {
    en_US: {
        locale: 'en_US',
        messages: {
            productMobile: {
                fabric: "Fabric",
                style: "Style",
                summary: "Summary",
                custom: "Custom",
                selectCustom: "Please choose a style"
            },
            homeText: {
                about1: "MEASURING<br/>BY A.I.",
                about2: "PERFECT FIT<br/>GUARANTEE",
                about3: "CREATE YOUR<br/>OWN STYLE",
                about4: "100% DIGITAL",
                about5: "FREE SHIPPING",
                about6: "FAIR PRICE",
                banner3: 'Our custom clothing helps individuals showcase their personalities and create styles that reflect their unique fashion sense<br/>-It is all about self-expression'
            },
            aboutClothes: {
                title: "OUR COMMITMENT",
                subTitle: "About Our Clothes",
                text1: "Each garment is tailored exclusively for you based on your style.",
                text2: "No need to be measured by a Tailor, our A.I. technology does the job.",
                text3: "Your clothes will fit perfectly; in case you are not 100% satisfied within 30 days, we will remake your garment.",
                text4: "All the process can be completed digitally.",
                text5: "We offer free delivery on all our orders.",
                text6: "We believe made-to-measure clothes need to be accessible to everyone, that is why all our prices are competitive and fair.",
            },
            bannerIA: "Effortless and easy measuring powered by A.I.",
            removeCheckout: "Remove",
            quantityCheckout: "Quantity",
            summaryCheckout: "Summary",
            gifthCardCodeCheckout: "Gift Card Code",
            applyGifthCardCodeCheckout: "Apply",
            gifthCardCheckout: "Discount Gif Card",
            discountCodeCheckout: "Discount Code",
            applyDiscountCodeCheckout: "Apply",
            discountCheckout: "Discount",
            orderTotalCheckout: "Order Total",
            payCheckout: "Pay",
            payOnlyGiftCard: "Confirm Pay",
            welcome: 'WELCOME',
            home: 'Home',
            shop: 'Shop',
            pages: 'Pages',
            about: 'About',
            blog: 'Blog',
            help: 'Help',
            contact: 'Contact',
            language: 'COUNTRY',
            titleSlider1: 'CUSTOM-TAILORED<br/>DRESS CLOTHES',
            subtitleSlider1: '',
            seeMore: "CUSTOMIZE<br/>NOW >>",
            aboutTitle: "CLOTHES YOUR STYLE",
            blogTitle: "WELCOME TO OUR BLOG",
            helpTitle: "HELP",
            collection: {
                titleWomen: 'Women',
                titleMen: 'Men',
                shop: "CUSTOMIZE<br/>NOW >>"
            },
            proceedCheckout: "Proceed Checkout",
            confirmOrder: "Confirm Order",
            cardNumber: "Card Number",
            expirationDate: "Expiration Date",
            cvv: "CVV",
            paymentDetails: "Payment Details",
            shippingInfo: "Shipping Address",
            paymentInfo: "Payment Address",
            sameShippingAddress: "Same as shipping address",
            firstName: "First Name *",
            lastName: "Last Name *",
            address: "Address *",
            aptunit: "Apt/Unit# *",
            country: "Country *",
            usa: "United States",
            mexico: "Mexico",
            panama: "Panama",
            province: "State *",
            city: "City *",
            zip: "Zip/Postal Code *",
            email: "Email Address *",
            phone: "Phone Number *",
            login: "LOG IN",
            logout: "LOG OUT",
            product: {
                addToCart: "Add to cart",
                title: "Customize your clothes​",
                shopNow: "Shop Now",
                blog: "Welcome Our Blog",
                contact: "Contact us",
                checkout: "Finalize your purchase",
                cart: "Configure your cart ",
                shopMen: "Shop Men",
                shopWomen: "Shop Women",
                blogDetail: "Blog Detail",
                selectGarment: "Select your garment",
                about: "CLOTHES YOUR STYLE",
                paso1: "1. CUSTOMIZATION LEVEL",
                paso2: "2. SECTIONS",
                paso3: "3. OPTIONS",
                textTop: "Scroll up for larger image"
            },
            footer: {
                title: "CLOTHES FOR MEN AND WOMEN",
                subTitle: "Customized - Made to Measure - Online - Free Delivery",
                ourPolicy: "OUR POLICY​",
                quality: "Quality",
                delivery: "Delivery",
                returns: "Returns",
                informations: "INFORMATION",
                contact: "Contact Us​"
            },
            filters: {
                title: "Filters",
                categories: "Categories"
            },
            contacto: {
                title: "Send us a message",
                buttonSend: "Send Message"
            },
            aboutUs: {
                title: "About Stile Personale and Our Founder",
                subTitle: "DRESS LIKE NO ONE ELSE",
                text1: "I got my start at a financial company, moving up to a leadership position where travel and meet-and-greets                 were an important part of the job. I quickly realized how important my image was to making a good first and  lasting impression. Dressing well not only gave me a boost, it also helped clients and colleagues look at me with respect and confidence.  Although coworkers stretch their budgets to buy off-the-rack clothes from designer brands, a steep price tag couldn’t make up for poor fit. That’s what led me to dream up and create Stile Personale. I want to make bespoke fashion for work, events and everyday effortless and competitively priced.",
                text2: "At Stile Personale, we offer a fully digital, easy and efficient experience that delivers on quality, fashion and fit for men and women. Our online process allows you to personalize every detail of any garment to your body and preferences. Plus, our risk-free fit guarantee promises a perfect fit on our custom measurements service. Express yourself, dress like no one else, reflect your personal style.",
                text3: "Get Started Today!",
                ceo: "Francisco Flores",
                ceoTitle: "Founder and CEO of Stile Personale"
            },
            policy: {
                title: "Privacy Policy for Stile Personale",
                html: `<div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 0cm;'>
                <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Introduction</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin-bottom:15.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;color:#374151;'>Welcome to Stile Personale (</span><a href="http://www.stilepersonale.com/" target="_new"><span style='font-family:"Segoe UI",sans-serif;color:blue;padding:0cm;'>www.stilepersonale.com</span></a><span style='font-family:"Segoe UI",sans-serif;color:#374151;'>). We specialize in providing made-to-measure clothes for men and women. Our operations are based in the United States, Mexico, and Panama. This privacy policy outlines how we collect, use, and protect your personal information.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Information Collection and Use</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin-bottom:15.0pt;border:none;padding:0cm;'><strong><span style='font-family:"Segoe UI",sans-serif;color:#374151;padding:0cm;'>Types of Information Collected:</span></strong></p>
            </div>
            <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 5.0pt;margin-left:18.0pt;margin-right:0cm;'>
                <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
                    <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>We primarily collect email addresses for order processing and marketing purposes.</span></li>
                    <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>No sensitive personal information is collected.</span></li>
                    <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>We may use cookies to enhance your browsing experience.</span></li>
                </ul>
            </div>
            <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 0cm;'>
                <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin-top:15.0pt;margin-right:0cm;margin-bottom:15.0pt;margin-left:0cm;border:none;padding:0cm;'><strong><span style='font-family:"Segoe UI",sans-serif;color:#374151;padding:0cm;'>Methods of Information Collection:</span></strong></p>
            </div>
            <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 5.0pt;margin-left:18.0pt;margin-right:0cm;'>
                <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
                    <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Email addresses are collected via our website, mainly through order placement and sign-ups.</span></li>
                    <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Google Sign-In API is used to collect email addresses. We assure you that no other information is accessed through this API.</span></li>
                </ul>
            </div>
            <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 0cm;'>
                <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Use of Google Sign-In API</span></p>
            </div>
            <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 5.0pt;margin-left:18.0pt;margin-right:0cm;'>
                <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
                    <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>The Google Sign-In API is employed to simplify the login process on our website.</span></li>
                    <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Only your email address is accessed through this API for order processing and marketing communications.</span></li>
                </ul>
            </div>
            <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 0cm;'>
                <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Data Usage</span></p>
            </div>
            <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 5.0pt;margin-left:18.0pt;margin-right:0cm;'>
                <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
                    <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Collected emails are used for processing your orders and sending marketing updates.</span></li>
                    <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>You will receive updates and offers from Stile Personale, which you can opt-out of anytime.</span></li>
                </ul>
            </div>
            <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 0cm;'>
                <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Data Security</span></p>
            </div>
            <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 5.0pt;margin-left:18.0pt;margin-right:0cm;'>
                <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
                    <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>We implement robust security measures to ensure the protection of your personal information.</span></li>
                    <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>However, please be aware that no method of transmission over the Internet is 100% secure.</span></li>
                </ul>
            </div>
            <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 0cm;'>
                <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>User Rights and Control</span></p>
            </div>
            <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 5.0pt;margin-left:18.0pt;margin-right:0cm;'>
                <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
                    <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>You have the right to opt-out of marketing emails and request the deletion of your information.</span></li>
                    <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Requests can be made by sending an email to: admin_it@stilepersonale.com.</span></li>
                </ul>
            </div>
            <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 0cm;'>
                <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Policy Updates</span></p>
            </div>
            <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 5.0pt;margin-left:18.0pt;margin-right:0cm;'>
                <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
                    <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>This privacy policy is subject to change. Any updates will be available on our website.</span></li>
                    <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>We encourage users to review our policy periodically.</span></li>
                </ul>
            </div>
            <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 0cm;'>
                <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Compliance with Legal Standards</span></p>
            </div>
            <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 5.0pt;margin-left:18.0pt;margin-right:0cm;'>
                <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
                    <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Stile Personale is committed to complying with the California Consumer Privacy Act (CCPA) and other relevant laws.</span></li>
                    <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>This policy reflects our dedication to protecting your privacy and handling your personal information responsibly.</span></li>
                </ul>
            </div>
            <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 0cm;'>
                <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Contact Information</span></p>
            </div>
            <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 5.0pt;margin-left:18.0pt;margin-right:0cm;'>
                <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
                    <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>For any privacy-related inquiries, please contact us via email at: admin_it@stilepersonale.com.</span></li>
                </ul>
            </div>`

            },
            cart: {
                pay: "Pay"
            }
        }

    },
    es_MX: {
        locale: 'es_MX',
        messages: {
            productMobile: {
                fabric: "Tela",
                style: "Estilo",
                summary: "Resumen",
                custom: "Personalizado",
                selectCustom: "Seleccione Estilo"
            },
            homeText: {
                about1: "I.A. PARA</br>MEDIDAS",
                about2: "GARANTÍA DE<br/>AJUSTE PERFECTO",
                about3: "CREA TU<br/>PROPIO ESTILO",
                about4: "100% DIGITAL",
                about5: "ENVIO GRATIS",
                about6: "PRECIO JUSTO",
                banner3: 'Nuestras prendas permiten que las personas muestren su personalidad y creen estilos que reflejan su particular gusto por la moda</br>-Es la expresión personal al máximo'
            },
            aboutClothes: {
                title: "OUR COMMITMENT",
                subTitle: "About Our Clothes",
                text1: "Each garment is tailored exclusively for you based on your style.",
                text2: "No need to be measured by a Tailor, our A.I. technology does the job.",
                text3: "Your clothes will fit perfectly; in case you are not 100% satisfied within 30 days, we will remake your garment.",
                text4: "All the process can be completed digitally.",
                text5: "We offer free delivery on all our orders.",
                text6: "We believe made-to-measure clothes need to be accessible to everyone, that is why all our prices are competitive and fair.",
            },
            bannerIA: "Toma de medidas fácilmente y sin esfuerzo utilizando I.A.​",
            removeCheckout: "Eliminar",
            quantityCheckout: "Cantidad",
            summaryCheckout: "Resumen",
            gifthCardCodeCheckout: "Código Tarjeta Regalo",
            applyGifthCardCodeCheckout: "Aplicar",
            gifthCardCheckout: "Tarjeta de Regalo",
            discountCodeCheckout: "Codigo de Descuento",
            applyDiscountCodeCheckout: "Aplicar",
            discountCheckout: "Descuento",
            orderTotalCheckout: "Total Órden",
            payCheckout: "Pagar",
            payOnlyGiftCard: "Confirmar Pago",
            welcome: 'BIENVENIDO',
            home: 'Inicio',
            shop: 'Tienda',
            pages: 'Paginas',
            about: 'Acerca de',
            blog: 'Blog',
            help: 'Ayuda',
            contact: 'Contactenos',
            language: 'PAIS',
            titleSlider1: '',
            subtitleSlider1: 'MUESTRA TU<br/>ESTILO PERSONAL',
            seeMore: "PERSONALIZA<br/>AHORA  >>",
            aboutTitle: "ROPA A TU ESTILO",
            blogTitle: "BIENVENIDO A NUESTRO BLOG",
            helpTitle: "AYUDA",
            collection: {
                titleWomen: 'Mujeres',
                titleMen: 'Hombres',
                shop: "PERSONALIZA<br/>AHORA  >>"
            },
            proceedCheckout: "Continuar con la Compra",
            confirmOrder: "Confirmar Orden",
            cardNumber: "Número de Tarjeta",
            expirationDate: "Fecha de Vencimiento",
            cvv: "CVV",
            paymentDetails: "Detalles de Pago",
            shippingInfo: "Dirección de Envío",
            sameShippingAddress: "Igual que la direccion de envio",
            paymentInfo: "Direccion de Pago",
            firstName: "Nombres *",
            lastName: "Apellidos *",
            address: "Dirección *",
            aptunit: "#Casa/Apartamento *",
            country: "País *",
            usa: "Estados Unidos",
            mexico: "México",
            panama: "Panamá",
            province: "Provincia *",
            city: "Ciudad *",
            zip: "Código Postal *",
            email: "Correo Electrónico *",
            phone: "Teléfono *",
            login: "INICIAR SESION",
            logout: "CERRAR SESION",
            product: {
                addToCart: "Agregar al carrito",
                title: "Personaliza tu ropa",
                shopNow: "Comprar ahora",
                blog: "Bienvenido a nuestro blog",
                contact: "Contactanos",
                checkout: "Finaliza tu compra",
                cart: "Configura tu carrito",
                shopMen: "Shop Men",
                shopWomen: "Shop Women",
                blogDetail: "Detalle del blog",
                selectGarment: "Selecciona tu prenda",
                about: "Ropa a tu estilo",
                paso1: "1. NIVEL DE PERSONALIZACIÓN",
                paso2: "2. SECCIONES",
                paso3: "3. OPCIONES",
                textTop: "Mayor tamaño, ver arriba"
            },
            footer: {
                title: "ROPA PARA HOMBRE Y MUJER",
                subTitle: "Personalizada - Hecha a medida - Online - Entrega gratuita.",
                ourPolicy: "NUESTRA POLÍTICA",
                quality: "Calidad",
                delivery: "Entrega",
                returns: "Devoluciones",
                informations: "INFORMACIÓN",
                contact: "Contáctanos"
            },
            filters: {
                title: "Filters",
                categories: "Categories"
            },
            contacto: {
                title: "Envianos un mensaje",
                buttonSend: "Enviar Mensaje"
            },
            aboutUs: {
                title: "Acerca de Stile Personale y Nuestro Fundador",
                subTitle: "VISTE COMO NADIE MÁS",
                text1: "Mi gran oportunidad a nivel profesional fue en uno de los principales bancos internacionales. Mientras ascendía en la pirámide corporativa, donde los viajes y las reuniones eran parte importante del trabajo, me percaté que mi imagen era una de las claves para dejar una primera impresión positiva y perdurable. El vestir correctamente no sólo era un habilitador en las negociaciones, sino que también me ayudaba a que clientes y colegas me vieran con respeto y confianza.",
                text2: "En ese ámbito laboral era común que las personas gastaran hasta el último centavo comprando ropa “off-the-rack” de diseñadores, sin darse cuenta de que el elevado precio que pagaban no compensaba el hecho de que la ropa no tuviera el ajuste adecuado. Esta situación me hizo soñar y crear Stile Personale. Mi objetivo es que las personas puedan acceder de forma sencilla, y a precios competitivos, a ropa tanto de trabajo como del día a día, hecha a su medida y gusto.",
                text3: "En Stile Personale ofrecemos una experiencia completamente digital, fácil y eficiente que entrega calidad, moda y ajuste adecuado a hombres y mujeres. Nuestro proceso en línea te permite personalizar cada detalle de tus prendas a tu cuerpo y preferencia; logrando creaciones únicas y exclusivas. Además, nuestra garantía libre de riesgo promete un ajuste perfecto en nuestro servicio de medidas personalizadas. Expresa tu personalidad, viste como nadie más, refleja tu propio estilo.",
                text4: "Empieza Hoy!",
                ceo: "Francisco Flores",
                ceoTitle: "Fundador y CEO de Stile Personale"
            },
            cart: {
                pay: "Pagar"
            },
            policy: {
                title: "Política de Privacidad de Stile Personale",
                html: `<div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 0cm;'>
                <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 0cm;'>
                    <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Introducci&oacute;n</span></p>
                    <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin-bottom:15.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;color:#374151;'>Bienvenido a Stile Personale (</span><a href="http://www.stilepersonale.com/" target="_new"><span style='font-family:"Segoe UI",sans-serif;color:blue;padding:0cm;'>www.stilepersonale.com</span></a><span style='font-family:"Segoe UI",sans-serif;color:#374151;'>). Nos especializamos en proporcionar ropa a medida para hombres y mujeres. Operamos en Estados Unidos, M&eacute;xico y Panam&aacute;. Esta pol&iacute;tica de privacidad describe c&oacute;mo recopilamos, utilizamos y protegemos su informaci&oacute;n personal.</span></p>
                    <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Recolecci&oacute;n y Uso de Informaci&oacute;n</span></p>
                    <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin-bottom:15.0pt;border:none;padding:0cm;'><strong><span style='font-family:"Segoe UI",sans-serif;color:#374151;padding:0cm;'>Tipos de Informaci&oacute;n Recopilada:</span></strong></p>
                </div>
                <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 5.0pt;margin-left:18.0pt;margin-right:0cm;'>
                    <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
                        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Principalmente recopilamos direcciones de correo electr&oacute;nico para procesamiento de pedidos y fines de marketing.</span></li>
                        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>No recopilamos informaci&oacute;n personal sensible.</span></li>
                        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Podemos utilizar cookies para mejorar su experiencia de navegaci&oacute;n.</span></li>
                    </ul>
                </div>
                <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 0cm;'>
                    <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin-top:15.0pt;margin-right:0cm;margin-bottom:15.0pt;margin-left:0cm;border:none;padding:0cm;'><strong><span style='font-family:"Segoe UI",sans-serif;color:#374151;padding:0cm;'>M&eacute;todos de Recolecci&oacute;n de Informaci&oacute;n:</span></strong></p>
                </div>
                <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 5.0pt;margin-left:18.0pt;margin-right:0cm;'>
                    <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
                        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Las direcciones de correo electr&oacute;nico se recopilan a trav&eacute;s de nuestro sitio web, principalmente mediante la realizaci&oacute;n de pedidos y suscripciones.</span></li>
                        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Utilizamos el API de Google Sign-In para recopilar direcciones de correo electr&oacute;nico. Le aseguramos que no se accede a ninguna otra informaci&oacute;n a trav&eacute;s de este API.</span></li>
                    </ul>
                </div>
                <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 0cm;'>
                    <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Uso del API de Google Sign-In</span></p>
                </div>
                <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 5.0pt;margin-left:18.0pt;margin-right:0cm;'>
                    <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
                        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>El API de Google Sign-In se emplea para simplificar el proceso de inicio de sesi&oacute;n en nuestro sitio web.</span></li>
                        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Solo accedemos a su direcci&oacute;n de correo electr&oacute;nico a trav&eacute;s de este API para procesamiento de pedidos y comunicaciones de marketing.</span></li>
                    </ul>
                </div>
                <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 0cm;'>
                    <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Uso de Datos</span></p>
                </div>
                <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 5.0pt;margin-left:18.0pt;margin-right:0cm;'>
                    <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
                        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Los correos electr&oacute;nicos recopilados se utilizan para procesar sus pedidos y enviar actualizaciones de marketing.</span></li>
                        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Recibir&aacute; actualizaciones y ofertas de Stile Personale, de las cuales puede darse de baja en cualquier momento.</span></li>
                    </ul>
                </div>
                <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 0cm;'>
                    <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Seguridad de Datos</span></p>
                </div>
                <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 5.0pt;margin-left:18.0pt;margin-right:0cm;'>
                    <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
                        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Implementamos medidas de seguridad robustas para garantizar la protecci&oacute;n de su informaci&oacute;n personal.</span></li>
                        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Sin embargo, tenga en cuenta que ning&uacute;n m&eacute;todo de transmisi&oacute;n por Internet es 100% seguro.</span></li>
                    </ul>
                </div>
                <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 0cm;'>
                    <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Derechos y Control del Usuario</span></p>
                </div>
                <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 5.0pt;margin-left:18.0pt;margin-right:0cm;'>
                    <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
                        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Tiene derecho a darse de baja de los correos electr&oacute;nicos de marketing y solicitar la eliminaci&oacute;n de su informaci&oacute;n.</span></li>
                        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Las solicitudes se pueden hacer enviando un correo electr&oacute;nico a: admin_it@stilepersonale.com.</span></li>
                    </ul>
                </div>
                <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 0cm;'>
                    <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Actualizaciones de la Pol&iacute;tica</span></p>
                </div>
                <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 5.0pt;margin-left:18.0pt;margin-right:0cm;'>
                    <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
                        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Esta pol&iacute;tica de privacidad est&aacute; sujeta a cambios. Cualquier actualizaci&oacute;n estar&aacute; disponible en nuestro sitio web.</span></li>
                        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Animamos a los usuarios a revisar nuestra pol&iacute;tica peri&oacute;dicamente.</span></li>
                    </ul>
                </div>
                <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 0cm;'>
                    <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Cumplimiento de Est&aacute;ndares Legales</span></p>
                </div>
                <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 5.0pt;margin-left:18.0pt;margin-right:0cm;'>
                    <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
                        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Stile Personale se compromete a cumplir con la Ley de Privacidad del Consumidor de California (CCPA) y otras leyes relevantes.</span></li>
                        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Esta pol&iacute;tica refleja nuestro compromiso con la protecci&oacute;n de su privacidad y el manejo responsable de su informaci&oacute;n personal.</span></li>
                    </ul>
                </div>
                <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 0cm;'>
                    <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Informaci&oacute;n de Contacto</span></p>
                </div>
                <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 5.0pt;margin-left:18.0pt;margin-right:0cm;'>
                    <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
                        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Para cualquier consulta relacionada con la privacidad, p&oacute;ngase en contacto con nosotros por correo electr&oacute;nico en: admin_it@stilepersonale.com.</span></li>
                    </ul>
                </div>
                <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
            </div>`
            }
        }
    },
    es_PA: {
        locale: 'es_PA',
        messages: {
            productMobile: {
                fabric: "Tela",
                style: "Estilo",
                summary: "Resumen",
                custom: "Personalizado",
                selectCustom: "Seleccione Estilo"
            },
            homeText: {
                about1: "I.A. PARA</br>MEDIDAS",
                about2: "GARANTÍA DE<br/>AJUSTE PERFECTO",
                about3: "CREA TU<br/>PROPIO ESTILO",
                about4: "100% DIGITAL",
                about5: "ENVIO GRATIS",
                about6: "PRECIO JUSTO",
                banner3: 'Nuestras prendas permiten que las personas muestren su personalidad y creen estilos que reflejan su particular gusto por la moda</br>-Es la expresión personal al máximo'
            },
            aboutClothes: {
                title: "OUR COMMITMENT",
                subTitle: "About Our Clothes",
                text1: "Each garment is tailored exclusively for you based on your style.",
                text2: "No need to be measured by a Tailor, our A.I. technology does the job.",
                text3: "Your clothes will fit perfectly; in case you are not 100% satisfied within 30 days, we will remake your garment.",
                text4: "All the process can be completed digitally.",
                text5: "We offer free delivery on all our orders.",
                text6: "We believe made-to-measure clothes need to be accessible to everyone, that is why all our prices are competitive and fair.",
            },
            bannerIA: "Toma de medidas fácilmente y sin esfuerzo utilizando I.A.​",
            removeCheckout: "Eliminar",
            quantityCheckout: "Cantidad",
            summaryCheckout: "Resumen",
            gifthCardCodeCheckout: "Código Tarjeta Regalo",
            applyGifthCardCodeCheckout: "Aplicar",
            gifthCardCheckout: "Tarjeta de Regalo",
            discountCodeCheckout: "Codigo de Descuento",
            applyDiscountCodeCheckout: "Aplicar",
            discountCheckout: "Descuento",
            orderTotalCheckout: "Total Órden",
            payCheckout: "Pagar",
            payOnlyGiftCard: "Confirmar Pago",
            welcome: 'BIENVENIDO',
            home: 'Inicio',
            shop: 'Tienda',
            pages: 'Paginas',
            about: 'Acerca de',
            blog: 'Blog',
            help: 'Ayuda',
            contact: 'Contactenos',
            language: 'PAIS',
            titleSlider1: '',
            subtitleSlider1: 'MUESTRA TU<br/>ESTILO PERSONAL',
            seeMore: "PERSONALIZA<br/>AHORA  >>",
            aboutTitle: "ROPA A TU ESTILO",
            blogTitle: "BIENVENIDO A NUESTRO BLOG",
            helpTitle: "AYUDA",
            collection: {
                titleWomen: 'Mujeres',
                titleMen: 'Hombres',
                shop: "PERSONALIZA<br/>AHORA  >>"
            },
            proceedCheckout: "Continuar con la Compra",
            confirmOrder: "Confirmar Orden",
            cardNumber: "Número de Tarjeta",
            expirationDate: "Fecha de Vencimiento",
            cvv: "CVV",
            paymentDetails: "Detalles de Pago",
            shippingInfo: "Dirección de Envío",
            paymentInfo: "Direccion de Pago",
            sameShippingAddress: "Igual que la direccion de envio",
            firstName: "Nombres *",
            lastName: "Apellidos *",
            address: "Dirección *",
            aptunit: "#Casa/Apartamento *",
            country: "País *",
            usa: "Estados Unidos",
            mexico: "México",
            city: "Ciudad *",
            panama: "Panamá",
            province: "Provincia *",
            zip: "Código Postal *",
            email: "Correo Electrónico *",
            phone: "Teléfono *",
            login: "INICIAR SESION",
            logout: "CERRAR SESION",
            product: {
                addToCart: "Agregar al carrito",
                title: "Personaliza tu ropa",
                shopNow: "Comprar ahora",
                blog: "Bienvenido a nuestro blog",
                contact: "Contactanos",
                checkout: "Finaliza tu compra",
                cart: "Configura tu carrito",
                shopMen: "Shop Men",
                shopWomen: "Shop Women",
                blogDetail: "Detalle del blog",
                selectGarment: "Selecciona tu prenda",
                about: "Ropa a tu estilo",
                paso1: "1. NIVEL DE PERSONALIZACIÓN",
                paso2: "2. SECCIONES",
                paso3: "3. OPCIONES",
                textTop: "Mayor tamaño, ver arriba"
            },
            footer: {
                title: "ROPA PARA HOMBRE Y MUJER",
                subTitle: "Personalizada - Hecha a medida - Online - Entrega gratuita.",
                ourPolicy: "NUESTRA POLÍTICA",
                quality: "Calidad",
                delivery: "Entrega",
                returns: "Devoluciones",
                informations: "INFORMACIÓN",
                contact: "Contáctanos"
            },
            filters: {
                title: "Filters",
                categories: "Categories"
            },
            contacto: {
                title: "Envianos un mensaje",
                buttonSend: "Enviar Mensaje"
            },
            aboutUs: {
                title: "Acerca de Stile Personale y Nuestro Fundador",
                subTitle: "VISTE COMO NADIE MÁS",
                text1: "Mi gran oportunidad a nivel profesional fue en uno de los principales bancos internacionales. Mientras ascendía en la pirámide corporativa, donde los viajes y las reuniones eran parte importante del trabajo, me percaté que mi imagen era una de las claves para dejar una primera impresión positiva y perdurable. El vestir correctamente no sólo era un habilitador en las negociaciones, sino que también me ayudaba a que clientes y colegas me vieran con respeto y confianza.",
                text2: "En ese ámbito laboral era común que las personas gastaran hasta el último centavo comprando ropa “off-the-rack” de diseñadores, sin darse cuenta de que el elevado precio que pagaban no compensaba el hecho de que la ropa no tuviera el ajuste adecuado. Esta situación me hizo soñar y crear Stile Personale. Mi objetivo es que las personas puedan acceder de forma sencilla, y a precios competitivos, a ropa tanto de trabajo como del día a día, hecha a su medida y gusto.",
                text3: "En Stile Personale ofrecemos una experiencia completamente digital, fácil y eficiente que entrega calidad, moda y ajuste adecuado a hombres y mujeres. Nuestro proceso en línea te permite personalizar cada detalle de tus prendas a tu cuerpo y preferencia; logrando creaciones únicas y exclusivas. Además, nuestra garantía libre de riesgo promete un ajuste perfecto en nuestro servicio de medidas personalizadas. Expresa tu personalidad, viste como nadie más, refleja tu propio estilo.",
                text4: "Empieza Hoy!",
                ceo: "Francisco Flores",
                ceoTitle: "Fundador y CEO de Stile Personale"
            },
            cart: {
                pay: "Pagar"
            },
            policy: {
                title: "Política de Privacidad de Stile Personale",
                html: `<div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 0cm;'>
                <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 0cm;'>
                    <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Introducci&oacute;n</span></p>
                    <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin-bottom:15.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;color:#374151;'>Bienvenido a Stile Personale (</span><a href="http://www.stilepersonale.com/" target="_new"><span style='font-family:"Segoe UI",sans-serif;color:blue;padding:0cm;'>www.stilepersonale.com</span></a><span style='font-family:"Segoe UI",sans-serif;color:#374151;'>). Nos especializamos en proporcionar ropa a medida para hombres y mujeres. Operamos en Estados Unidos, M&eacute;xico y Panam&aacute;. Esta pol&iacute;tica de privacidad describe c&oacute;mo recopilamos, utilizamos y protegemos su informaci&oacute;n personal.</span></p>
                    <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Recolecci&oacute;n y Uso de Informaci&oacute;n</span></p>
                    <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin-bottom:15.0pt;border:none;padding:0cm;'><strong><span style='font-family:"Segoe UI",sans-serif;color:#374151;padding:0cm;'>Tipos de Informaci&oacute;n Recopilada:</span></strong></p>
                </div>
                <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 5.0pt;margin-left:18.0pt;margin-right:0cm;'>
                    <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
                        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Principalmente recopilamos direcciones de correo electr&oacute;nico para procesamiento de pedidos y fines de marketing.</span></li>
                        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>No recopilamos informaci&oacute;n personal sensible.</span></li>
                        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Podemos utilizar cookies para mejorar su experiencia de navegaci&oacute;n.</span></li>
                    </ul>
                </div>
                <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 0cm;'>
                    <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin-top:15.0pt;margin-right:0cm;margin-bottom:15.0pt;margin-left:0cm;border:none;padding:0cm;'><strong><span style='font-family:"Segoe UI",sans-serif;color:#374151;padding:0cm;'>M&eacute;todos de Recolecci&oacute;n de Informaci&oacute;n:</span></strong></p>
                </div>
                <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 5.0pt;margin-left:18.0pt;margin-right:0cm;'>
                    <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
                        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Las direcciones de correo electr&oacute;nico se recopilan a trav&eacute;s de nuestro sitio web, principalmente mediante la realizaci&oacute;n de pedidos y suscripciones.</span></li>
                        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Utilizamos el API de Google Sign-In para recopilar direcciones de correo electr&oacute;nico. Le aseguramos que no se accede a ninguna otra informaci&oacute;n a trav&eacute;s de este API.</span></li>
                    </ul>
                </div>
                <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 0cm;'>
                    <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Uso del API de Google Sign-In</span></p>
                </div>
                <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 5.0pt;margin-left:18.0pt;margin-right:0cm;'>
                    <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
                        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>El API de Google Sign-In se emplea para simplificar el proceso de inicio de sesi&oacute;n en nuestro sitio web.</span></li>
                        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Solo accedemos a su direcci&oacute;n de correo electr&oacute;nico a trav&eacute;s de este API para procesamiento de pedidos y comunicaciones de marketing.</span></li>
                    </ul>
                </div>
                <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 0cm;'>
                    <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Uso de Datos</span></p>
                </div>
                <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 5.0pt;margin-left:18.0pt;margin-right:0cm;'>
                    <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
                        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Los correos electr&oacute;nicos recopilados se utilizan para procesar sus pedidos y enviar actualizaciones de marketing.</span></li>
                        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Recibir&aacute; actualizaciones y ofertas de Stile Personale, de las cuales puede darse de baja en cualquier momento.</span></li>
                    </ul>
                </div>
                <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 0cm;'>
                    <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Seguridad de Datos</span></p>
                </div>
                <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 5.0pt;margin-left:18.0pt;margin-right:0cm;'>
                    <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
                        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Implementamos medidas de seguridad robustas para garantizar la protecci&oacute;n de su informaci&oacute;n personal.</span></li>
                        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Sin embargo, tenga en cuenta que ning&uacute;n m&eacute;todo de transmisi&oacute;n por Internet es 100% seguro.</span></li>
                    </ul>
                </div>
                <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 0cm;'>
                    <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Derechos y Control del Usuario</span></p>
                </div>
                <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 5.0pt;margin-left:18.0pt;margin-right:0cm;'>
                    <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
                        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Tiene derecho a darse de baja de los correos electr&oacute;nicos de marketing y solicitar la eliminaci&oacute;n de su informaci&oacute;n.</span></li>
                        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Las solicitudes se pueden hacer enviando un correo electr&oacute;nico a: admin_it@stilepersonale.com.</span></li>
                    </ul>
                </div>
                <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 0cm;'>
                    <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Actualizaciones de la Pol&iacute;tica</span></p>
                </div>
                <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 5.0pt;margin-left:18.0pt;margin-right:0cm;'>
                    <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
                        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Esta pol&iacute;tica de privacidad est&aacute; sujeta a cambios. Cualquier actualizaci&oacute;n estar&aacute; disponible en nuestro sitio web.</span></li>
                        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Animamos a los usuarios a revisar nuestra pol&iacute;tica peri&oacute;dicamente.</span></li>
                    </ul>
                </div>
                <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 0cm;'>
                    <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Cumplimiento de Est&aacute;ndares Legales</span></p>
                </div>
                <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 5.0pt;margin-left:18.0pt;margin-right:0cm;'>
                    <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
                        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Stile Personale se compromete a cumplir con la Ley de Privacidad del Consumidor de California (CCPA) y otras leyes relevantes.</span></li>
                        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Esta pol&iacute;tica refleja nuestro compromiso con la protecci&oacute;n de su privacidad y el manejo responsable de su informaci&oacute;n personal.</span></li>
                    </ul>
                </div>
                <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 0cm;'>
                    <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Informaci&oacute;n de Contacto</span></p>
                </div>
                <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;padding:0cm 0cm 0cm 5.0pt;margin-left:18.0pt;margin-right:0cm;'>
                    <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
                        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#374151;margin-left:18.0pt;border:none;padding:0cm;'><span style='font-family:"Segoe UI",sans-serif;'>Para cualquier consulta relacionada con la privacidad, p&oacute;ngase en contacto con nosotros por correo electr&oacute;nico en: admin_it@stilepersonale.com.</span></li>
                    </ul>
                </div>
                <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
            </div>`
            }
        }
    }
};

export default module;