import React, { Component } from 'react';
import Navbar2 from '../pages/common/Navbar2';
import Footer from '../pages/common/Footer';
import { connect } from 'react-redux';
import { withTranslate, IntlActions } from 'react-redux-multilingual';
import FabricComponent from './Fabric';
import Styles from './Styles';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class SingleProduct extends Component {
    constructor(props) {
        super(props);

        const { product } = this.props;

        let adminFabrics = JSON.parse(localStorage.fabric);
        let conversions = JSON.parse(localStorage.conversion);
        let tableIncompatibility = JSON.parse(localStorage.tableIncompatibility);


        let adminProduct = JSON.parse(localStorage.adminProductSelecte);
        let tempCatFabric = [];
        let tempSection = [];
        let tempOptions = [];

        try {
            /*adminProducts.forEach(element => {
                if (element.code === idProduct) {
                    adminProduct = element;
                }
            });*/

            adminProduct.cat_section.forEach(section => {
                if (section.tag_en == "Fabric") {
                    section.cat_option.forEach(op => {
                        adminFabrics.forEach(fabric => {
                            if (op.code === fabric.code) {
                                tempCatFabric = fabric.cat_fabric;
                            }
                        });
                    });
                } else {
                    if (section.basic == true) {
                        tempSection.push(section);
                    }
                }
            });

            tempSection.sort((a, b) => a.order - b.order).forEach(section => {
                section.cat_option.sort((a, b) => a.order - b.order).filter(op => op.basic == true).forEach(op => {
                    let opStatus = true;
                    op.option_element.filter(el => el.basic == true).forEach(element => {
                        if (opStatus == true) {
                            opStatus = false;

                            let tempData = { ...section };
                            let tempOp = { ...op };

                            tempOp.option_element = element;
                            tempData.cat_option = tempOp;

                            tempOptions.push(tempData)
                        }
                    });
                });
            });
        } catch (error) {
        }

        adminProduct.cat_section = adminProduct.cat_section.sort((a, b) => a.order - b.order);
        tempSection = tempSection.sort((a, b) => a.order - b.order);

        let selectedProduct = {
            fabric: {},
            style: {
                level: "basic",
                option: tempOptions,
                sections: tempSection
            },
            sumarry: {},
            stateTab: {
                fabric: true,
                style: false,
                summary: false
            }
        };

        if (localStorage.getItem(adminProduct._id) !== null) {
            let localSecctionsSelected = JSON.parse(localStorage.getItem(adminProduct._id));
            selectedProduct = localSecctionsSelected;
        } else {
            localStorage.setItem(adminProduct._id, JSON.stringify(selectedProduct));
        }

        this.state = {
            product: product[0],
            adminProduct: adminProduct,
            adminFabrics: adminFabrics,
            fabricElements: tempCatFabric,
            conversions: conversions,
            tabActive: "Default",
            imgPrincipal: product[0].defaultImage,
            imgTextAlternative: "",
            saiaWidgetReact: false,
            tableIncompatibility: tableIncompatibility,
            monogramText: "",
            selectedProduct: selectedProduct,
            modal: false,
            modalDelete: false,
            configName: "",
            configNameSpanish: "",
            indexConfig: 0
        };

    }



    componentDidMount() {

    }

    componentWillMount() {
        setTimeout(() => {
            window.scroll(0, 95);
        }, 1000);
    }


    componentDidUpdate() {
    }

    componentWillReceiveProps() {
    }

    render() {
        let iconCheck = '<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check" fill="currentColor" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"/> </svg>';
        const { translate, country } = this.props;
        const { adminProduct, product, imgPrincipal, fabricElements, selectedProduct, conversions, modal, modalDelete, idexConfig } = this.state;
        const { stateTab } = selectedProduct;

        const selectedFabric = (fabric) => {
            this.setState({
                selectedProduct: {
                    ...this.state.selectedProduct,
                    fabric: fabric
                }
            })

            try {
                setTimeout(() => {
                    window.affirm.ui.refresh();
                }, 500);
            } catch (error) {
                console.log(error);
            }

            localStorage.setItem(adminProduct._id, JSON.stringify({
                ...this.state.selectedProduct,
                fabric: fabric
            }));
        }

        const selectTab = (style, summary) => {

            if (selectedProduct.fabric.id == undefined) {
                style = false;
                summary = false;
            }


            this.setState({
                selectedProduct: {
                    ...this.state.selectedProduct,
                    stateTab: {
                        fabric: true,
                        style: style,
                        summary: summary
                    }
                }
            })

            localStorage.setItem(adminProduct._id, JSON.stringify({
                ...this.state.selectedProduct,
                stateTab: {
                    fabric: true,
                    style: style,
                    summary: summary
                }
            }));
        }

        const changeLevel = (e) => {

            let level = e.target.value;
            let tempSection = [];
            adminProduct.cat_section.forEach(section => {
                if (section.tag_en != "Fabric" && section[level] == true) {
                    tempSection.push(section);
                }
            });

            let tempOptions = [];

            tempSection.forEach(sec => {
                sec.cat_option.forEach(op => {
                    let opStatus = true;
                    if (op.option_element && op[level] == true) {
                        op.option_element.forEach(el => {
                            if (el[level] == true && opStatus == true) {
                                opStatus = false;

                                let tempData = { ...sec };
                                let tempOp = { ...op };
                                tempOp.option_element = el;
                                tempData.cat_option = tempOp;
                                tempOptions.push(tempData);
                            }
                        });
                    }
                })
            })


            this.setState({
                selectedProduct: {
                    ...this.state.selectedProduct,
                    style: {
                        level: level,
                        option: tempOptions,
                        sections: tempSection
                    }
                }
            })


            localStorage.setItem(adminProduct._id, JSON.stringify({
                ...this.state.selectedProduct,
                style: {
                    level: level,
                    option: tempOptions,
                    sections: tempSection
                }
            }));
        }

        const changeSelection = (element, option) => {
            let options = this.state.selectedProduct.style.option;
            let tempOption = [];
            let section = true;


            if (element.code == "custom_code") {

            }


            options.forEach(ops => {
                if (ops.cat_option.code == option.code) {
                    section = false;
                }

                if (ops.cat_option.code == option.code && ops.cat_option.option_element.code != element.code) {
                    let auxOp = ops;
                    auxOp.cat_option.option_element = element;
                    tempOption.push(auxOp);
                }

                else {
                    tempOption.push(ops)
                }
            });

            if (section) {
                let sections = this.state.selectedProduct.style.sections;
                sections.forEach(sec => {
                    sec.cat_option.forEach(op => {
                        if (op.code == option.code) {

                            if (element.code = "custom_code") {
                                let tempData = { ...sec };
                                let tempOp = { ...op };
                                tempOp.option_element = element;
                                tempData.cat_option = tempOp;
                                tempOption.push(tempData);
                            }
                            else {
                                op.option_element.forEach(ele => {
                                    if (ele.code == element.code) {
                                        let tempData = { ...sec };
                                        let tempOp = { ...op };
                                        tempOp.option_element = ele;
                                        tempData.cat_option = tempOp;
                                        tempOption.push(tempData);
                                    }
                                })
                            }
                        }

                    });
                });
            }

            this.setState({
                selectedProduct: {
                    ...this.state.selectedProduct,
                    style: {
                        ...this.state.selectedProduct.style,
                        option: tempOption
                    }
                }
            })


            localStorage.setItem(adminProduct._id, JSON.stringify({
                ...this.state.selectedProduct,
                style: {
                    ...this.state.selectedProduct.style,
                    option: tempOption
                }
            }));
        }

        const nextStyleStep = () => {
            console.log("style");
            selectTab(true, true);
        }

        const backStyleStep = () => {
            selectTab(false, false);
        }

        const backSummaryStep = () => {
            selectTab(true, false);
        }

        const solvePrice = (onlyPrice) => {
            let pais = {};
            let currency = "";
            let factorFabric = 0;
            let precioBase = 0;
            let numM2 = adminProduct.num_m2;
            let priceM2 = 0;
            let fabric = false;
            let rateConversion = 0;

            conversions.forEach(conver => {
                if (conver.code_country === country) {
                    currency = conver.code_currency;
                    rateConversion = conver.exchange_rate;
                }
            });

            adminProduct.countries.forEach(con => {
                if (con.code === country) {
                    pais = con;
                    precioBase = con.base_price;
                    factorFabric = con.factor_fabric;
                }
            });

            priceM2 = selectedProduct.fabric.price_m2;

            let subTotal = (factorFabric * numM2 * priceM2);
            let precioFinal = parseFloat((parseFloat(precioBase) + parseFloat(subTotal)) * parseFloat(rateConversion)).toFixed(2);

            if (onlyPrice) {

                return (precioFinal === "NaN" ? 0.00 : precioFinal);
            }

            return currency + " " + (precioFinal === "NaN" ? "0.00" : precioFinal);

        };


        const addToCart = () => {
            toggle();
        }


        const deleteConfigIndex = (index) => {
            this.setState({ indexConfig: index })
            toggleDelete();

        }


        const toggle = () => this.setState({ modal: !modal });
        const toggleDelete = () => this.setState({ modalDelete: !modalDelete });


        const EliminarEstilo = () => {
            let url = process.env.SP_API_ADMIN_URL;
            const { idProduct } = this.props;

            let configurations = adminProduct.configurations;
            configurations.splice(this.state.indexConfig, 1);

            let fetchOptions = {
                method: 'POST',
                body: JSON.stringify(configurations),
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            fetch(`${url}/v2/products/add-config/${adminProduct._id}`, fetchOptions)
                .then(function (response) {
                    return response.json();
                })
                .then(function (data) {
                    if (data.success) {
                        window.location.href = `${window.location.origin}/custom-product/${idProduct}`;
                        return 1;
                    }

                    console.log("error al crear registro");
                });
        };


        const GuardarEstilo = () => {
            let url = process.env.SP_API_ADMIN_URL;
            const { idProduct } = this.props;

            let configurations = adminProduct.configurations;
            configurations.push({
                name: this.state.configName,
                name_es: this.state.configNameSpanish,
                config: JSON.parse(localStorage.getItem(adminProduct._id))
            });

            let fetchOptions = {
                method: 'POST',
                body: JSON.stringify(configurations),
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            fetch(`${url}/v2/products/add-config/${adminProduct._id}`, fetchOptions)
                .then(function (response) {
                    return response.json();
                })
                .then(function (data) {
                    if (data.success) {
                        window.location.href = `${window.location.origin}/custom-product/${idProduct}`;
                        return 1;
                    }

                    console.log("error al crear registro");
                });

            console.log();
        };

        const changeConfigName = (event) => {
            this.setState({ configName: event.target.value })
        };

        const changeConfigNameSpanish = (event) => {
            this.setState({ configNameSpanish: event.target.value })
        };



        return (<div className="product-mobile">
            <Navbar2 />
            <div style={{ paddingTop: "100px" }}>
                <div className="title" onClick={() => { window.location.href = `${window.location.origin}/${localStorage.typeCategory}` }} style={{ cursor: "pointer" }}>
                    {"< " + translate('product.title')}
                </div>
            </div>
            <div className="container-mobile" >
                <div className="content-mobile" style={{ maxWidth: "550px !important" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <div className="imagen" style={{
                            backgroundImage: `url("${imgPrincipal}")`,
                            width: "150px",
                            height: "185px",
                            backgroundPosition: "center",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat"
                        }}>
                            {/* <img src={imgPrincipal} width="149" height="auto" style={{ maxWidth: "150px !important", maxHeight: "150px !important" }} alt=""/> */}
                        </div>
                    </div>

                    <div className="pasos">
                        <button className={stateTab.fabric ? "section" : "section disabled"} disabled={!stateTab.fabric} onClick={() => selectTab(false, false)}>
                            {translate('productMobile.fabric')}
                        </button>
                        <button className={stateTab.style ? "section" : "section disabled"} onClick={() => selectTab(true, false)}>
                            {translate('productMobile.style')}
                        </button>
                        <button className={stateTab.summary ? "section" : "section disabled"} onClick={() => selectTab(true, true)}>
                            {translate('productMobile.summary')}
                        </button>
                    </div>
                    {stateTab.fabric && !stateTab.style &&
                        <div className="fabric">
                            <FabricComponent
                                selected={selectedProduct.fabric}
                                selectedFabric={selectedFabric}
                                id={adminProduct._id}
                                elements={fabricElements}
                            />
                            <div className="footer-fabric">
                                <button className={selectedProduct.fabric.id != undefined ? "next" : "next disabled"} disabled={selectedProduct.fabric.id ? false : true} onClick={() => selectTab(true, false)}>
                                    NEXT
                                </button>
                            </div>
                        </div>
                    }

                    {stateTab.fabric && stateTab.style && !stateTab.summary &&
                        <div className="style" width="100%">
                            <Styles
                                selectedStyle={selectedProduct.style}
                                changeLevel={changeLevel}
                                nextStyleStep={nextStyleStep}
                                backStyleStep={backStyleStep}
                                changeSelection={changeSelection}
                                country={this.props.country}
                            />
                        </div>
                    }


                    {stateTab.fabric && stateTab.style && stateTab.summary && <>
                        <div style={{ display: "flex", flexDirection: "column", padding: "15px", height: "350px" }}>
                            <div style={{ display: "flex" }}>
                                <div style={{ width: "100px", fontSize: "12px" }}>Fabric:</div>
                                <div style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: "initial",
                                    fontSize: "12px",
                                    width: "100%"
                                }}>
                                    <span style={{ background: "#e2e2e2", padding: "3px" }}>
                                        {
                                            selectedProduct.fabric.tag_en
                                        }
                                    </span>

                                </div>
                            </div>
                            <div style={{ borderBottom: "1px solid black", marginTop: "15px", marginBottom: "15px" }}></div>
                            <div style={{ display: "flex" }}>
                                <div style={{ width: "100px", fontSize: "12px" }} >Style:</div>
                                <div style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: "initial",
                                    fontSize: "12px",
                                    width: "100%"
                                }}>
                                    {selectedProduct.style.option &&
                                        selectedProduct.style.option.map(data =>
                                            <div
                                                key={data.cat_option.option_element.id}
                                                style={{ background: "#e2e2e2", padding: "3px", marginTop: "5px", marginRight: "5px" }}
                                            >
                                                {data.cat_option.option_element.tag_en}
                                            </div>)
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="footer-style" style={{ marginBottom: "-12px", marginTop: "10px", display: "flex", justifyContent: "space-between" }}>
                            <button
                                onClick={() => backSummaryStep()}
                                style={{
                                    background: "#011726",
                                    height: "35px",
                                    width: "75px",
                                    color: "white",
                                    fontSize: "9pt",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontWeight: "bold",
                                    border: "0",
                                }}
                            >
                                BACK
                            </button>

                            <button
                                onClick={() => addToCart()}
                                style={{
                                    background: "#011726",
                                    height: "35px",
                                    width: "75px",
                                    color: "white",
                                    fontSize: "9pt",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontWeight: "bold",
                                    border: "0",
                                    lineHeight: "1"
                                }}
                            >
                                GUARDAR
                            </button>
                        </div>
                    </>
                    }
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                        fontFamily: "monospace",
                        marginTop: "-30px"
                    }}>

                        {solvePrice()}

                    </div>
                </div>
                <div style={{ paddingLeft: "35px", maxWidth: "300px !important", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    <h3>Configuraciones</h3>
                    <ul class="list-group">
                        {
                            adminProduct.configurations &&
                            adminProduct.configurations.map((conf, index) =>
                                <li key={'CF' + index} className="list-group-item d-flex justify-content-between align-items-center" style={{ minWidth: "300px" }}>
                                    {conf.name}
                                    <span>
                                        <span class="badge bg-secondary mr-2" style={{ color: 'white' }}>{conf.config.style.level}</span>
                                        <i className="fa fa-edit text-primary mr-2" style={{ cursor: "pointer" }}></i>
                                        <i className="fa fa-trash text-danger" style={{ cursor: "pointer" }} onClick={() => deleteConfigIndex(index)}></i>
                                    </span>
                                </li>
                            )
                        }
                    </ul>
                </div>

            </div>

            <Footer />


             <Modal isOpen={modal} toggle={toggle} centered={true}>
                <ModalHeader toggle={toggle}>
                    Guardar Configuracion
                </ModalHeader>
                <ModalBody>
                    Nombre Ingles
                    <input type="text" onChange={changeConfigName} className="form-control" />
                    Nombre Español
                    <input type="text" onChange={changeConfigNameSpanish} className="form-control" />
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => { toggle() }}>Cancelar</Button>{' '}
                    <Button color="primary" onClick={() => { GuardarEstilo() }}>Guardar</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={modalDelete} toggle={toggleDelete} centered={true}>
                <ModalHeader toggle={toggleDelete}>
                    Eliminar Configuracion
                </ModalHeader>
                <ModalBody>
                    Desea eliminar la configuracion?
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => { toggleDelete() }}>Cancelar</Button>{' '}
                    <Button color="primary" onClick={() => { EliminarEstilo() }}>Eliminar</Button>
                </ModalFooter>
            </Modal>

        </div>)
    }
}

const mapStateToProps = (state, ownProps) => {
    let idProduct = ownProps.match.params.id;

    return {
        product: state.products.list.filter(pro => pro.productType === idProduct),
        idProduct: idProduct,
        country: state.Intl.locale,
        symbol: state.data.symbol
    }

};

export default connect(mapStateToProps)(withTranslate(SingleProduct));
