/** @format */

const ALL_FETCHING = "vendors/ALL_FETCHING";
const ALL_FETCH_SUCCESS = "vendors/ALL_FETCH_SUCCESS";
const ALL_FETCH_FAILURE = "vendors/ALL_FETCH_FAILURE";

const MORE_ALL_FETCHING = "vendors/MORE_ALL_FETCHING";
const MORE_ALL_FETCH_SUCCESS = "vendors/MORE_ALL_FETCH_SUCCESS";
const MORE_ALL_FETCH_FAILURE = "vendors/MORE_ALL_FETCH_FAILURE";

const FETCHING = "vendors/FETCHING";
const FETCH_SUCCESS = "vendors/FETCH_SUCCESS";
const FETCH_FAILURE = "vendors/FETCH_FAILURE";

const MORE_FETCHING = "vendors/MORE_FETCHING";
const MORE_FETCH_SUCCESS = "vendors/MORE_FETCH_SUCCESS";
const MORE_FETCH_FAILURE = "vendors/MORE_FETCH_FAILURE";

const CLEAN_PRODUCT = "vendors/CLEAN";

export {
  ALL_FETCHING,
  ALL_FETCH_SUCCESS,
  ALL_FETCH_FAILURE,
  MORE_ALL_FETCHING,
  MORE_ALL_FETCH_SUCCESS,
  MORE_ALL_FETCH_FAILURE,
  FETCHING,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  MORE_FETCHING,
  MORE_FETCH_SUCCESS,
  MORE_FETCH_FAILURE,
  CLEAN_PRODUCT,
};
