import React, {
    Component
} from 'react';
import {
    connect
} from 'react-redux';
import {
    withTranslate
} from 'react-redux-multilingual';

import {urlTableIncompatibility, urlFabric} from "../../constants/proxy";
const {
    SP_API_ADMIN_URL
} = process.env;
class OnloadProduct extends Component {

    componentDidMount() {
        const {
            idProduct
        } = this.props;
        let adminProducts = JSON.parse(localStorage.products);

        fetch(urlTableIncompatibility)
        .then(response => response.json())
        .then(data => {
            localStorage.setItem('tableIncompatibility', JSON.stringify(data.data));
        });

    fetch(urlFabric)
        .then(response => response.json())
        .then(data => {
            localStorage.setItem('fabric', JSON.stringify(data.data));
        });

        adminProducts.forEach(element => {
            if (element.code === idProduct) {
                fetch(`${SP_API_ADMIN_URL}/v2/products/${element._id}`)
                    .then(response => response.json())
                    .then(data => {
                        localStorage.setItem('adminProductSelecte', JSON.stringify(data.data));
                        
                        this.props.history.push(`/custom-admin-product/${idProduct}`);
                    });
            }
        });
    }
    render() {
        return <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
            <img src="/assets/img/loading.svg" alt="" />
        </div> ;
    }
 
}


const mapStateToProps = (state, ownProps) => {
    let idProduct = ownProps.match.params.id;

    return {
        product: state.products.list.filter(pro => pro.productType === idProduct),
        idProduct: idProduct,
        country: state.Intl.locale,
        symbol: state.data.symbol
    }

};

export default connect(mapStateToProps)(withTranslate(OnloadProduct));