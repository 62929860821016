import React from 'react';

class TitlePages extends React.Component {

    render() {
        return (
            <div className="title-product-head">
                <img src="/assets/img/flecha-izquierda.svg" className="flechaIzquierda" onClick={()=>  window.location.href = `${window.location.origin} `} alt=""/>
                {this.props.title}
            </div>
        );
    }
}

export default TitlePages