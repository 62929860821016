import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslate, IntlActions } from 'react-redux-multilingual'
import store from '../../../store';
import { connect } from 'react-redux';
import { logoutUserAndCleanCart } from '../../../reducers/user/operations'

class Navbar extends Component {

	constructor(props) {
		super(props);

		let productsCart = JSON.parse(localStorage.cart);

		if (productsCart.length > 0) {
			productsCart = productsCart.filter(item => item.country = this.props.country);
		}


		this.state = {
			cart: productsCart.length,
			showMobilMenu: false
		}

	}

	componentDidMount() {

	}

	changeLanguage(lang) {
		store.dispatch(IntlActions.setLocale(lang))
	}

	componentWillMount() {
		window.addEventListener('scroll', this.handleScroll);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll = () => {
		let number = window.pageXOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
		let publicUrl = process.env.PUBLIC_URL + '/'
		if (number >= 100) {
			document.getElementById("sticky").classList.add('navbar-fixed');
			document.getElementById("logo-stile").src = publicUrl + "assets/img/logo-stile-white.png";
			document.getElementById("nav-font").classList.add('color-secondary-navbar');
			document.getElementById("options").classList.add('color-secondary-navbar');
			document.getElementById("nameUser").classList.add('name-user-two');
		} else {
			document.getElementById("sticky").classList.remove('navbar-fixed');
			document.getElementById("logo-stile").src = publicUrl + "assets/img/logo.png";
			document.getElementById("nav-font").classList.remove('color-secondary-navbar');
			document.getElementById("options").classList.remove('color-secondary-navbar');
			document.getElementById("nameUser").classList.remove('name-user-two');
		}
	}

	logOut() {
		this.props.logoutUserAndCleanCart();
	}

	render() {
		let publicUrl = process.env.PUBLIC_URL + '/'
		const { translate, user } = this.props;
		const { showMobilMenu } = this.state;

		let textoAi = "";

		console.log(this.props.sectionsText);

		this.props.sectionsText.forEach(sec => {
			if (sec.code === "TXTOBAN") {
				textoAi = sec.texto;
			}
		});
		return (
			<div className="stoon-navbar absolute-nav">
				<nav className="navbar navbar-area navbar-expand-lg nav-style-01 header-top">
					<div id="sticky" className="container-fluid nav-container">
						<div className="row navbar-sp">
							<div className="col-lg-2 col-4 order-1 align-self-center">
								<div className="logo">
									<Link to={`/`}>
										<img id="logo-stile" src={publicUrl + "assets/img/logo.png"} className="logo-stile miImagen" alt="stilepersonale" />
									</Link>
								</div>
							</div>
							<div className="col-lg-7 order-3 order-lg-2">
								<div className={`${window.innerWidth < 900 ? `${showMobilMenu ? "collapse navbar-collapse show" : "collapse navbar-collapse"}` : "collapse navbar-collapse"}`} id="shop-menu" >
									<ul id="nav-font" className="navbar-nav menu-open" >
										<li>
											<Link to="/" onClick={(e) => (window.location.replace('/'))}>{translate('home')}</Link>
										</li>
										<li >
											<a onClick={() => {
												window.location.href = window.location.origin + "/products/men";
											}
											}>{translate('collection.titleMen')}</a>
										</li>
										<li >
											<a onClick={() => {
												window.location.href = window.location.origin + "/products/women";
											}}>{translate('collection.titleWomen')}</a>
										</li>
										<li><Link to="/about">{translate('about')}</Link></li>
										<li><Link to="/blog">{translate('blog')}</Link></li>
										{/* <li><Link to="/contact">{translate('contact')}</Link></li> */}
									</ul>
								</div>
							</div>
							<div className="col-lg-3 col-8 justify-content-end d-flex order-2 order-lg-3">
								<div className="nav-right-part">
									<ul id="options" className="d-flex justify-content-center">
										<li className="has-dropdown mt-1">
											<a className="options color-options" href="#">{translate('language')}</a>
											<ul>
												<li>
													<Link className="btn btn-cart" to="#" onClick={() => this.changeLanguage('en_US')}>
														<img id="usa" src={publicUrl + "assets/img/estados-unidos-de-america.svg"} width="25px" height="25px" alt="USA" />
													</Link>
												</li>
												<li>
													<Link className="btn btn-cart" to="#" onClick={() => this.changeLanguage('es_MX')}>
														<img id="mexico" src={publicUrl + "assets/img/mexico.svg"} width="25px" height="25px" alt="MÉXICO" />
													</Link>
												</li>
												<li>
													<Link className="btn btn-cart" to="#" onClick={() => this.changeLanguage('es_PA')}>
														<img id="panama" src={publicUrl + "assets/img/panama.svg"} width="25px" height="25px" alt="PANAMÁ" />
													</Link>
												</li>
											</ul>
										</li>
										<li className="has-dropdown mt-1">
											{(user.accessToken === null) ?
												<Link className="options color-options" to="/login">{translate('login')}<i className="icon-user" /></Link>
												:
												<Link className="options color-options" to="#" onClick={() => this.logOut()}>{translate('logout')}<i className="icon-user" /></Link>
											}
										</li>
										<li className="has-dropdown mt-1">
											<Link className="cart color-options" to="/cart"><i className="icon-add-to-cat" /><span id="countCartItem" className="badge ">{this.state.cart}</span></Link>
										</li>
									</ul>
									<div id="nameUser" className="name-user">
										<span>{(user.userInfo === null) ? '' : (user.userInfo.firstName === null) ? '' : translate('welcome') + ' ' + user.userInfo.firstName.toUpperCase()}</span>
									</div>
								</div>
								<div className="responsive-mobile-menu">
									<div className="menu toggle-btn d-block d-lg-none" onClick={() => { this.setState({ showMobilMenu: !showMobilMenu }) }}>
										<div className="icon-left" />
										<div className="icon-right" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</nav>
				<div className="banner-IA" dangerouslySetInnerHTML={{ __html: textoAi }}>
				</div>
			</div>
		)
	}
}




const mapStateToProps = (state) => {

	return {
		user: state.user,
		country: state.Intl.locale,
	}
};

export default connect(mapStateToProps, { logoutUserAndCleanCart })(withTranslate(Navbar));