import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslate } from 'react-redux-multilingual'
 import {
  UncontrolledCarousel
 } from 'reactstrap';



class Background extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { translate } = this.props;

    let items = [];

    this.props.sections.forEach(sec => {
      if (sec.code === "MS001") {
        items.push({ src: sec.img, caption: "", altText: "slider", order: sec.order })
      }
    })

    let titleSlider = "";
    this.props.sectionsText.forEach(sec => {
      if (sec.code === "TXTMAIN") {
        titleSlider = sec.texto;
      }
    });

    items.sort(function (a, b) {
      return a.order - b.order;
    }); 

    return <div className="banner-style-01 ">
      <div className="margin-component">
        <UncontrolledCarousel items={items} autoPlay={true} indicators={false} controls={false} /> 
        
        <div className="container-fluid" style={{ position: "absolute", marginTop: (window.innerWidth > 800 ? "-350px" : "-250px") }}>
          <div className="banner-content">
            <h4
              className="subtitle title-slider"
              data-animation-in="fadeInLeft"
              dangerouslySetInnerHTML={{ __html: translate('subtitleSlider1') }}
            >
            </h4>
            <h2
              className="title title-slider"
              data-animation-in="fadeInRight"
              dangerouslySetInnerHTML={{ __html: titleSlider }}
            >
            </h2>
            <div className="mt-3 pl-1">
              <div className="btn-wrapper" data-animation-in="fadeInDown">
                <Link
                  className="btn btn-white btn-home-shop"
                  to="/all-products"
                  dangerouslySetInnerHTML={{ __html: translate('seeMore') }}
                >
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

export default withTranslate(Background)