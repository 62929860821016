import React from 'react';
import Navbar2 from './common/Navbar2';
import Footer from './common/Footer';
import TitlePage from './common/TitlePage';
import { withTranslate } from 'react-redux-multilingual'


class Test
    extends React.Component {

    render() {
        const margin = {
            marginTop: "100px"
        }

        const centerImg = { position: "absolute", left: "200px" }
        let imgBody = `${window.location.origin}/test/SAI021A-CAPAA1.jpg`;
        let imgCuello = `${window.location.origin}/test/SAI021A-SH5AL5.png`;
        let imgManga = `${window.location.origin}/test/SAI021A-SH5410.png`;
        //let imgBody = 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/131045/colorful-triangles.jpg';


        function loadImage(src) {
            let canvas = document.getElementById("desing");
            let canvasV = canvas.getContext("2d");
            
            var img = new Image();
            img.onload = function (e) {
                canvasV.drawImage(img, 0, 0);
            };
            img.src = src;
        }

        setTimeout(() => {
            loadImage(imgBody);
            setTimeout(() => {
                loadImage(imgCuello);
                setTimeout(() => {
                    loadImage(imgManga);
                }, 1000);
            }, 1000);
        }, 1000);



        return <div className="absolute-nav">
            <Navbar2 />
            <TitlePage title={"Promocion"} />

            <div style={{ padding: "0", display: "flex", justifyContent: "center", color: "#203341", fontSize: "18px", fontWeight: 600, flexDirection: "column", alignItems: "center" }}>

                <div style={{ width: "624px", height: "676px", display: "none" }} >
                    <img src="/test/SAI021A-CAPAA1.jpg" alt="" style={centerImg} />
                    <img src="/test/SAI021A-SH5AL5.png" alt='' style={centerImg} />
                    {/* <img src="/test/roma_top.png" alt='' style={centerImg}/> */}
                    {/* <img src="/test/btnh_body.png" alt='' style={centerImg}/> */}
                    {/* <img src="/test/roma_body.png" alt='' style={centerImg}/> */}
                    <img src="/test/SAI021A-SH5410.png" alt='' style={centerImg} />
                    {/* <img src="/test/btnh_lobc.png" alt='' style={centerImg}/> */}
                    {/* <img src="/test/roma_lobc.png" alt='' style={centerImg}/> */}
                </div>

                <canvas id="desing" width="624" height="676"></canvas>
            </div>

            <div style={margin}>
                <Footer />
            </div>
        </div>
    }

}

export default withTranslate(Test)