import React from 'react';
import Navbar from './common/Navbar2';
import Footer from './common/Footer';
import TitlePage from './common/TitlePage';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslate, IntlActions } from 'react-redux-multilingual'
import { giftUpAuthKey } from "../../constants/managementKeys";
import { urlGiftUp, headerGiftUp } from "../../constants/proxy";
import { 
    urlDescuentos
} from "../../constants/proxy";

class Cart extends Component {

    constructor(props) {
        super(props);

        let productsCart = JSON.parse(localStorage.cart);
        let giftCardCode = (localStorage.giftCardCode !== undefined ? JSON.parse(localStorage.giftCardCode) : null);
        let descuentosAplicados = (localStorage.descuentosAplicados !== undefined ? JSON.parse(localStorage.descuentosAplicados) : null);
        let descuentos = (localStorage.descuentos !== undefined ? JSON.parse(localStorage.descuentos) : null);

        const { country } = this.props;

        this.state = {
            cart: productsCart.filter((cart) => (cart.country === country)),
            total: 0,
            giftCardCode: giftCardCode,
            giftCardCodeText: "",
            descuentos: descuentos,
            descuentosAplicados: descuentosAplicados
        }
    }

    componentDidMount() {
        fetch(urlDescuentos)
            .then(response => response.json())
            .then(data => {
                this.setState({ descuentos: data.data })
                localStorage.setItem('descuentos', JSON.stringify(data.data));
            });
    }

    render() {
        const { translate } = this.props;
        const { cart, total, giftCardCode, giftCardCodeText, descuentosAplicados, descuentos, codigoDescuentoText } = this.state;

        const deleteCondition = (index) => {
            cart.splice(index, 1);
            localStorage.setItem('cart', JSON.stringify(cart));

            this.setState({
                cart: cart
            });

            window.location.reload();
        }

        const add = (index) => {
            var total;

            cart[index].qty += 1;
            this.setState({
                cart: cart
            });

            try {
                setTimeout(() => {
                    window.affirm.ui.refresh();
                }, 500);
            } catch (error) {
                console.log(error);
            }

            localStorage.setItem('cart', JSON.stringify(cart));
        }

        const substract = (index) => {
            cart[index].qty -= 1;
            this.setState({
                cart: cart
            });

            try {
                setTimeout(() => {
                    window.affirm.ui.refresh();
                }, 500);
            } catch (error) {
                console.log(error);
            }

            localStorage.setItem('cart', JSON.stringify(cart));

            if (cart[index].qty === 0) {
                deleteCondition(index);
            }
        }

        const subTotalCart = () => {
            let total = 0;
            cart.forEach(item => {
                total += (item.qty * item.price)
            });

            return parseFloat(total).toFixed(2);
        };

        const totalCart = () => {
            let total = 0;
            cart.forEach(item => {
                total += (item.qty * item.price)
            });

            if (giftCardCode !== null) {
                let giftValue = giftCardCode.remainingValue;

                if (parseFloat(giftValue) > parseFloat(total)) {
                    total = 0.00;
                } else {
                    total -= parseFloat(giftValue);
                }
            }

            if (totalDiscountCode() > 0) {
                total -= parseFloat(totalDiscountCode());
            }

            return parseFloat(total).toFixed(2);
        };

        const totalGift = () => {
            let total = 0.00;
            let totalG = 0.00;
            let saldo = 0.00;
            cart.forEach(item => {
                total += (item.qty * item.price)
            });

            if (giftCardCode !== null) {
                let giftValue = giftCardCode.remainingValue;

                if (parseFloat(giftValue) > parseFloat(total)) {
                    saldo = parseFloat(parseFloat(giftValue) - parseFloat(total)).toFixed(2);
                    totalG = parseFloat(total).toFixed(2);
                } else {
                    totalG = parseFloat(giftValue).toFixed(2);
                }

            }

            return totalG.toFixed(2) + (saldo > 0 ? " (Balance:" + saldo.toFixed(2) + ")" : "");
        };


        const totalDiscountCode = () => {
            let totalD = 0.00;
            let total = 0.00;

            cart.forEach(item => {
                total += (item.qty * item.price)
            });

            if (descuentosAplicados != null) {
                if (descuentosAplicados.all == true) {
                    if (descuentosAplicados.tipo_descuento == "value") {
                        totalD += parseFloat(descuentosAplicados.valor);
                    }
                    else if (descuentosAplicados.tipo_descuento == "percentage") {
                        let porcentaje = parseFloat(parseFloat(descuentosAplicados.valor) / 100);
                        totalD = parseFloat(total * porcentaje);
                    }
                }
                else {
                    descuentosAplicados.productos.forEach(prodCode => {
                        cart.forEach(item => {
                            if (prodCode == item.code) {
                                if (descuentosAplicados.tipo_descuento == "value") {
                                    totalD += parseFloat(descuentosAplicados.valor);
                                }
                                else if (descuentosAplicados.tipo_descuento == "percentage") {
                                    let totalItem = (item.qty * item.price);
                                    let porcentaje = parseFloat(parseFloat(descuentosAplicados.valor) / 100);
                                    totalD = parseFloat(totalItem * porcentaje);
                                }
                            }
                        });

                    });
                }
            }

            return totalD.toFixed(2);
        }

        const applyGiftCardCode = () => {
            var requestOptions = {
                method: 'GET',
                headers: headerGiftUp,
                redirect: 'follow'
            };

            fetch(urlGiftUp + giftCardCodeText, requestOptions)
                .then(response => response.text())
                .then(result => {

                    try {
                        setTimeout(() => {
                            window.affirm.ui.refresh();
                        }, 500);
                    } catch (error) {
                        console.log(error);
                    }

                    localStorage.setItem("giftCardCode", JSON.stringify(JSON.parse(result)));
                    this.setState({ giftCardCode: JSON.parse(result) });
                })
                .catch(error => alert("Gift Card Not Found.."));
        }

        const applyDiscountCode = () => {
            descuentos.forEach(descuento => {
                if (descuento.code == codigoDescuentoText) {
                    localStorage.setItem("descuentosAplicados", JSON.stringify(descuento));
                    this.setState({ descuentosAplicados: descuento })
                }
            });
        }

        const changeGiftCardCode = (e) => {
            try {
                setTimeout(() => {
                    window.affirm.ui.refresh();
                }, 500);
            } catch (error) {
                console.log(error);
            }

            this.setState({
                giftCardCodeText: e.target.value
            })
        }

        const changeDiscountCode = (e) => {
            try {
                setTimeout(() => {
                    window.affirm.ui.refresh();
                }, 500);
            } catch (error) {
                console.log(error);
            }

            this.setState({
                codigoDescuentoText: e.target.value
            })
        }


        const removeGiftCard = () => {
            this.setState({
                giftCardCodeText: "",
                giftCardCode: null
            })
            localStorage.removeItem("giftCardCode");
        }
        return (
            <div className="cart">
                <Navbar />
                <TitlePage title={translate('product.cart')} />
                {(cart.length > 0)
                    ?
                    <div className="container mt-3 mb-5 pb-5">
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-sm-12 col-12 ">
                                <table className="table table-hover">
                                    <thead className="color-header center">
                                        <tr>
                                            <th></th>
                                            {/* <th>{translate('quantityCheckout')}</th> */}
                                            {/* <th>Price / Unit</th> */}
                                            <th></th>
                                            <th></th>
                                            {/* <th></th> */}
                                        </tr>
                                    </thead>
                                    <tbody >
                                        {cart.map((item, index) => {
                                            return (
                                                <>
                                                    <tr key={index + "2"}>
                                                        <td colSpan="3">
                                                            <strong>{item.name}</strong>
                                                        </td>
                                                    </tr>
                                                    <tr key={index} className="center">
                                                        <td><img className="responsive" src={item.img} height="100" alt="" /></td>
                                                        <td style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            justifyContent: "space-around",
                                                            alignItems: "center",
                                                            height: "100px"
                                                        }}>
                                                            <span style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center"
                                                            }}>
                                                                <button
                                                                    className="ml-2 mr-2 btn btn-checkout btn-opciones"
                                                                    onClick={() => substract(index)}
                                                                    style={{
                                                                        width: "30px",
                                                                        height: "25px",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center"
                                                                    }}
                                                                >-</button>
                                                                <span >{item.qty} </span>
                                                                <button
                                                                    className="ml-2 btn btn-checkout btn-opciones"
                                                                    onClick={() => add(index)}
                                                                    style={{
                                                                        width: "30px",
                                                                        height: "25px",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center"
                                                                    }}
                                                                >+</button>
                                                            </span>

                                                            <span style={{ fontWeight: "bold" }}>{parseFloat(item.qty * item.price).toFixed(2)}</span>
                                                        </td>
                                                        {/* <td>{item.price}</td> */}
                                                        <td className="colum-pointer" onClick={() => deleteCondition(index)} >{translate('removeCheckout')}</td>
                                                    </tr>
                                                </>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 col-12">

                                <div className="summary ">
                                    <h6 className="title">{translate('summaryCheckout')}</h6>
                                    <div className="w-100 mt-">
                                        <label htmlFor="input">{translate('discountCodeCheckout')}</label>
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" onChange={(e) => changeDiscountCode(e)} placeholder={translate('discountCodeCheckout')} value={codigoDescuentoText} />
                                            <div className="input-group-append">
                                                <button onClick={() => applyDiscountCode()} className="btn btn-outline-secondary btn-opciones" type="button">{translate('applyDiscountCodeCheckout')}</button>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        (this.props.country === "en_US" || this.props.country === "es_PA") &&
                                        <div className="w-100 mt-1 mb-2">
                                            <label htmlFor="input">{translate('gifthCardCodeCheckout')}</label>
                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control" onChange={(e) => changeGiftCardCode(e)} placeholder={translate('gifthCardCodeCheckout')} aria-label="Recipient's username" value={giftCardCodeText} aria-describedby="basic-addon2" />
                                                <div className="input-group-append">
                                                    <button onClick={() => applyGiftCardCode()} className="btn btn-outline-secondary btn-opciones" type="button">{translate('applyGifthCardCodeCheckout')}</button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <form action="#">
                                        <div className="d-flex justify-content-between total">
                                            <p>Subtotal</p>
                                            <p>{subTotalCart()}<span className=""></span></p>
                                        </div>
                                        <div className="d-flex justify-content-between total">
                                            <p>  {translate('gifthCardCheckout')}  {giftCardCode !== null && <i onClick={() => removeGiftCard()} className="remove-giftcard">{translate('removeCheckout')}</i>} </p>
                                            <p>{totalGift()}</p>
                                        </div>

                                        <div className="d-flex justify-content-between total">
                                            <p>  {translate('discountCheckout')} </p>
                                            <p>{totalDiscountCode()}</p>
                                        </div>
                                        <div className="d-flex justify-content-between total">
                                            <p>{translate('orderTotalCheckout')}</p>
                                            <p>
                                                {totalCart()} <span className=""></span></p>
                                        </div>


                                    </form>
                                    {this.props.country === "en_US" &&
                                        <p class="affirm-as-low-as text-center pt-3" data-page-type="product" data-amount={parseInt(totalCart() * 100)}></p>
                                    }
                                    <div className="btn-wrapper">
                                        <Link className="btn btn-checkout" to={(this.props.user.accessToken === null) ? '/login?redirect=checkout' : '/checkout'} >
                                            {translate('payCheckout')}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div>
                        <div className="column d-flex justify-content-center mt-5 pt-5">
                            <img src="assets/img/icon-empty-cart.png" alt="" />
                        </div>
                        <div className="column d-flex justify-content-center mt-5 mb-5 pb-5">
                            <h3>
                                <strong>Your Cart is Empty</strong>
                            </h3>
                        </div>
                    </div>
                }
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        country: state.Intl.locale
    }
};

export default connect(mapStateToProps)(withTranslate(Cart));
