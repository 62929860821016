import {
    giftUpAuthKey,
    PAkey
} from "./managementKeys";

const urlProxy = process.env.SP_PROXY_ADMIN_URL;
const urlApiAdmin = process.env.SP_API_ADMIN_URL;

export const urlConversion = urlApiAdmin + "/v2/conversion";
export const urlProducts = urlApiAdmin + "/v2/products/index/custom";
export const urlSections = urlApiAdmin + "/v2/section/custom-section";
export const urlSectionsText = urlApiAdmin + "/v2/section-text/custom-section";
export const urlFabric = urlApiAdmin + "/v2/fabric";
export const urlNewOrder = urlProxy + "/v1/shopify/new-order";
export const urlNewSale = urlApiAdmin + "/v2/sales";
export const urlTableIncompatibility = urlApiAdmin + "/v2/table-incompatibility";
export const urlDescuentos = urlApiAdmin + "/v2/descuentos";
export const urlCategory = urlApiAdmin + "/v2/category";

export const urlGiftUp = "https://api.giftup.app/gift-cards/";

export const headerGiftUp = {
    "Authorization": giftUpAuthKey,
    "Accepts": "application/json",
    "x-giftup-testmode": process.env.SP_GIFTHUP_TEST_MODE,
    "Content-Type": "application/json"
};

/**
 * Url PAYMENT PANAMA
 */

 export const urlPanama = process.env.SP_PA_PAYMENT_URL;



 export const headerPA = {
    "Authorization": PAkey,
    "Content-Type": "application/json"
};