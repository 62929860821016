import React, { Component } from 'react';
import SocialButton from './SocialButton';
import { register } from "../../reducers/user/operations"
import { connect } from "react-redux";
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import Navbar from './common/Navbar2';
import Footer from './common/Footer';

var md5 = require('md5');

class Register extends Component {

  constructor(props) {
    super(props)

    let state = {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      url: "",
      redirect: false,
      valueUrl: ""
    };

    const params = props.params;

    if (params && params.user) {
      state = { ...state, ...params.user };
    }

    this.state = state;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getUrlVars = this.getUrlVars.bind(this);
  }

  componentDidMount() {
    this.state.valueUrl = this.getUrlVars()["redirect"];
    if (this.state.valueUrl !== null) {
      this.setState({
        url: this.state.valueUrl,
        redirect: true
      });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    var datos = {
      email: this.state.email,
      password: md5(this.state.password),
      firstName: this.state.firstName,
      lastName: this.state.lastName
    };
    this.props.register(datos, false);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      vars[key] = value;
    });
    return vars;
  }

  render() {
    const handleSocialLogin = (user) => {
      let social = true;
      let redirect = false;
      const datos = {
        email: user._profile.email,
        password: md5(user._profile.email),
        firstName: user._profile.name,
        lastName: user._profile.lastName
      };

      this.props.register(datos, social, redirect, this.state.url);
    }

    const handleSocialLoginFailure = (err) => {
      console.error(err)
    }

    function parseJwt(token) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    }

    const responseGoogle = async (response) => {
      console.log(response);
      const profile = parseJwt(response.credential);
      console.log(profile);
      let social = true;
      const datos = {
        email: profile.email,
        password: md5(profile.email),
        lastName: profile.family_name,
        firstName: profile.given_name
      };

      this.props.login(datos, social, this.state.redirect, this.state.url);
    }

    return (
      <React.Fragment>
        <Navbar />
        <br />
        <div className="container login register mt-5 pt-5">
          <div className="row margin">
            <img className="img-register" src={`${process.env.PUBLIC_URL}/assets/img/banner/stile-personale-1.gif`} alt="" />
            <div className="div-form">
              <div className="center">
                <div className="div-login">
                  <img className="img-stile" src={`${process.env.PUBLIC_URL}/assets/img/logo.png`} alt="" />
                </div>
                <span className="create-your-account">Create your account</span>
                <input
                  type="text"
                  name="firstName"
                  placeholder="FIRST NAME" required={true}
                  onChange={this.handleChange}
                />
                <input
                  type="text"
                  name="lastName"
                  placeholder="LAST NAME" required={true}
                  onChange={this.handleChange}
                />
                <input
                  type="text"
                  name="email"
                  placeholder="EMAIL" required={true}
                  onChange={this.handleChange}
                />
                <input
                  type="password"
                  name="password"
                  placeholder="PASSWORD" required={true}
                  onChange={this.handleChange}
                />
                <p>by signing up you agree to the terms of service privacy polity</p>
                <div className="sign">
                  <a href className="title" type="submit" onClick={this.handleSubmit} >SIGN UP</a>
                </div>
                {/* <SocialButton className="sign-face"
                  provider='facebook'
                  appId='3259655317417730'
                  onLoginSuccess={handleSocialLogin}
                  onLoginFailure={handleSocialLoginFailure}
                >
                  <span className="title">FACEBOOK</span>
                </SocialButton> */}
                <br />
                <GoogleOAuthProvider clientId="160661769409-q55okhcuc4jd1tbqqt9jtegogslm59pr.apps.googleusercontent.com">
                  <GoogleLogin
                    className="sign-google"
                    onSuccess={responseGoogle}
                    onError={() => {
                      console.log('Login Failed');
                    }}
                  />
                </GoogleOAuthProvider>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    symbol: state.data.symbol
  }
}

export default connect(mapStateToProps, { register })(Register);