import React from 'react';
import Navbar2 from './common/Navbar2';
import Footer from './common/Footer';
import TitlePage from './common/TitlePage';
import { withTranslate } from 'react-redux-multilingual'

class Policy extends React.Component {

  render() {
    let publicUrl = process.env.PUBLIC_URL + '/'

    const margin = {
      marginTop: "-6px"
    }

    const { translate } = this.props;

    return <div className="absolute-nav">
      <Navbar2 />
      <TitlePage title={translate('product.about')} />
      <div>
        <div className="about  pt-5">
          <div className="row">
            <div className="col-lg-2 col-md-2 col-sm-12">
            </div>
            <div className="col-md-8 col-sm-12 p-5">
              <b><h2 className="title">{translate('policy.title')}</h2></b>
              <p dangerouslySetInnerHTML={{ __html: translate('policy.html') }}></p>
              <br />
              <div className="mt-5">
                <img src={publicUrl + 'assets/img/favicon.png'} className="img-about-us-finger" alt="" />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12">
            </div>
          </div>
        </div>
      </div>
      <div style={margin}>
        <Footer />
      </div>
    </div>
  }

}

export default withTranslate(Policy)