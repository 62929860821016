import React, { Component } from 'react';
import Slider from 'react-slick';
import { connect } from 'react-redux';
import { getProductsTop20 } from '../../../services/index';
import { Product4, Product5 } from '../../../services/script';
import { Link } from 'react-router-dom';

class SliderProduct extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.state = {
      adminProducts: (localStorage.products === null || localStorage.products === undefined) ? [] : JSON.parse(localStorage.products),
      conversions: (localStorage.conversion === null || localStorage.conversion === undefined) ? [] : JSON.parse(localStorage.conversion)
    };
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  render() {
    const { items, type, country } = this.props;
    const { adminProducts, conversions } = this.state;

    var properties;
    if (type === 'kids') {
      properties = Product5
    } else {
      properties = Product4
    }

    const filterPrice = (product) => {
      let price = product.price;
      let currency = "";
      let exchange_rate= 1;
      conversions.forEach(conver => {
        if (conver.code_country === country) {
          currency = conver.code_currency;
          exchange_rate = conver.exchange_rate;
        }
      });

      adminProducts.forEach(pr => {
        if (pr.code === product.productType) {
          pr.countries.forEach(ct => {
            if (ct.code === country) {
              price = (ct.min_price *  exchange_rate) + " - " + (ct.max_price * exchange_rate);
            }
          });
        }
      });



      return currency + " " + price;
    };

    const filterAvalible = () => {
      let productsList = items;
      let list = [];
      productsList.forEach(element => {
        adminProducts.forEach(pr => {
          if (pr.code === element.productType) {
            pr.countries.forEach(ct => {
              if (ct.code === country && ct.avalible === true) {
                list.push(element);
              }
            });
          }
        });
      });
      return list;
    }

    return (
      <div className="container banner-style-02 slider-home">
        <img className="previus" src="/assets/img/icons/arrow-left.png" onClick={this.previous} alt="Arriw Left" />
        <Slider ref={c => (this.slider = c)} {...properties} className="product-4 product-m no-arrow text-center">
          {filterAvalible().map((product, index) =>
            <div key={index} className="col-lg-3 col-md-3 col-sm-6 col-6 mb-5 mt-5 w-100">
              <div className="center w-100">
                <Link to={`${process.env.PUBLIC_URL}/product/${product.productType}`} >
                  <img className="img-product" src={product.defaultImage} alt={product.productType} />
                </Link>
                <div className="mt-3 slider-homo-footer">
                  {product.title}
                </div>
                <div className="slider-homo-footer">
                  {filterPrice(product)}
                </div>
              </div>
            </div>
          )}
        </Slider>
        <img className="next" src="/assets/img/icons/arrow-right.png" onClick={this.next} alt="Arrow Rigth" />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  items: getProductsTop20(state.products.list),
  country: state.Intl.locale
})

export default connect(mapStateToProps)(SliderProduct);