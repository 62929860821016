import React from 'react';
import Navbar2 from './common/Navbar2';
import ContactForm from './common/ContactForm';
import Footer from '../pages/common/Footer';
import TitlePage from './common/TitlePage';
import { withTranslate } from 'react-redux-multilingual'

class Contact extends React.Component {

    render(){
        const {translate} = this.props;
        return <div>
        <Navbar2 />
        <TitlePage title={translate('product.contact')}/>
        <ContactForm />
        <Footer />
      </div>
    }
}

export default withTranslate(Contact);

