import React, { Component } from 'react';
import Navbar2 from '../pages/common/Navbar2';
import Footer from '../pages/common/Footer';
import { connect } from 'react-redux';
import { withTranslate, IntlActions } from 'react-redux-multilingual';
import FabricComponent from './Fabric';
import Styles from './Styles';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
const {
    SP_CUSTOM_STYLE
} = process.env;

class SingleProduct extends Component {
    constructor(props) {
        super(props);

        const { product } = this.props;

        let adminFabrics = JSON.parse(localStorage.fabric);
        let conversions = JSON.parse(localStorage.conversion);
        let tableIncompatibility = JSON.parse(localStorage.tableIncompatibility);


        let adminProduct = JSON.parse(localStorage.adminProductSelecte);
        let tempCatFabric = [];
        let tempSection = [];
        let tempOptions = [];

        try {
            /*adminProducts.forEach(element => {
                if (element.code === idProduct) {
                    adminProduct = element;
                }
            });*/

            adminProduct.cat_section.forEach(section => {
                if (section.tag_en === "Fabric") {
                    section.cat_option.forEach(op => {
                        adminFabrics.forEach(fabric => {
                            if (op.code === fabric.code) {
                                tempCatFabric = fabric.cat_fabric;
                            }
                        });
                    });
                } else {
                    if (section.basic === true) {
                        tempSection.push(section);
                    }
                }
            });

            tempSection.sort((a, b) => a.order - b.order).forEach(section => {
                section.cat_option.sort((a, b) => a.order - b.order).filter(op => op.basic === true).forEach(op => {
                    let opStatus = true;
                    op.option_element.filter(el => el.basic === true).forEach(element => {
                        if (opStatus === true) {
                            opStatus = false;

                            let tempData = { ...section };
                            let tempOp = { ...op };

                            tempOp.option_element = element;
                            tempData.cat_option = tempOp;

                            tempOptions.push(tempData)
                        }
                    });
                });
            });
        } catch (error) {
        }

        adminProduct.cat_section = adminProduct.cat_section.sort((a, b) => a.order - b.order);
        tempSection = tempSection.sort((a, b) => a.order - b.order);

        let selectedProduct = {
            fabric: {},
            style: {
                level: "basic",
                option: tempOptions,
                sections: tempSection
            },
            sumarry: {},
            stateTab: {
                fabric: true,
                style: false,
                summary: false
            }
        };

        if (localStorage.getItem(adminProduct._id) !== null) {
            let localSecctionsSelected = JSON.parse(localStorage.getItem(adminProduct._id));
            selectedProduct = localSecctionsSelected;
        } else {
            localStorage.setItem(adminProduct._id, JSON.stringify(selectedProduct));
        }

        this.state = {
            product: product[0],
            adminProduct: adminProduct,
            adminFabrics: adminFabrics,
            fabricElements: tempCatFabric,
            conversions: conversions,
            tabActive: "Default",
            imgPrincipal: adminProduct.img_default,
            imgPrincipalShow: true,
            imgTextAlternative: "",
            saiaWidgetReact: false,
            reloadImg: true,
            tableIncompatibility: tableIncompatibility,
            monogramText: "",
            selectedProduct: selectedProduct,
            modal: false,
            mobileNavigation: false,
            zIndex: -100
        };

    }



    componentDidMount() {

    }

    componentWillMount() {
        setTimeout(() => {
            window.scroll(0, 95);
        }, 1000);
    }


    render() {
        const { translate, country } = this.props;
        const { adminProduct, product, imgPrincipal, reloadImg, imgPrincipalShow, fabricElements, selectedProduct, conversions, modal, mobileNavigation } = this.state;
        const { stateTab } = selectedProduct;

        const doesImageExist = (url) => {
            new Promise((resolve) => {
                const img = new Image();
                img.src = url;
                img.onload = () => {
                    resolve(true);
                    console.log('Existe');
                    this.setState({ imgPrincipalShow: false })
                };
                img.onerror = () => {
                    resolve(false);
                    console.log('No Existe');
                    this.setState({ imgPrincipalShow: true })
                };
            });
        }

        const selectedFabric = (fabric) => {
            this.setState({
                selectedProduct: {
                    ...this.state.selectedProduct,
                    fabric: fabric
                }
            })

            try {
                setTimeout(() => {
                    window.affirm.ui.refresh();
                }, 500);
            } catch (error) {
                console.log(error);
            }

            localStorage.setItem(adminProduct._id, JSON.stringify({
                ...this.state.selectedProduct,
                fabric: fabric
            }));

            let imgCapas = document.getElementById("imgCapas");
            imgCapas.innerHTML = '';
            let urlImg = `https://s3.amazonaws.com/admin.stilepersonale.com/blanca_Mesa.png`;
            loadImage(urlImg)

            selectedProduct.style.option.forEach(op => {
                if (adminProduct.section_default === op.cat_option.code) {

                    let url = `${SP_CUSTOM_STYLE}/${adminProduct.code}/${fabric.code}/${op.code}/${op.cat_option.code}/${op.cat_option.option_element.code}.png`;
                    let elementCode = op.cat_option.option_element.code;
                    setTimeout(() => {

                        if (adminProduct.code === "CAPAF1") {
                            if (op.cat_option.code === 'COPAAJ') {
                                selectedProduct.style.option.forEach(opB => {
                                    if (opB.cat_option.code === 'COPACH') {
                                        let pantCode = opB.cat_option.option_element.code;
                                        url = `${SP_CUSTOM_STYLE}/${adminProduct.code}/${selectedProduct.fabric.code}/${op.code}/${op.cat_option.code}/${pantCode}/${op.cat_option.option_element.code}.png`;
                                    }
                                });
                            }
                        }

                        loadImage(url);
                        selectedProduct.style.option.forEach(opB => {
                            if (opB.cat_option.code === 'COPABE') {
                                let urlB = `${SP_CUSTOM_STYLE}/${adminProduct.code}/${selectedProduct.fabric.code}/${opB.code}/${opB.cat_option.code}/${elementCode}/${opB.cat_option.option_element.code}.png`;
                                loadImage(urlB);
                            }
                        });
                        doesImageExist(url);

                        selectedProduct.style.option.forEach(op => {
                            if (adminProduct.section_default !== op.cat_option.code) {
                                let url = `${SP_CUSTOM_STYLE}/${adminProduct.code}/${fabric.code}/${op.code}/${op.cat_option.code}/${op.cat_option.option_element.code}.png`;
                                let urlTemp = `${SP_CUSTOM_STYLE}/${adminProduct.code}/${fabric.code}/${op.code}/${op.cat_option.code}/${op.cat_option.option_element.code}`;
                                let elementCode = op.cat_option.option_element.code;
                                let buttonCollar = false;
                                let butttonCode = '';
                                if (op.cat_option.code === 'COPAAQ') {

                                    selectedProduct.style.option.forEach(opB => {
                                        console.log('OP:', opB);
                                        if (opB.cat_option.code === 'COPAAL') {
                                            buttonCollar = true;
                                            butttonCode = opB.cat_option.option_element.code;
                                        }
                                    });
                                }

                                setTimeout(() => {

                                    if (adminProduct.code === "CAPAF1" && op.cat_option.code === 'COPAAJ') {

                                    } else {
                                        loadImage(url);
                                        selectedProduct.style.option.forEach(opB => {
                                            if (opB.cat_option.code === 'COPACH') {

                                            }
                                            else if (opB.cat_option.code === 'COPABE') {
                                                let urlB = `${SP_CUSTOM_STYLE}/${adminProduct.code}/${selectedProduct.fabric.code}/${opB.code}/${opB.cat_option.code}/${elementCode}/${opB.cat_option.option_element.code}.png`;

                                                if (buttonCollar) {
                                                    urlB = `${urlTemp}/${butttonCode}/${opB.cat_option.option_element.code}.png`;
                                                    console.log('URL:', urlB);
                                                }

                                                loadImage(urlB);
                                            }
                                        });
                                    }

                                }, 500);
                            }
                        });
                    }, 500);
                }
            });
        }

        const selectTab = (style, summary) => {

            if (selectedProduct.fabric.id === undefined) {
                style = false;
                summary = false;
            }


            this.setState({
                selectedProduct: {
                    ...this.state.selectedProduct,
                    stateTab: {
                        fabric: true,
                        style: style,
                        summary: summary,
                    }
                }
            })

            localStorage.setItem(adminProduct._id, JSON.stringify({
                ...this.state.selectedProduct,
                stateTab: {
                    fabric: true,
                    style: style,
                    summary: summary
                }
            }));
        }

        const changeStyleLevel = (e) => {

            adminProduct.configurations.forEach(config => {
                if (e.target.value === config.name && config.config.style.level === selectedProduct.style.level) {
                    config.config.stateTab.summary = false;
                    this.setState({
                        selectedProduct: config.config
                    })

                    localStorage.setItem(adminProduct._id, JSON.stringify(config.config));
                }
            });
        }

        const changeLevel = (e) => {

            let level = e.target.value;
            let tempSection = [];
            adminProduct.cat_section.forEach(section => {
                if (section.tag_en != "Fabric" && section[level] == true) {
                    tempSection.push(section);
                }
            });

            let tempOptions = [];

            tempSection.forEach(sec => {
                sec.cat_option.forEach(op => {
                    let opStatus = true;
                    if (op.option_element && op[level] == true) {
                        op.option_element.forEach(el => {
                            if (el[level] == true && opStatus == true) {
                                opStatus = false;

                                let tempData = { ...sec };
                                let tempOp = { ...op };
                                tempOp.option_element = el;
                                tempData.cat_option = tempOp;
                                tempOptions.push(tempData);
                            }
                        });
                    }
                })
            })


            this.setState({
                selectedProduct: {
                    ...this.state.selectedProduct,
                    style: {
                        level: level,
                        option: tempOptions,
                        sections: tempSection
                    }
                }
            })


            localStorage.setItem(adminProduct._id, JSON.stringify({
                ...this.state.selectedProduct,
                style: {
                    level: level,
                    option: tempOptions,
                    sections: tempSection
                }
            }));
        }

        const changeSelection = (element, option) => {
            let options = this.state.selectedProduct.style.option;
            let tempOption = [];
            let section = true;

            if (element.code === "custom_code") {

            }

            options.forEach(ops => {
                if (ops.cat_option.code === option.code) {
                    section = false;
                }

                if (ops.cat_option.code === option.code && ops.cat_option.option_element.code !== element.code) {
                    let auxOp = ops;
                    auxOp.cat_option.option_element = element;
                    tempOption.push(auxOp);
                }

                else {
                    tempOption.push(ops)
                }
            });

            if (section) {
                let sections = this.state.selectedProduct.style.sections;
                sections.forEach(sec => {
                    sec.cat_option.forEach(op => {
                        if (op.code === option.code) {

                            if (element.code === "custom_code") {
                                let tempData = { ...sec };
                                let tempOp = { ...op };
                                tempOp.option_element = element;
                                tempData.cat_option = tempOp;
                                tempOption.push(tempData);
                            }
                            else {
                                op.option_element.forEach(ele => {
                                    if (ele.code === element.code) {
                                        let tempData = { ...sec };
                                        let tempOp = { ...op };
                                        tempOp.option_element = ele;
                                        tempData.cat_option = tempOp;
                                        tempOption.push(tempData);
                                    }
                                })
                            }
                        }

                    });
                });
            }

            this.setState({
                selectedProduct: {
                    ...this.state.selectedProduct,
                    style: {
                        ...this.state.selectedProduct.style,
                        option: tempOption
                    }
                }
            })

            localStorage.setItem(adminProduct._id, JSON.stringify({
                ...this.state.selectedProduct,
                style: {
                    ...this.state.selectedProduct.style,
                    option: tempOption
                }
            }));

            this.setState({ reloadImg: true })
        }

        const nextStyleStep = () => {
            console.log("style");
            selectTab(true, true);
        }

        const backStyleStep = () => {
            selectTab(false, false);
        }

        const backSummaryStep = () => {
            selectTab(true, false);
        }

        const solvePrice = (onlyPrice) => {
            let pais = {};
            let currency = "";
            let factorFabric = 0;
            let precioBase = 0;
            let numM2 = adminProduct.num_m2;
            let priceM2 = 0;
            let fabric = false;
            let rateConversion = 0;

            conversions.forEach(conver => {
                if (conver.code_country === country) {
                    currency = conver.code_currency;
                    rateConversion = conver.exchange_rate;
                }
            });

            adminProduct.countries.forEach(con => {
                if (con.code === country) {
                    pais = con;
                    precioBase = con.base_price;
                    factorFabric = con.factor_fabric;
                }
            });

            priceM2 = selectedProduct.fabric.price_m2;

            let subTotal = (factorFabric * numM2 * priceM2);
            let precioFinal = parseFloat((parseFloat(precioBase) + parseFloat(subTotal)) * parseFloat(rateConversion)).toFixed(2);

            if (onlyPrice) {

                return (precioFinal === "NaN" ? 0.00 : precioFinal);
            }

            return currency + " " + (precioFinal === "NaN" ? "0.00" : precioFinal);

        };

        const addToCart = () => {

            let configOptions = [
                ...selectedProduct.style.option
            ];

            let fabricOPT = {
                code: "Fabric",
                description: "Fabric",
                cat_option: {
                    code: "FabricOP",
                    description: "Fabric",
                    tag_es: "Tela",
                    option_element: selectedProduct.fabric,
                    tag_en: "Fabric",
                    order: "1"
                },
                tag_es: "Fabric",
                tag_en: "Tela",
                order: "1"
            };

            configOptions.push(fabricOPT);

            let cartProduct = {
                id: product.id,
                name: product.title,
                idAdmin: adminProduct._id,
                code: adminProduct.code,
                img: adminProduct.img_default,
                qty: 1,
                price: solvePrice(true),
                configType: selectedProduct.style.level,
                configurations: configOptions,
                country: country
            };

            let cart = JSON.parse(localStorage.cart || []);
            cart.push(cartProduct);
            localStorage.setItem("cart", JSON.stringify(cart))

            toggle();
            selectTab(false, false);

            this.setState({
                selectedProduct: {
                    ...this.state.selectedProduct,
                    fabric: {},
                    stateTab: {
                        fabric: true,
                        style: false,
                        summary: false
                    }
                }
            })


            localStorage.setItem(adminProduct._id, JSON.stringify({
                ...this.state.selectedProduct,
                fabric: {},
                stateTab: {
                    fabric: true,
                    style: false,
                    summary: false
                }
            }));

        }

        const toggle = () => this.setState({ modal: !modal });

         const  loadImage = async (src) => {
            let canvas = document.getElementById("desing");
            let canvasV = canvas.getContext("2d");

            let imgCapas = document.getElementById("imgCapas");
            let imagen = document.createElement("img");
            imagen.src = src;
            imagen.onerror="this.onerror=null; this.src='Default.jpg'; this.style = 'display: none;'";
            imgCapas.append(imagen);

            var img = new Image();
            img.onload = function () {
                canvasV.drawImage(img, 0, 0, 624, 676);
            };
            img.src = src;
            img.height = 676;
        }


        setTimeout(() => {
            if (this.state.reloadImg) {
                //loadImage(imgPrincipal);
                this.setState({ reloadImg: false })
            }
        }, 500);


        setTimeout(() => {
            let imgCapas = document.getElementById("imgCapas");
            imgCapas.innerHTML = '';
            let urlImg = `https://s3.amazonaws.com/admin.stilepersonale.com/blanca_Mesa.png`;
            loadImage(urlImg);

            selectedProduct.style.option.forEach(op => {
                if (adminProduct.section_default === op.cat_option.code) {
                    let url = `${SP_CUSTOM_STYLE}/${adminProduct.code}/${selectedProduct.fabric.code}/${op.code}/${op.cat_option.code}/${op.cat_option.option_element.code}.png`;
                    let elementCode = op.cat_option.option_element.code;

                    setTimeout(() => {
                        if (adminProduct.code === "CAPAF1") {
                            if (op.cat_option.code === 'COPAAJ') {
                                selectedProduct.style.option.forEach(opB => {
                                    if (opB.cat_option.code === 'COPACH') {
                                        let pantCode = opB.cat_option.option_element.code;
                                        url = `${SP_CUSTOM_STYLE}/${adminProduct.code}/${selectedProduct.fabric.code}/${op.code}/${op.cat_option.code}/${pantCode}/${op.cat_option.option_element.code}.png`;
                                    }
                                });
                            }
                        }

                        loadImage(url);
                        if (reloadImg) {
                            doesImageExist(url);
                            this.setState({ reloadImg: false })
                        }



                        setTimeout(() => {

                            selectedProduct.style.option.forEach(opB => {
                                if (opB.cat_option.code === 'COPABE') {
                                    let urlB = `${SP_CUSTOM_STYLE}/${adminProduct.code}/${selectedProduct.fabric.code}/${opB.code}/${opB.cat_option.code}/${elementCode}/${opB.cat_option.option_element.code}.png`;
                                    loadImage(urlB);
                                }
                            });


                            selectedProduct.style.option.forEach(op => {
                                if (op.cat_option.code !== 'COPABE' && op.cat_option.code !== 'COP022') {
                                    let url = `${SP_CUSTOM_STYLE}/${adminProduct.code}/${selectedProduct.fabric.code}/${op.code}/${op.cat_option.code}/${op.cat_option.option_element.code}.png`;
                                    let urlTemp = `${SP_CUSTOM_STYLE}/${adminProduct.code}/${selectedProduct.fabric.code}/${op.code}/${op.cat_option.code}/${op.cat_option.option_element.code}`;
                                    let elementCode = op.cat_option.option_element.code;
                                    let buttonCollar = false;
                                    let butttonCode = '';

                                    if (op.cat_option.code === 'COPAAQ') {
                                        selectedProduct.style.option.forEach(opB => {
                                            if (opB.cat_option.code === 'COPAAL') {
                                                buttonCollar = true;
                                                butttonCode = opB.cat_option.option_element.code;
                                            }
                                        });
                                    }

                                    setTimeout(async () => {

                                        if (adminProduct.code === "CAPAF1" && op.cat_option.code === 'COPAAJ') {
                                            //console.log('final url', url);
                                            //await loadImage(url);
                                        } else {
                                            setTimeout(() => {
                                                loadImage(url);
                                            }, 500);
                                        }
                                        selectedProduct.style.option.forEach(opB => {

                                            if (opB.cat_option.code === 'COPACH') {

                                            }
                                            else if (opB.cat_option.code === 'COPABE') {
                                                let urlB = `${SP_CUSTOM_STYLE}/${adminProduct.code}/${selectedProduct.fabric.code}/${opB.code}/${opB.cat_option.code}/${elementCode}/${opB.cat_option.option_element.code}.png`;

                                                if (buttonCollar) {
                                                    urlB = `${urlTemp}/${butttonCode}/${opB.cat_option.option_element.code}.png`;
                                                }
                                                setTimeout(() => {
                                                    console.log("button:", urlB);
                                                    loadImage(urlB);
                                                }, 500);
                                            }

                                            else if (opB.cat_option.code === 'COP022') {
                                                let urlB = `${SP_CUSTOM_STYLE}/${adminProduct.code}/${selectedProduct.fabric.code}/${opB.code}/${opB.cat_option.code}/${elementCode}/${opB.cat_option.option_element.code}.png`;
                                                console.log(urlB);
                                                setTimeout(() => {
                                                    loadImage(urlB);
                                                }, 500);
                                            }
                                        });
                                    }, 500);
                                }

                            });

                        }, 1500);
                    }, 50);


                }
            });


        }, 100);


        return (<div className="product-mobile">
            <Navbar2 />
            <div style={{ paddingTop: "100px" }}>
                <div className="title" onClick={() => { window.location.href = `${window.location.origin}/${localStorage.typeCategory}` }} style={{ cursor: "pointer" }}>
                    {"< " + translate('product.title')}
                </div>
            </div>
            <div className="container-mobile" style={{ maxWidth: "550px !important" }}>
                <div className="content-mobile">
                    <div style={{ display: "flex", justifyContent: "center", flexDirection: 'column' }}>
                        <img className='principalImg' src={imgPrincipal} width="624" height="auto" style={{ display: imgPrincipalShow ? 'block' : 'none', maxWidth: '624px' }} alt="" />
                          <canvas id="desing" style={{ display: "none" }} width="624" height="676"></canvas>
                        <div id='imgCapas' style={{zIndex: 0, display: imgPrincipalShow ? 'none' : 'block', width: "624px", height: '676px'}}></div>
                    </div>

                    <div className={mobileNavigation ? "product-section-2" : "product-section-2 product-section-2-hide"}>
                        <div className="pasos">
                            <button className={stateTab.fabric ? "section" : "section disabled"} disabled={!stateTab.fabric} onClick={() => selectTab(false, false)}>
                                {translate('productMobile.fabric')}
                            </button>
                            <button className={stateTab.style ? "section" : "section disabled"} onClick={() => selectTab(true, false)}>
                                {translate('productMobile.style')}
                            </button>
                            <button className={stateTab.summary ? "section" : "section disabled"} onClick={() => selectTab(true, true)}>
                                {translate('productMobile.summary')}
                            </button>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div className='expanded-button' style={{
                                width: "20px",
                                background: "#e5e5e5",
                                alignItems: "center",
                                padding: "1px",
                                fontSize: "11px",
                                justifyContent: "center"

                            }}
                                onClick={() => this.setState({ mobileNavigation: !mobileNavigation })}
                            >
                                {!mobileNavigation ?
                                    "◀︎"
                                    :
                                    "▶︎"
                                }
                            </div>
                            <div style={{ width: "100%" }}>
                                {stateTab.fabric && !stateTab.style &&
                                    <div className="fabric">
                                        <FabricComponent
                                            selected={selectedProduct.fabric}
                                            selectedFabric={selectedFabric}
                                            id={adminProduct._id}
                                            elements={fabricElements}
                                        />
                                        <div className="footer-fabric">
                                            <button className={selectedProduct.fabric.id !== undefined ? "next" : "next disabled"} disabled={selectedProduct.fabric.id ? false : true} onClick={() => selectTab(true, false)}>
                                                NEXT
                                            </button>
                                        </div>
                                    </div>
                                }

                                {stateTab.fabric && stateTab.style && !stateTab.summary &&
                                    <div className="style" width="100%">
                                        <Styles
                                            selectedStyle={selectedProduct.style}
                                            changeLevel={changeLevel}
                                            changeStyleLevel={changeStyleLevel}
                                            nextStyleStep={nextStyleStep}
                                            backStyleStep={backStyleStep}
                                            changeSelection={changeSelection}
                                            configurations={adminProduct.configurations}
                                            country={this.props.country}
                                        />
                                    </div>
                                }


                                {stateTab.fabric && stateTab.style && stateTab.summary && <>
                                    <div style={{ display: "flex", flexDirection: "column", padding: "15px", height: "350px" }}>
                                        <div style={{ display: "flex" }}>
                                            <div style={{ width: "100px", fontSize: "12px" }}>Fabric:</div>
                                            <div style={{
                                                display: "flex",
                                                flexWrap: "wrap",
                                                justifyContent: "initial",
                                                fontSize: "12px",
                                                width: "100%"
                                            }}>
                                                <span style={{ background: "#e2e2e2", padding: "3px" }}>
                                                    {
                                                        selectedProduct.fabric.tag_en
                                                    }
                                                </span>

                                            </div>
                                        </div>
                                        <div style={{ borderBottom: "1px solid black", marginTop: "15px", marginBottom: "15px" }}></div>
                                        <div style={{ display: "flex" }}>
                                            <div style={{ width: "100px", fontSize: "12px" }} >Style:</div>
                                            <div style={{
                                                display: "flex",
                                                flexWrap: "wrap",
                                                justifyContent: "initial",
                                                fontSize: "12px",
                                                width: "100%"
                                            }}>
                                                {selectedProduct.style.option &&
                                                    selectedProduct.style.option.map(data =>
                                                        <div
                                                            key={data.cat_option.option_element.id}
                                                            style={{ background: "#e2e2e2", padding: "3px", marginTop: "5px", marginRight: "5px" }}
                                                        >
                                                            {data.cat_option.option_element.tag_en}
                                                        </div>)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="footer-style" style={{ marginBottom: "-12px", marginTop: "10px", display: "flex", justifyContent: "space-between" }}>
                                        <button
                                            onClick={() => backSummaryStep()}
                                            style={{
                                                background: "#011726",
                                                height: "35px",
                                                width: "75px",
                                                color: "white",
                                                fontSize: "9pt",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                fontWeight: "bold",
                                                border: "0",
                                            }}
                                        >
                                            BACK
                                        </button>

                                        <button
                                            onClick={() => addToCart()}
                                            style={{
                                                background: "#011726",
                                                height: "35px",
                                                width: "75px",
                                                color: "white",
                                                fontSize: "9pt",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                fontWeight: "bold",
                                                border: "0",
                                                lineHeight: "1"
                                            }}
                                        >
                                            ADD TO CART
                                        </button>
                                    </div>
                                </>
                                }
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    fontWeight: "bold",
                                    fontSize: "18px",
                                    fontFamily: "monospace",
                                    marginTop: "-30px"
                                }}>

                                    {solvePrice()}

                                </div>
                                {this.props.country === "en_US" &&
                                    <p className="affirm-as-low-as text-center pt-3" data-page-type="product" data-amount={parseInt(solvePrice(true) * 100)}></p>
                                }
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <Footer />
            <Modal isOpen={modal} toggle={toggle} centered={true}>
                <ModalHeader toggle={toggle}>
                    Product Successfully Added
                </ModalHeader>
                <ModalBody>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => { window.location.href = window.location.origin + "/all-products"; }}>Continue Shopping</Button>{' '}
                    <Button color="primary" onClick={() => { window.location.href = window.location.origin + "/cart"; }}>Go to Cart</Button>
                </ModalFooter>
            </Modal>
        </div>)
    }
}

const mapStateToProps = (state, ownProps) => {
    let idProduct = ownProps.match.params.id;

    return {
        product: state.products.list.filter(pro => pro.productType === idProduct),
        idProduct: idProduct,
        country: state.Intl.locale,
        symbol: state.data.symbol
    }

};

export default connect(mapStateToProps)(withTranslate(SingleProduct));
