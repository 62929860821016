import React, { Component } from 'react';
import { withTranslate, IntlActions } from 'react-redux-multilingual';

class StylesSelected extends Component {

    state = {
        saiaWidgetReact: false,
        navHide: false
    }

    constructor(props) {
        super(props);
    }


    render() {
        const { translate, selectedOption, changeSelection, level, findSelectedUninqueElement, unselectedOption, country, OptionsNav } = this.props;
        const { saiaWidgetReact, navHide } = this.state;

        const solveUrlImg = (img) => {
            if (img === undefined || img.trim() === "") {
                return "";
            }

            var prefix = 'https://';
            var prefix2 = 'http://';
            if (img.substr(0, prefix.length) !== prefix && img.substr(0, prefix2.length) !== prefix2) {
                img = prefix + img;
            }
            return img.replace("s3://", "s3.amazonaws.com/");
        };


        const selectedElementSize = ({ id, code, tag_en }) => {
            changeSelection({ id: id, code: code, tag_en: tag_en }, selectedOption)
        }


        if (selectedOption.code === "COPM02" && saiaWidgetReact === false) {
            setTimeout(() => {
                this.setState({ saiaWidgetReact: true });
                document.getElementById("saiaWidgetReact").prepend(document.getElementById("saia-widget-container"));

            }, 500);
        }

        const showTextCountry = (element) => {
            if (country === "en_US") {
                return element.tag_en;
            } else {
                return element.tag_es;
            }
        }

        return (
            <div className="content-styles-selected" style={{ paddingTop: "15px" }}>
                <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                    <img src="/assets/img/flecha-izquierda-gray.svg" className="flechaIzquierda2" onClick={() => unselectedOption()} alt=""/>
                    Back to List
                </div>
                <hr />

                <div style={{ display: "flex" }}>
                    <div style={{ width: navHide ?"20px" :"105px", display: "flex" }}>
                        {
                            !navHide &&
                            <OptionsNav
                                code={selectedOption.code}
                            />
                        }

                        <div style={{
                            height: "310px",
                            width: "20px",
                            background: "#e5e5e5",
                            display: "flex",
                            alignItems: "center",
                            padding: "1px",
                            fontSize: "11px",
                            justifyContent: "center"
                        }}
                            onClick={() => this.setState({ navHide: !navHide })}
                        >
                            { !navHide ? 
                            "◀︎"
                            : 
                            "▶︎"
                            }
                        </div>
                    </div>
                    <div
                        className="contain"
                        style={{ 
                            display: "flex", 
                            flexWrap: "wrap", 
                            justifyContent: "space-between", 
                            background: "#99a2a8", 
                            height: "310px", 
                            overflowY:"auto",
                            padding: "10px", 
                            width: "100%" }}
                    >
                        {
                            (selectedOption.option_element && selectedOption.code != "COPM02") &&
                            selectedOption.option_element.map(
                                ele =>
                                    ele[level] &&
                                    <div
                                        key={ele.id}
                                        style={{
                                            marginRight: "2px",
                                            marginLeft:"2px",
                                            width: !navHide? "105px": "135px",
                                            height: !navHide? "135px": "155px",
                                            background: "white",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            fontSize: "7pt",
                                            textAlign: "center",
                                            display: "flex",
                                            marginBottom: "10px",
                                            alignItems: "center",
                                            border: `1px solid ${findSelectedUninqueElement(ele) == true ? "#20ff20" : "black"}`
                                        }}
                                        onClick={() => changeSelection(ele, selectedOption)}
                                    >
                                        <img src={solveUrlImg(ele.url)} width="100%" alt=""/>
                                        <span style={{ height: "32px", width: "100%" }}>{showTextCountry(ele)}</span>
                                    </div>
                            )
                        }
                    </div>

                </div>
            </div>
        )
    }
}




export default withTranslate(StylesSelected);