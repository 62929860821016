import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { withTranslate } from 'react-redux-multilingual'

class FooterMobile extends Component {

    constructor(props) {
        super(props)

        this.state = {
            urlFacebook: "",
            urlInstagram: ""
        }
    }

    componentDidMount() {

        if (this.props.languaje === 'es_MX') {
            this.setState({
                urlFacebook: "https://www.facebook.com/StilePersonaleMexico",
                urlInstagram: "https://www.instagram.com/stilepersonalemex/"
            })
        }

        else if (this.props.languaje === 'es_PA') {
            this.setState({
                urlFacebook: "https://www.facebook.com/stilepersonalepanama/",
                urlInstagram: "https://www.instagram.com/stilepersonalepanama/"
            })
        }

        else {
            this.setState({
                urlFacebook: "https://www.facebook.com/Stile-Personale-109369217529101/",
                urlInstagram: "https://www.instagram.com/stilepersonalemex/"
            })
        }

    }

    render() {
        const { translate } = this.props;
        let publicUrl = process.env.PUBLIC_URL + '/'
        const { urlFacebook, urlInstagram } = this.state;

        return (
            <footer className={"footer-area footer-style-2"} style={{ padding: "20px" }}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", color: "white" }}>
                    <Link to="/">
                        <img className="img-logo" src={publicUrl + "assets/img/logowhite.svg"} style={{ width: "150px" }} alt="StilePersonale"/>
                    </Link>
                    <div style={{ fontSize: "10pt", marginTop: "15px" }}>
                        {translate('footer.title')}
                    </div>
                    <div style={{ fontSize: "10pt" }}>
                        {translate('footer.subTitle')}
                    </div>
                    <div style={{ marginTop: "15px" }}>
                        <a
                            href={urlFacebook}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ background: "white", padding: "5px", borderRadius: "50px", marginRight: "5px" }}
                        >
                            <i className="icon-facebook" />
                        </a>
                        <a
                            href={urlInstagram}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ background: "white", padding: "5px", borderRadius: "50px", marginRight: "5px" }}
                        >
                            <i className="icon-instagram" />
                        </a>
                    </div>

                    <div style={{
                        marginTop: "15px",
                        width: "100%",
                        paddingLeft: "25px",
                        paddingRight: "25px",
                        display:"flex",
                        justifyContent:"center",
                        flexDirection:"column",
                        alignItems:"center",
                        fontSize:"10pt"
                    }}>
                        <div style={{ borderTop: "1px solid white", width:"100%", textAlign:"center", padding:"6px" }}>
                            <Link to="/products/men">SHOP</Link>
                        </div>
                        <div style={{ borderTop: "1px solid white", width:"100%", textAlign:"center", padding:"6px" }}>
                            <Link to="/policy">OUR POLICY</Link>
                        </div>
                        <div style={{ borderTop: "1px solid white", width:"100%", textAlign:"center", padding:"6px" }}>
                            <Link to="/contact">CONTACT US</Link>
                        </div>

                        <div style={{ borderTop: "1px solid white", width:"100%", textAlign:"center", padding:"6px" }}>
                            3400 Cottage Way Set G2 <br />
                            #421 Sacramento, CA 95825
                        </div>
                    </div>
                </div>

            </footer>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        languaje: state.Intl.locale
    }
}

export default connect(mapStateToProps)(withTranslate(FooterMobile));