import React from 'react';
import Navbar2 from './common/Navbar2';
import Footer from './common/Footer';
import TitlePage from './common/TitlePage';
import { withTranslate } from 'react-redux-multilingual'
import { connect } from 'react-redux';

class ConfirmAffirm extends React.Component {
    state = {
        mensaje: "",
        checkout: "",
        orden: "",
        loading: true
    }

    componentDidMount() {
        const params = new URLSearchParams(window.location.search);

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        let orderDetail = JSON.parse(localStorage.orderDetail);
        let cartDetailAll = JSON.parse(localStorage.cart);

        let cartDetail = cartDetailAll.filter(cart => cart.country === this.props.country);

        var raw = JSON.stringify({
            checkout_token: params.get("checkout_token"),
            order_id: localStorage.orderId,
            clienteId: orderDetail.email,
            orderId: localStorage.orderId,
            orderDetail: orderDetail,
            cart: cartDetail
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${process.env.SP_API_ADMIN_URL}/confirm`, requestOptions)
            .then(response => response.text())
            .then(result => {
                console.log(result);
                this.setState({ loading: false });
            })
            .catch(error => console.log('error', error));
    }

    render() {
        const { loading } = this.state;

        const margin = {
            marginTop: "-6px"
        }

        return <div className="absolute-nav">
            <Navbar2 />
            <TitlePage title={"Confirm Affirm"} />

            <div style={{ marginBottom: "25px", marginTop: "25px", display: "flex", justifyContent: "center" }}>
                {
                    loading &&
                    <div style={{ textAlign: "center" }}>
                        <h2>Processing order</h2>
                        <img src="/assets/img/loading.svg" alt="" />
                        <img src="/assets/img/order_process.svg" style={{ maxWidth: "350px" }} alt="" />
                    </div>

                }

                {
                    !loading &&
                    <div style={{ textAlign: "center" }}>
                        <h2>Order processed successfully</h2>
                        <img src="/assets/img/order_completed.svg" style={{ maxWidth: "350px" }} alt="" />
                    </div>

                }
            </div>

            <div style={margin}>
                <Footer />
            </div>
        </div>
    }

}

const mapStateToProps = (state) => ({
    symbol: state.data.symbol,
    country: state.Intl.locale
})

export default connect(mapStateToProps)(ConfirmAffirm)