import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

class ContactInfo extends Component {

	render() {
		const {faq} = this.props;
		return(
			<div>
			  <div className="contact">
			    <div className="container">
			      <div className="row">
			        <div className="col-md-12">
			          <div className="section-title text-center">
			            <h3>FAQ</h3>
			          </div>
			        </div>
			      </div>
			      <div className="faq">
				    <table>
						<tbody>
						{ 
							faq.map((item,index) =>{
								return(
								<tr key={item.id}>
									<td>
										<h4 className="mr-4">
											{(index + 1) + ')' }
										</h4>
									</td>
									<td >
										<h4>
											<Link to={`/single-blog/${item.id}`}>
												{item.title}
											</Link>
										</h4>
									</td>
								 </tr>
								)
							})
						}
						</tbody>
					</table>
			      </div>
			    </div>
			  </div>
			</div>
		);
    }
}


const mapStateToProps = (state) => {
	  return {
		faq: state.articles.slider
	  }
	};
  
export default connect(mapStateToProps)(ContactInfo);
