import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslate } from 'react-redux-multilingual'

class CollectionSlider extends Component {
  constructor(props) {
    super(props);
  }


  render() {
    let publicUrl = process.env.PUBLIC_URL + '/'
    const { translate } = this.props;

    let imgMen = "";
    let imgWoman = "";

    this.props.sections.forEach(sec => {
      if (sec.code === "MD002") {
        imgWoman = sec.img;
      } 
      if (sec.code === "MD003") {
        imgMen = sec.img;
      }
    });


    return <div className="container collection-style-04 d-flex justify-content-center">
      <div className="row">
        <div className="collection-style-04 col-lg-6 col-md-6 col-sm-6  col-6  margin-top-50 mb-5 p-0">
          <div className="">
            <Link to="/products/men" >
              <img className="image-category" height="auto" src={imgMen} alt="Men" />
            </Link>
          </div>
          <div className="content" style={{ padding: 0 }}>
            <h3 className="title-men" style={{ fontSize: "15pt" }}>{translate('collection.titleMen')}</h3>
            <div className="btn-wrapper">
              <Link to="/products/men" className="btn btn-collection2" dangerouslySetInnerHTML={{ __html: translate('collection.shop') }}></Link>
            </div>
          </div>
        </div>
        <div className="collection-style-04 col-lg-6 col-md-6 col-sm-6  col-6 margin-top-50  mb-5 p-0">
          <div className="">
            <Link to="/products/women">
              <img className="image-category" height="auto" src={imgWoman} alt="Women" />
            </Link>
          </div>
          <div className="content" style={{ padding: 0 }}>
            <h3 style={{ fontSize: "15pt" }}>{translate('collection.titleWomen')}</h3>
            <div className="btn-wrapper">
              <Link to="/products/women" className="btn btn-collection2" dangerouslySetInnerHTML={{ __html: translate('collection.shop') }}></Link>
            </div>
          </div>
        </div>
      </div>
    </div>


  }
}

export default withTranslate(CollectionSlider)