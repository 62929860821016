import React, { Component } from 'react';
import { withTranslate, IntlActions } from 'react-redux-multilingual';
import StyleSelected from "./StyleSelect";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

class Styles extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectOption: false,
            opcionSeleccionada: {},
            modal: false,
            saiaWidgetReact: true,
            customMeasurements: localStorage.getItem('customMeasurements')
        };
    }

    render() {
        const { translate, selectedStyle, changeLevel, changeStyleLevel, changeSelection, nextStyleStep, backStyleStep, country } = this.props;
        const {selectOption, opcionSeleccionada, modal, saiaWidgetReact, customMeasurements  } = this.state;

        const showTextCountry = (element) => {
            console.log(element);
            if (country === "en_US") {
                return element.tag_en;
            } else {
                return element.tag_es;
            }
        }

        const findSelectedUninqueOp = (op) => {
            let text = "";
            selectedStyle.option.forEach(sec => {
                if (sec.cat_option.code === op.code) {
                    text = ` ${showTextCountry(sec.cat_option.option_element)}`;
                }
            });

            return text;
        }

        const findSelectedUninqueElement = (element) => {
            let text = false;
            selectedStyle.option.forEach(sec => {
                if (sec.cat_option.option_element.code === element.code) {
                    text = true
                }
            });

            return text;
        }

        const selectedOption = (op) => {

            if (op.code === "COPM02") {
                toggle();
                if (saiaWidgetReact) {
                    // setTimeout(() => {
                    //     this.setState({ saiaWidgetReact: false })
                    //     document.getElementById("saiaWidgetReact").prepend(document.getElementById("saia-widget-container"));
                    // }, 500);
                }
            } else {
                this.setState({
                    opcionSeleccionada: op,
                    selectOption: true
                })
            }

        }

        const unselectedOption = () => {
            this.setState({
                selectOption: false,
                estiloSeleccionado: {}
            });
        }

        const selectedElementSize = ({ id, code, tag_en }) => {
            localStorage.setItem('customMeasurements', true);
            this.setState({customMeasurements: true})
            window.open("https://mtm-widget.3dlook.me/?key=3016c9c7-343b-4acb-a559-d393daa20e95#/?origin=https://www.stilepersonale.com&returnUrl=https%3A%2F%2Fwww.stilepersonale.com%2Fall-products%2F", '_blank');
            changeSelection({ id: id, code: code, tag_en: tag_en }, opcionSeleccionada)
        }

        const toggle = () => this.setState({ modal: !modal });

        const OptionsNav = (props) => {

            let styleNav = {
                section: {},
                title: {},
                general: {}
            };

            if (props.main) {
                styleNav.section = {
                    borderBottom: "1px solid black",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    height: "44pt",
                    alignItems: "center"
                }

                styleNav.title = {
                    fontSize: "10pt",
                    fontWeight: "bold"
                }

                styleNav.general = {
                    padding: "5px",
                    overflowY: "auto"
                }
            } else {
                styleNav.general = {
                    height: "310px",
                    overflowY: "auto"
                }

                styleNav.section = {
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    padding: "5px",
                    marginTop: "2px",
                    marginBottom: "2px"
                }
                styleNav.title = {
                    fontSize: "8pt",
                    color: "gray"
                }
            }




            return <div className="style-sections" style={{ ...styleNav.general }}>
                {
                    selectedStyle.sections &&
                    selectedStyle.sections.map(sec =>
                        sec.cat_option.map(op =>
                            op[selectedStyle.level] &&
                            <div
                                key={op.code}
                                className={"sections-child"}
                                style={{
                                    ...styleNav.section,
                                    display: `${op.code === "COPM03" ? "none" : "flex"}`,
                                    background: op.code === props.code ? "#99a2a8" : ""
                                }}
                                onClick={() => selectedOption(op)}
                            >
                                <div className="child-title" style={{ ...styleNav.title, color: op.code === props.code ? "white" : "" }}>{showTextCountry(op)} </div>
                                <div className="child-text" style={{ fontSize: "9pt", display: `${props.main ? "" : "none"}` }}>{findSelectedUninqueOp(op)}</div>
                            </div>)
                    )
                }
            </div>
        }
        return (
            <div className="content-styles" style={{ paddingTop: "15px" }}>
                {!selectOption ?
                    <>
                        <table style={{ width: "100%" }}>
                            <tbody>
                                {/*  <tr>
                                    <td style={{ fontSize: "10pt", fontWeight: "bold" }}>Predefined Styles</td>
                                    <td style={{ fontSize: "9pt", padding: "10px" }}>
                                        <select style={{ fontSize: "9pt" }} name="predefinedStyle" className="form-control" defaultValue="custom">
                                            <option value="custom">Custom</option>
                                        </select>
                                    </td>
                                </tr>*/}
                                <tr>
                                    <td style={{ fontSize: "10pt", fontWeight: "bold" }}>Level</td>
                                    <td style={{ fontSize: "9pt", padding: "10px" }}>
                                        <select style={{ fontSize: "9pt" }} name="predefinedStyle" className="form-control" defaultValue={selectedStyle.level} onChange={changeLevel}>
                                            <option value="basic">{country === "en_US"? 'Basic': 'Basico'}</option>
                                            <option value="intermediate">{country === "en_US"? 'Intermediate':'Intermedio'}</option>
                                            <option value="advanced">{country === "en_US"? 'Advanced':'Avanzado'}</option>
                                        </select>
                                    </td>
                                </tr>

                                {this.props.configurations &&
                                <tr>
                                    <td style={{ fontSize: "10pt", fontWeight: "bold" }}>{translate('productMobile.custom')}</td>
                                    <td style={{ fontSize: "9pt", padding: "10px" }}>
                                        <select style={{ fontSize: "9pt" }} name="predefinedStyle" className="form-control" onChange={changeStyleLevel}>
                                            <option value="0">{translate('productMobile.selectCustom')}</option>
                                            {
                                                this.props.configurations.map(item =>
                                                     (item.config.style.level === selectedStyle.level &&
                                                    <option value={item.name}> {country === "en_US"? item.name: item.name_es}</option>)
                                                )

                                            }
                                        </select>
                                    </td>
                                </tr>}
                            </tbody>
                        </table>
                        <OptionsNav main={true} />
                        <div className="footer-style" style={{ marginTop: "10px", display: "flex", justifyContent: "space-between" }}>
                            <button
                                onClick={() => backStyleStep()}
                                style={{
                                    background: "#011726",
                                    height: "31px",
                                    width: "71px",
                                    color: "white",
                                    fontSize: "10pt",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontWeight: "bold",
                                    border: "0",
                                }}
                            >
                                BACK
                            </button>

                            <button
                                onClick={() => { nextStyleStep() }}
                                disabled={customMeasurements ? false : true}
                                style={{
                                    background: `${customMeasurements? "#011726": '#02182666'}`,
                                    height: "31px",
                                    width: "71px",
                                    color: "white",
                                    fontSize: "10pt",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontWeight: "bold",
                                    border: "0",
                                }}
                            >
                                NEXT
                            </button>
                        </div>
                    </>
                    :
                    <div>
                        <StyleSelected
                            selectedOption={opcionSeleccionada}
                            changeSelection={changeSelection}
                            level={selectedStyle.level}
                            unselectedOption={unselectedOption}
                            findSelectedUninqueElement={findSelectedUninqueElement}
                            country={this.props.country}
                            OptionsNav={OptionsNav}
                        />
                        <div className="footer-style" style={{ marginTop: "10px", display: "flex", justifyContent: "flex-end" }}>

                            <button
                                onClick={() => unselectedOption()}
                                style={{
                                    background: "#011726",
                                    height: "31px",
                                    width: "71px",
                                    color: "white",
                                    fontSize: "10pt",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontWeight: "bold",
                                    border: "0",
                                }}
                            >
                                BACK
                            </button>
                        </div>

                    </div>
                }

                 <Modal isOpen={modal} toggle={toggle} centered={true}>
                    <ModalHeader toggle={toggle}>
                        Custom Measurements
                    </ModalHeader>
                    <ModalBody>
                      <button style={{
                            fontfamily: "Roboto Light,sans-serif",
                            fontsize: "14px",
                            letterspacing: "3px",
                            lineheight: "16px",
                            border: "1px solid #343239",
                            minheight: "50px",
                            width: "100%",
                            boxSizing: "border-box",
                            cursor: "pointer",
                            textTransform: "uppercase",
                            transition: "all .25s ease-out",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#011726",
                            color: "#fff",
                            padding: "7px",
                            fontWeight: 600
                        }}
                            onClick={() => selectedElementSize({ id: "custom_id", code: "custom_code", tag_en: "Custom Size" })}
                        >
                            GET MEASURED
                        </button>
                    </ModalBody>
                </Modal>
            </div>

        )
    }
}




export default withTranslate(Styles);
