/**
 * Format data to sync all data
 *
 * @format
 */
import { cloneDeep } from "lodash";

const formatVendor = (item) => {
  try {
    const { vendor, productType, tags} = item;
   
    return {
        vendor,
        productType,
        tags
    };
  } catch (error) {
    console.warn(error);
  }
};

export default formatVendor;
