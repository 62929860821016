import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslate, IntlActions } from 'react-redux-multilingual'
import store from '../../../store';
import { connect } from 'react-redux';
import { logoutUserAndCleanCart } from '../../../reducers/user/operations'

class Navbar2 extends Component {
	constructor(props) {
		super(props);

		const { country } = this.props;
		let productsCart = JSON.parse(localStorage.cart);
		let productsCount = [];

		productsCart.forEach(cart => {
			if (cart.country === country) {
				productsCount.push(cart);
			}
		});

		this.state = {
			cartCount: productsCount.length,
			showMobilMenu: false
		}
	}

	changeLanguage(lang) {
		store.dispatch(IntlActions.setLocale(lang))
	}

	logOut() {
		this.props.logoutUserAndCleanCart();
	}

	render() {
		let publicUrl = process.env.PUBLIC_URL + '/'
		const { translate, user } = this.props;
		const { cartCount, showMobilMenu } = this.state;

		return (
			<div className="stoon-navbar absolute-nav">
				<nav className="navbar navbar-area navbar-expand-lg nav-style-01 header-top">
					<div id="sticky" className="container-fluid nav-container navbar-fixed" >
						<div className="row navbar-sp">
							<div className="col-lg-2 col-4 order-1 align-self-center">
								<div className="logo">
									<Link to={`/`}><img id="logo-stile" src={publicUrl + "assets/img/logo-stile-white.png"} className="logo-stile" alt="stilepersonale" /></Link>
								</div>
							</div>
							<div className="col-lg-7 order-3 order-lg-2">
							<div className={`${window.innerWidth < 900? `${showMobilMenu? "collapse navbar-collapse show":"collapse navbar-collapse"}` :"collapse navbar-collapse"}`} id="shop-menu" >
									<ul className="navbar-nav menu-open color-fixed-navbar " >
										<li>
											<Link to="/" onClick={(e) => (window.location.replace('/'))}>{translate('home')}</Link>
										</li>
										<li >
										<a onClick={() => {
												window.location.href = window.location.origin + "/products/men";
											}
											}>{translate('collection.titleMen')}</a>
										</li>
										<li >
										<a onClick={() => {
												window.location.href = window.location.origin + "/products/women";
											}}>{translate('collection.titleWomen')}</a>
										</li>
										<li><Link to="/about">{translate('about')}</Link></li>
										<li><Link to="/blog">{translate('blog')}</Link></li>
									</ul>
								</div>
							</div>
							<div className="col-lg-3 col-8 justify-content-end d-flex order-2 order-lg-3">
								<div className="nav-right-part">
									<ul className="d-flex justify-content-center">
										<li className="has-dropdown">
											<a className="options" href="#">{translate('language')}</a>
											<ul>
												<li>
													<Link className="btn btn-cart" to="#" onClick={() => this.changeLanguage('en_US')}>
														<img id="usa" src={publicUrl + "assets/img/estados-unidos-de-america.svg"} width="25px" height="25px" alt="USA" />
													</Link>
												</li>
												<li>
													<Link className="btn btn-cart" to="#" onClick={() => this.changeLanguage('es_MX')}>
														<img id="mexico" src={publicUrl + "assets/img/mexico.svg"} width="25px" height="25px" alt="MÉXICO" />
													</Link>
												</li>
												<li>
													<Link className="btn btn-cart" to="#" onClick={() => this.changeLanguage('es_PA')}>
														<img id="panama" src={publicUrl + "assets/img/panama.svg"} width="25px" height="25px" alt="PANAMÁ" />
													</Link>
												</li>
											</ul>
										</li>
										<li className="has-dropdown">
											{(user.accessToken === null) ?
												<Link className="options" to="/login">{translate('login')}<i className="icon-user" /></Link>
												:
												<Link className="options" to="#" onClick={() => this.logOut()}>{translate('logout')}<i className="icon-user" /></Link>
											}
										</li>
										<li className="has-dropdown">
											<Link className="cart" to="/cart"><i className="icon-add-to-cat" /><span className="badge" id="cartCount">{cartCount}</span></Link>
										</li>
									</ul>
									<div id="nameUser" className="name-user-fixed">
										<span>{(user.userInfo === null) ? '' : (user.userInfo.firstName === null) ? '' : translate('welcome') + ' ' + user.userInfo.firstName.toUpperCase()}</span>
									</div>
								</div>
								<div className="responsive-mobile-menu">
									<div className="menu toggle-btn d-block d-lg-none"  onClick={() => { this.setState({showMobilMenu: !showMobilMenu}) }}>
										<div className="icon-left" />
										<div className="icon-right" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</nav>
			</div>
		)
	}
}


const mapStateToProps = (state) => {

	return {
		user: state.user,
		country: state.Intl.locale,
	}
};

export default connect(mapStateToProps, { logoutUserAndCleanCart })(withTranslate(Navbar2));