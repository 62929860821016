import React, { Component } from 'react'
import Navbar2 from '../pages/common/Navbar2';
import Footer from '../pages/common/Footer';
import TitlePage from '../pages/common/TitlePage';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslate } from 'react-redux-multilingual'
import {
	urlCategory,
	urlConversion
} from "../../constants/proxy";

class ListProducts extends Component {
	constructor(props) {
		super(props);
		const { typeCategory } = this.props;
		let filter = "";
		let filterType = []
		let singleModule = false;
		localStorage.setItem("typeCategory", "all-products");
		if (typeCategory) {
			filter = (typeCategory === 'women') ? 'CGEWWW' : typeCategory === 'men' ? 'CGEMMM' : 'ALL';
			filterType.push(filter);
			localStorage.setItem("typeCategory", `products/${typeCategory}`);
			singleModule = true;
		}

		this.state = {
			typeCategory: filter,
			adminProducts: JSON.parse(localStorage.products),
			conversions: [],
			codesCategories: [],
			categories: [],
			categoriesAll: [],
			products: this.props.products.list,
			productosFiltrados: [],
			productsAdmin: JSON.parse(localStorage.products),
			filterType: filterType,
			filtersCategories: [],
			singleModule: singleModule
		}
	}

	componentDidMount() {

		fetch(urlConversion)
			.then(response => response.json())
			.then(data => {
				this.setState({ conversions: data.data })
				localStorage.setItem('conversion', JSON.stringify(data.data));
			});

		let codesCategories = [];
		let categories = [];
		fetch(urlCategory)
			.then(response => response.json())
			.then(data => {

				data.data.forEach(item => {
					codesCategories.push(
						{
							'code': item.code,
							'type': item.description,
							'visible': false,
							'gender': item.gender.tag_en
						})
				});

				codesCategories.forEach(item => {
					if (!categories.find(f => f.type === item.type)) {
						categories.push(item);
					}
				});

				this.setState({
					categories: categories,
					codesCategories: codesCategories,
					categoriesAll: data.data
				});
			}
			);


		window.scroll(0, 0);
	}

	filterProducts = (products, filterType) => {
		let productosFiltrados = [];
		for (const element of products) {
			for (const filter of filterType) {
				if (element.productType === filter) {
					productosFiltrados.push(element);
				}
			}
		}

		return productosFiltrados;
	}

	filterProductsCategories = (products, filtersCategories) => {
		let productosFiltrados = [];
		for (const element of products) {
			for (const cat of filtersCategories) {
				if (element.vendor === cat.code) {
					productosFiltrados.push(element);
				}
			}
		}

		return productosFiltrados;
	}

	onChangeType = (event) => {
		let filterType = this.state.filterType;

		if (event.target.checked) {
			filterType.push(event.target.value)
		} else {
			let index = filterType.indexOf(event.target.value);
			filterType.splice(index, 1);
		}
		if (event.target.type === "select-one") {
			filterType = [event.target.value];
			if (event.target.value === "all") {
				filterType = [];
			}
		}

		this.setState({
			filterType
		});

	}

	onCheckedChange = (event) => {
		let filtersCategories = this.state.filtersCategories;
		if (event.target.checked) {
			filtersCategories.push(event.target.value)
		} else {
			let index = filtersCategories.indexOf(event.target.value);
			filtersCategories.splice(index, 1);
		}

		if (event.target.type === "select-one") {
			filtersCategories = [event.target.value];
			if (event.target.value === "all") {
				filtersCategories = [];
			}
		}

		this.setState({
			filtersCategories
		});
	}

	render() {
		const { translate, country, products } = this.props
		const { categories, categoriesAll, filterType, filtersCategories, adminProducts, singleModule, typeCategory } = this.state;

		const filterAvalibleProd = (productsList) => {
			let list = [];
			productsList.forEach(element => {
				adminProducts.forEach(pr => {
					if (pr.code === element.productType) {
						pr.countries.forEach(ct => {
							if (ct.code === country && ct.avalible === true) {
								list.push(element);
							}
						});
					}
				});
			});
			return list;
		}

		const filterAvalible = (categories) => {
			let list = [];

			categories.forEach(element => {
				element.countries.forEach(ct => {
					if (ct.code === country && ct.avaible === true) {
						list.push({
							...element,
							products: filterAvalibleProd(products.list.filter(x => x.vendor === element.code))
						});
					}
				});
			});

			if (filterType.length > 0) {
				let listTemp = [];
				list.forEach(element => {
					filterType.forEach(typeF => {
						if (typeF === element.gender.code) {
							listTemp.push(element);
						}
					});
				});
				list = listTemp;
			}

			if (filtersCategories.length > 0) {
				let listTemp = [];
				list.forEach(element => {
					filtersCategories.forEach(cat => {
						if (cat === element.description) {
							listTemp.push(element);
						}
					});
				});
				list = listTemp;
			}

			console.log(list);
			return list;
		}

		return (
			<div>
				<Navbar2 />
				<TitlePage title={translate('product.shopNow')} />
				<div className="collection-area mt-1">
					<div className="container">
						<div className="row ">
							{!singleModule && <div className="col-lg-3 col-md-3 col-sm-12 d-none d-md-block d-lg-block col-12 filters mt-4 mb-5 pb-5">
								<h5 className="title">{translate('filters.title')}</h5>
								<hr />
								<h5 className="title">Gender</h5>
								<div>
									<input id="male" type="checkbox" value="CGEMMM" onClick={this.onChangeType} />
									<label>Male</label>
									<br />
									<input id="women" type="checkbox" value="CGEWWW" onClick={this.onChangeType} />
									<label>Women</label>
								</div>
								<hr />
								<h6 className="title">{translate('filters.categories')}</h6>
								<ul className="list-checks mb-4">
									{
										categories.map((cat) =>
											<li key={`index-${cat.type}`}>
												<input id={cat.code} type="checkbox" name={cat.type} value={cat.type} onClick={this.onCheckedChange} />
												<label>{cat.type}</label>
											</li>
										)
									}
								</ul>
							</div>}
							{!singleModule && <div className="col-12 d-block d-sm-none text-center">
								<h5 className="title">{translate('filters.title')}</h5>
								<div className="w-100 d-flex">
									<div className="col-6 text-left">
										<div className="form-group">
											<label htmlFor="genderSelect">Gender</label>
											<select className="form-control" id="genderSelect" onChange={this.onChangeType}>
												<option value="all">All</option>
												<option value="CGEMMM">Male</option>
												<option value="CGEWWW">Women</option>
											</select>
										</div>
									</div>

									<div className="col-6 text-left">
										<div className="form-group">
											<label htmlFor="catSelect">Categories</label>
											<select className="form-control" id="catSelect" onChange={this.onCheckedChange}>
												<option value="all">All</option>
												{
													categories.map((cat) =>
														<option value={cat.type} key={`cat-${cat.type}`}>{cat.type} </option>
													)
												}
											</select>
										</div>
									</div>
								</div>

							</div>}
							<div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
								<div className="tab-content">
									<div className="tab-pane fade in show active row" id="one">
										{ (typeCategory === 'CGEMMM' || typeCategory === undefined) && <div style={{ paddingLeft: "5px" }}> <strong>MEN</strong> </div>}
										<div className="row justify-center-mobil p-3">

											{
												filterAvalible(categoriesAll).map((cat, index) => {
													return cat.products.map(prod =>
														<div
															key={prod.code}
															className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4 p-0 mb-0"
															style={{ display: `${cat.gender.code == "CGEMMM" ? "Block" : "None"}` }}
														>
															<div className="product-style-03 margin-top-40 p-1">
																<div className="thumb">
																	<Link to={`/product/${prod.productType}`}> <img src={prod.defaultImage} alt="" /></Link>
																</div>
																<div className="content text-center d-none">
																	<h6 className="title stone-go-top"><Link to="/product-details">{cat.description}</Link></h6>
																	<div className="content-price d-flex align-self-center justify-content-center">
																	</div>
																</div>
															</div>
														</div>)
												})
											}
										</div>
										{(typeCategory === 'CGEWWW' || typeCategory === undefined) && <div style={{ paddingLeft: "5px" }}> <strong> WOMEN </strong></div>}
										<div className="row justify-center-mobil p-3">

											{
												filterAvalible(categoriesAll).map((cat) => {
													return cat.products.map(prod => <div
														key={prod.code}
														className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4 p-0 mb-0"
														style={{ display: `${cat.gender.code == "CGEWWW" ? "Block" : "None"}` }}>
														<div className="product-style-03 margin-top-40 p-1">
															<div className="thumb">
																<Link to={`/product/${prod.productType}`}> <img src={prod.defaultImage} alt="" /></Link>
															</div>
															<div className="content text-center d-none">
																<h6 className="title stone-go-top"><Link to="/product-details">{cat.description}</Link></h6>
																<div className="content-price d-flex align-self-center justify-content-center">
																</div>
															</div>
														</div>
													</div>)
												})
											}
										</div>
									</div>
								</div>
								<hr />
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	let type = ownProps.match.params.id;
	return {
		products: state.products,
		country: state.Intl.locale,
		typeCategory: type
	}
};

export default connect(mapStateToProps)(withTranslate(ListProducts));
