import React, {
    Component
} from 'react';
import ReactDOM from 'react-dom';
import {
    BrowserRouter as Router,
    Route,
    Switch
} from "react-router-dom";
import Home from './components/pages/Home';
import About from './components/pages/About';

import Policy from './components/pages/Policy';
import Blog from './components/pages/Blog';
//import ShopifyProducts from './components/pages/ShopifyProducts';
import ListProducts from './components/Products';
import Cart from './components/pages/Cart';
import SingleBlog from './components/pages/SingleBlog';
import TypeProducts from './components/pages/TypesProducts';
import Checkout from './components/pages/Checkout';
import Login from './components/pages/Login';
import Register from './components/pages/Register';
import Contact from './components/pages/Contact';
import ViewOrder from './components/pages/ViewOrder';
import AboutOurClothes from './components/pages/AboutOurClothes';
import ProductAdmin from './components/ProductAdmin';
import ProductAdminOn from './components/ProductAdmin/onload';
import ProductMobile from './components/Product';
import ProductOnload from './components/Product/onload';
import MxPromo from './components/pages/MxPromo';
import UsPromo from './components/pages/UsPromo';
import test from './components/pages/test';
import ConfirmAffirm from './components/pages/ConfirmAffirm';
import {
    urlProducts,
} from "./constants/proxy";

import {
    Provider
} from 'react-redux';
import store from './store';
import translations from './constants/translations';
import {
    fetchAllProducts
} from './reducers/productos/operations';

import {
    IntlProvider,
    IntlActions
} from 'react-redux-multilingual';
import 'bootstrap/dist/css/bootstrap.min.css';


class Root extends Component {

    render() {

        console.log(process.env.SP_PROXY_ADMIN_URL);
        store.dispatch(fetchAllProducts(249));
        store.dispatch(IntlActions.setLocale("en_US"));

        if (localStorage.cart === undefined) {
            localStorage.setItem('cart', JSON.stringify([]));
        }

        //Consulta para obtener los productos
        fetch(urlProducts)
            .then(response => response.json())
            .then(data => {
                localStorage.setItem('products', JSON.stringify(data.data));
            });

        let mxDomain = ".mx";
        let paDomain = ".pa";


        if (window.location.host.includes(mxDomain)) {
            store.dispatch(IntlActions.setLocale("es_MX"));
        } else if (window.location.host.includes(paDomain)) {
            store.dispatch(IntlActions.setLocale("es_PA"));
        }

        return (<Provider store={store} >
            <IntlProvider translations={translations} >
                <Router >
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route path="/confirm-affirm" component={ConfirmAffirm} />
                        <Route path="/us-promo" component={UsPromo} />
                        <Route path="/mx-promo" component={MxPromo} />
                        <Route path="/about" component={About} />
                        <Route path="/policy" component={Policy} />
                        <Route path="/custom-product/:id" component={ProductAdminOn} />
                        <Route path="/custom-admin-product/:id" component={ProductAdmin} />
                        <Route path="/blog" component={Blog} />
                        <Route path="/products/:id" component={ListProducts} />
                        <Route path="/product/:id" component={ProductOnload} />
                        <Route path="/product-single/:id" component={ProductMobile} />
                        <Route path="/checkout" component={Checkout} />
                        <Route path="/contact" component={Contact} />
                        <Route path="/typeProducts/:id" component={TypeProducts} />
                        <Route path="/all-products" component={ListProducts} />
                        <Route path="/login" component={Login} />
                        <Route path="/register" component={Register} />
                        <Route path="/cart" component={Cart} />
                        <Route path="/single-blog/:id" component={SingleBlog} />
                        <Route path="/view-order/:id" component={ViewOrder} />
                        <Route path="/about-our-clothes" component={AboutOurClothes} />
                        <Route path="/product-mobile/:id" component={ProductOnload} />
                        <Route path="/test" component={test} />
                    </Switch >
                </Router>
            </IntlProvider>
        </Provider>
        )
    }
}

export default Root;

ReactDOM.render(< Root />, document.getElementById('root'));