import React, { Component } from 'react';
import Navbar from './common/Navbar2';
import Footer from './common/Footer';
import TitlePage from './common/TitlePage';
import { connect } from 'react-redux';

class ViewOrder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            order: {}
        }
    }

    componentDidMount() {
        let { id } = this.props;
        fetch(`https://proxy-aws.stilepersonale.com/v1/sales/${id}`)
            .then(response => response.json())
            .then(data => {
                console.log(data);
                this.setState({
                    order: { ...data.data }
                });
            });
    }



    render() {
        let { order } = this.state; 

        const solveUrlImg = (opt) => {
            let img = opt.url;
            
            if (img == undefined) {
                img = opt.url_image;
            }


            if (img === undefined || img.trim() === "") {
                return "";
            }

            var prefix = 'https://';
            var prefix2 = 'http://';
            if (img.substr(0, prefix.length) !== prefix && img.substr(0, prefix2.length) !== prefix2) {
                img = prefix + img;
            }
            return img.replace("s3://", "s3.amazonaws.com/");
        };

        return (
            <div>
                <Navbar />
                <TitlePage title="Order" />
                <div className="container mt-4 mb-5 orders">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        {order.cart && order.cart.map((item, index) => {
                            return (
                                <li className="nav-item">
                                    <a className="nav-link " id={item.idAdmin} data-toggle="tab" href={`#${index}`}
                                        role="tab" aria-controls="home" aria-selected="true">{"Product " + (index + 1)}</a>
                                </li>
                            );
                        })
                        }
                    </ul>
                    <div className="tab-content">
                        {order.cart && order.cart.map((cont, index) => {
                            return (
                                <div key={index} className={(index === 0) ? 'tab-pane fade show mt-4 active': 'tab-pane fade show mt-4' } id={index} role="tabpanel" aria-labelledby="home-tab">
                                    <div className="row">
                                        <div className="col-lg-4 col-sm-12 col-12 center">
                                            <span>{cont.price}</span>
                                            <span>{cont.configType}</span>
                                            <img className="img-product" src={cont.img} alt="" />
                                        </div>
                                        <div className="col-lg-8 col-sm-12 col-12">
                                            <div className="col-lg-12 col-sm-12 col-12 row">
                                            { 
                                          cont.configurations.map((conf, index) => {
                                            return (
                                                <div key={conf.cat_option.option_element.code} class="card border-dark col-lg-2 col-md-4 col-sm-3 col-3 m-2 padding-card height-card">
                                                    <div class="card-header text-center title-footer">{conf.tag_en}</div>
                                                    <div class="card-body center"> 
                                                        {console.log(conf.cat_option.option_element)}
                                                        <img className="zoom" src={solveUrlImg(conf.cat_option.option_element)} alt=""/>
                                                    </div>
                                                    <div class="card-footer bg-transparent border-dark">
                                                      <h5 class="text-center title-footer">{(conf.tag_en === 'Fabric') ? conf.cat_option.desc_group : conf.cat_option.tag_en}</h5>
                                                    </div>
                                                </div>
                                            );
                                          })
                                        }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })

                        }
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => {
    let id = ownProps.match.params.id;
    return {
        id: id
    }
};

export default connect(mapStateToProps)(ViewOrder);