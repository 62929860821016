import React from 'react';
import Navbar2 from './common/Navbar2';
import Footer from './common/Footer';
import TitlePage from './common/TitlePage';
import { withTranslate } from 'react-redux-multilingual'

class MxPromo extends React.Component {

    render() {

        const margin = {
            marginTop: "-6px"
        }

        const { translate } = this.props;

        return <div className="absolute-nav">
            <Navbar2 />
            <TitlePage title={"Promocion"} />

            <div style={{ padding: "0", display: "flex", justifyContent: "center", color: "#203341", fontSize: "18px", fontWeight: 600, flexDirection: "column", alignItems: "center" }}>
                 <img src="/assets/img/mx-promo.jpeg" width="100%" alt=""/>
            </div>

            <div style={margin}>
                <Footer />
            </div>
        </div>
    }

}

export default withTranslate(MxPromo)