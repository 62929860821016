import React, { Component } from 'react';
import { connect } from "react-redux";
import { withTranslate } from 'react-redux-multilingual'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from '../Stripe/PaymentForm';
import PaymentMXForm from '../StripeMX/PaymentForm';
import { urlNewSale, urlNewOrder, urlGiftUp, headerGiftUp, headerPA, urlPanama } from '../../constants/proxy';
import { stripePublicKey, stripeMXPublicKey, PAcclw, affirmPKey } from '../../constants/managementKeys';

class Checkout extends Component {
	constructor(props) {
		super(props);

		const { userInfo, pais } = this.props;


		let dataDefaultData = {
			address1: "",
			address2: "",
			city: "",
			company: "",
			country: "",
			firstName: "",
			lastName: "",
			phone: "",
			province: "",
			zip: "",
			address1Pay: "",
			address2Pay: "",
			cityPay: "",
			companyPay: "",
			countryPay: "",
			firstNamePay: "",
			lastNamePay: "",
			phonePay: "",
			provincePay: "",
			zipPay: "",
			sameShippping: true,
			cardNumber: "",
			expMonth: "",
			expYear: "",
			cardCvv: ""
		};

		let dataDefault = {};

		let email = "";
		if (userInfo !== null) {
			const { defaultAddress } = userInfo;
			email = userInfo.email;
			dataDefault = { ...defaultAddress, ...dataDefaultData };
		}
		let paiss = (pais === "en_US" ? "United States" : (pais === "es_MX" ? "Mexico" : "Panama"));
		this.state = {
			focused: "number",
			index: 0,
			email: email,
			...dataDefault,
			country: paiss,
			countryPay: paiss,
			paymentProcess: false,
			tabActive: 2
		};

		localStorage.setItem("orderDetail", JSON.stringify({
			focused: "number",
			index: 0,
			email: email,
			...dataDefault,
			country: paiss
		}));

		this.handleChange = this.handleChange.bind(this);
		this.enablePay = this.enablePay.bind(this);
		this.handleChangePay = this.handleChangePay.bind(this);
	}

	handleChange(e) {
		const { sameShippping } = this.state;
		this.setState({ [e.target.name]: e.target.value });

		if (sameShippping === true) {
			this.setState({ [e.target.name + "Pay"]: e.target.value });
		}

		setTimeout(() => {
			localStorage.setItem("orderDetail", JSON.stringify(this.state));
		}, 500);
	}

	handleChangePay(e) {

		this.setState({ [e.target.name + "Pay"]: e.target.value });

		setTimeout(() => {
			localStorage.setItem("orderDetail", JSON.stringify(this.state));
		}, 500);
	}

	enablePay(pay) {
		setTimeout(() => {
			try {
				document.getElementById("button-payment").disabled = pay;
			} catch (ex) {

			}

		}, 1000);
	}

	render() {
		const { translate, pais } = this.props;
		const {
			address1,
			address2,
			city,
			country,
			firstName,
			lastName,
			phone,
			province,
			zip,
			address1Pay,
			address2Pay,
			cityPay,
			countryPay,
			firstNamePay,
			lastNamePay,
			phonePay,
			provincePay,
			zipPay,
			email,
			sameShippping,
			cardNumber,
			expMonth,
			expYear,
			cardCvv,
			paymentProcess,
			tabActive
		} = this.state;

		const changePaymentAddress = (e) => {
			if (e.target.checked) {
				this.setState({
					sameShippping: e.target.checked,
					address1Pay: address1,
					address2Pay: address2,
					cityPay: city,
					countryPay: country,
					firstNamePay: firstName,
					lastNamePay: lastName,
					phonePay: phone,
					provincePay: province,
					zipPay: zip
				});
			}
			else {
				this.setState({
					sameShippping: e.target.checked,
					address1Pay: "",
					address2Pay: "",
					cityPay: "",
					countryPay: "",
					firstNamePay: "",
					lastNamePay: "",
					phonePay: "",
					provincePay: "",
					zipPay: "",
				});
			}
			setTimeout(() => {
				localStorage.setItem("orderDetail", JSON.stringify(this.state));
			}, 500);
		}

		const stripePromise = loadStripe(stripePublicKey);
		const stripeMXPromise = loadStripe(stripeMXPublicKey);

		if (firstName === "" || lastName === "" || address1 === "" || country === "" || province === "" || zip === "" || email === "" || phone === "" || firstNamePay === "" || lastNamePay === "" || address1Pay === "" || countryPay === "" || provincePay === "" || zipPay === "" || phonePay === "" || paymentProcess === true
		) {
			this.enablePay(true)
		} else {
			this.enablePay(false)
		}

		if (this.props.country === "es_PA") {
			if (cardNumber === "" || cardCvv === "" || expMonth === "" || expYear === "" || paymentProcess === true) {
				this.enablePay(true)
			} else {
				this.enablePay(false)
			}
		}

		const giftCardCheck = () => {
			let giftCardCode = (localStorage.giftCardCode !== undefined ? JSON.parse(localStorage.giftCardCode) : null);
			let cartDetail = JSON.parse(localStorage.cart);

			let resultado = false;

			if (giftCardCode !== null) {
				let amount = 0;
				cartDetail.forEach(item => {
					amount += (item.qty * item.price);
				});

				let giftValue = giftCardCode.remainingValue;

				if (parseFloat(giftValue) >= parseFloat(amount)) {
					resultado = true;
				}
			}

			return resultado;
		};

		const uuidv4 = () => {
			return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
				var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
				return v.toString(16);
			});
		}

		// const confirmPayMX = () => {

		// 	let cartDetailAll = JSON.parse(localStorage.cart);
		// 	let cartDetail = cartDetailAll.filter(cart => cart.country === pais);
		// 	let conversion = JSON.parse(localStorage.conversion);

		// 	let amount = 0;
		// 	cartDetail.forEach(item => {
		// 		amount += (item.qty * item.price);
		// 	});

		// 	let converAmount = 1;
		// 	conversion.forEach(conver => {
		// 		if (conver.code_country === pais) {
		// 			converAmount = parseFloat(conver.exchange_rate);
		// 		}
		// 	});

		// 	let orderDetail = JSON.parse(localStorage.orderDetail);
		// 	let orderIdSend = localStorage.orderId || "";


		// 	var raw = JSON.stringify({
		// 		"affiliation_bbva": "",
		// 		"amount": amount,
		// 		"description": "Pago desde StilePersonale",
		// 		"currency": "MXN",
		// 		"order_id": uuidv4(),
		// 		"customer": {
		// 			"name": orderDetail.firstNamePay,
		// 			"last_name": orderDetail.lastNamePay,
		// 			"email": orderDetail.email,
		// 			"phone_number": orderDetail.phonePay
		// 		},
		// 		"use_3d_secure": false,
		// 		"redirect_url": "urlBBVARedirect"
		// 	});

		// 	var requestOptions = {
		// 		method: 'POST',
		// 		headers: "headerBBVA",
		// 		body: raw,
		// 		redirect: 'follow'
		// 	};

		// 	try {
		// 		fetch("urlBBVA" + "BBVAAMerchantId" + "/charges", requestOptions)
		// 			.then(response => response.json())
		// 			.then(result => {
		// 				localStorage.setItem("orderMX", JSON.stringify(result))
		// 				let lineItems = [];

		// 				cartDetail.forEach(item => {
		// 					lineItems.push({
		// 						"originalUnitPrice": (item.price / converAmount),
		// 						"quantity": item.qty,
		// 						"taxable": true,
		// 						"title": item.name
		// 					});
		// 				});

		// 				var rawOrder = JSON.stringify({
		// 					"email": orderDetail.email,
		// 					"lineItems": lineItems,
		// 					"billingAddress": {
		// 						"address1": orderDetail.address1Pay,
		// 						"address2": orderDetail.address2Pay,
		// 						"city": orderDetail.cityPay,
		// 						"firstName": orderDetail.firstNamePay,
		// 						"lastName": orderDetail.lastNamePay,
		// 						"zip": orderDetail.zipPay,
		// 						"phone": orderDetail.phonePay,
		// 						"province": orderDetail.provincePay,
		// 						"country": orderDetail.countryPay
		// 					},
		// 					"shippingAddress": {
		// 						"address1": orderDetail.address1,
		// 						"address2": orderDetail.address2,
		// 						"city": orderDetail.city,
		// 						"firstName": orderDetail.firstName,
		// 						"lastName": orderDetail.lastName,
		// 						"zip": orderDetail.zip,
		// 						"phone": orderDetail.phone,
		// 						"province": orderDetail.province,
		// 						"country": orderDetail.country
		// 					}
		// 				});

		// 				var myHeaders = new Headers();
		// 				myHeaders.append("Content-Type", "application/json");

		// 				var requestOptions = {
		// 					method: 'POST',
		// 					headers: myHeaders,
		// 					body: rawOrder
		// 				};

		// 				fetch(urlNewOrder, requestOptions)
		// 					.then(response => response.json())
		// 					.then(result => {
		// 						orderIdSend = result.data.draftOrderCreate.draftOrder.legacyResourceId;
		// 						localStorage.setItem('orderId', result.data.draftOrderCreate.draftOrder.legacyResourceId);
		// 						setTimeout(() => {
		// 							window.close();
		// 						}, 2000);
		// 					})
		// 					.catch(error => console.log('error', error));

		// 				window.open(result.payment_method.url, "_blank");


		// 			})
		// 			.catch(error => console.log('error', error));
		// 	} catch (ex) {
		// 		console.log(ex);
		// 	}
		// }


		const confirmPayGiftCard = () => {

			let giftCardCode = (localStorage.giftCardCode !== undefined ? JSON.parse(localStorage.giftCardCode) : null);

			fetch(urlGiftUp + giftCardCode.code,
				{
					method: 'GET',
					headers: headerGiftUp
				})
				.then(response => response.text())
				.then(result => {

					let giftCardCodeJson = JSON.parse(result)
					let giftValue = giftCardCodeJson.remainingValue;
					let cartDetailAll = JSON.parse(localStorage.cart);
					let cartDetail = cartDetailAll.filter(cart => cart.country === pais);

					let amount = 0;
					cartDetail.forEach(item => {
						amount += (item.qty * item.price);
					});

					if (parseFloat(giftValue) >= parseFloat(amount)) {

						var myHeaders = new Headers();
						myHeaders.append("Content-Type", "application/json");

						let orderDetail = JSON.parse(localStorage.orderDetail);
						let orderIdSend = localStorage.orderId || "";

						if (orderDetail.firstName === "" || orderDetail.lastName === "" || orderDetail.address1 === "" || orderDetail.country === "" || orderDetail.province === ""
							|| orderDetail.zip === "" || orderDetail.email === "" || orderDetail.phone === ""
						) {
							alert("Fill in the required fields")
						}
						else {
							if (localStorage.orderId === undefined || localStorage.orderId === null) {
								let lineItems = [];

								cartDetail.forEach(item => {
									lineItems.push({
										"originalUnitPrice": item.price,
										"quantity": item.qty,
										"taxable": true,
										"title": item.name
									});
								});

								var rawOrder = JSON.stringify({
									"email": orderDetail.email,
									"lineItems": lineItems,
									"billingAddress": {
										"address1": orderDetail.address1Pay,
										"address2": orderDetail.address2Pay,
										"city": orderDetail.cityPay,
										"firstName": orderDetail.firstNamePay,
										"lastName": orderDetail.lastNamePay,
										"zip": orderDetail.zipPay,
										"phone": orderDetail.phonePay,
										"province": orderDetail.provincePay,
										"country": orderDetail.countryPay
									},
									"shippingAddress": {
										"address1": orderDetail.address1,
										"address2": orderDetail.address2,
										"city": orderDetail.city,
										"firstName": orderDetail.firstName,
										"lastName": orderDetail.lastName,
										"zip": orderDetail.zip,
										"phone": orderDetail.phone,
										"province": orderDetail.province,
										"country": orderDetail.country
									}
								});

								var requestOptions = {
									method: 'POST',
									headers: myHeaders,
									body: rawOrder
								};


								fetch(urlNewOrder, requestOptions)
									.then(response => response.json())
									.then(result => {
										orderIdSend = result.data.draftOrderCreate.draftOrder.legacyResourceId;
										localStorage.setItem('orderId', result.data.draftOrderCreate.draftOrder.legacyResourceId);
									})
									.catch(error => console.log('error', error));
							}
						}

						let methodGiftCard = (parseFloat(giftValue) > parseFloat(amount)) ? "redeem" : "redeem-in-full";
						let bodySend = JSON.stringify({});

						if (parseFloat(giftValue) > parseFloat(amount)) {
							bodySend = JSON.stringify({
								"amount": parseFloat(amount),
								"units": null,
								"reason": "Pay order"
							});
						} else {
							bodySend = JSON.stringify({ "reason": "Pay order" });
						}

						fetch(urlGiftUp + giftCardCodeJson.code + "/" + methodGiftCard,
							{
								method: 'POST',
								headers: headerGiftUp,
								body: bodySend,
								redirect: 'follow'
							}
						)
							.then(response => response.text())
							.then(result => {

								var requestOptions = {
									method: 'POST',
									headers: myHeaders,
									redirect: 'follow',
									body: JSON.stringify({
										clienteId: orderDetail.email,
										orderId: orderIdSend,
										orderDetail: orderDetail,
										cart: cartDetail
									})
								};

								fetch(urlNewSale, requestOptions)
									.then(response => response.json())
									.then(result => {
										alert("Thank you for your purchase..!");
										localStorage.removeItem("cart");
										localStorage.removeItem("giftCardCode");
										localStorage.removeItem("orderDetail");
										localStorage.removeItem("orderId");
										localStorage.removeItem("orderMX");
										window.location.href = window.location.origin;
									})
									.catch(error => console.log('error', error));
							})
							.catch(error => console.log('error', error));
					} else {
						alert("The coupon entered is less than the current purchase..!")
					}
				})
				.catch(error => console.log(error));

		};

		const confirPayAffirm = () => {

			var myHeaders = new Headers();
			myHeaders.append("Content-Type", "application/json");

			let orderDetail = JSON.parse(localStorage.orderDetail);
			let orderIdSend = localStorage.orderId || "";

			let cartDetailAll = JSON.parse(localStorage.cart);
			let cartDetail = cartDetailAll.filter(cart => cart.country === pais);

			if (orderDetail.firstName === "" || orderDetail.lastName === "" || orderDetail.address1 === "" || orderDetail.country === "" || orderDetail.province === ""
				|| orderDetail.zip === "" || orderDetail.email === "" || orderDetail.phone === ""
			) {
				alert("Fill in the required fields")
			}
			else {
				let lineItems = [];

				cartDetail.forEach(item => {
					lineItems.push({
						"originalUnitPrice": item.price,
						"quantity": item.qty,
						"taxable": true,
						"title": item.name
					});
				});

				var rawOrder = JSON.stringify({
					"email": orderDetail.email,
					"lineItems": lineItems,
					"billingAddress": {
						"address1": orderDetail.address1Pay,
						"address2": orderDetail.address2Pay,
						"city": orderDetail.cityPay,
						"firstName": orderDetail.firstNamePay,
						"lastName": orderDetail.lastNamePay,
						"zip": orderDetail.zipPay,
						"phone": orderDetail.phonePay,
						"province": orderDetail.provincePay,
						"country": orderDetail.countryPay
					},
					"shippingAddress": {
						"address1": orderDetail.address1,
						"address2": orderDetail.address2,
						"city": orderDetail.city,
						"firstName": orderDetail.firstName,
						"lastName": orderDetail.lastName,
						"zip": orderDetail.zip,
						"phone": orderDetail.phone,
						"province": orderDetail.province,
						"country": orderDetail.country
					}
				});

				var requestOptions = {
					method: 'POST',
					headers: myHeaders,
					body: rawOrder
				};


				fetch(urlNewOrder, requestOptions)
					.then(response => response.json())
					.then(result => {
						orderIdSend = result.data.draftOrderCreate.draftOrder.legacyResourceId;
						localStorage.setItem('orderId', result.data.draftOrderCreate.draftOrder.legacyResourceId);
					})
					.catch(error => console.log('error', error));



				let lineItemsAffirm = [];
				let amoutTotal = 0;
				cartDetail.forEach(item => {
					lineItemsAffirm.push({
						"display_name": "Awesome Pants",
						"sku": item.name,
						"unit_price": (item.price * 100),
						"qty": item.qty,
						"item_image_url": item.img,
						"item_url": `https://www.stilepersonale.com/product/${item.code}`
					});
					amoutTotal += ((item.price * item.qty) * 100);
				});

				let affirmOBjetct = {
					"merchant": {
						"user_confirmation_url": `${window.location.origin}/confirm-affirm`,
						"user_cancel_url": `${window.location.origin}/cancel-affirm`,
						"user_confirmation_url_action": "GET",
						"name": `${orderDetail.firstName} ${orderDetail.lastName}`,
						"public_api_key": affirmPKey
					},
					"shipping": {
						"name": {
							"first": orderDetail.firstName,
							"last": orderDetail.lastName
						},
						"address": {
							"line1": orderDetail.address1,
							"line2": orderDetail.address2,
							"city": orderDetail.city,
							"state": orderDetail.province,
							"zipcode": orderDetail.zip,
							"country": "USA"
						},
						"phone_number": orderDetail.phone,
						"email": orderDetail.email
					},
					"billing": {
						"name": {
							"first": orderDetail.firstNamePay,
							"last": orderDetail.lastNamePay
						},
						"address": {
							"line1": orderDetail.address1Pay,
							"line2": orderDetail.address2Pay,
							"city": orderDetail.cityPay,
							"state": orderDetail.provincePay,
							"zipcode": orderDetail.zipPay,
							"country": "USA"
						},
						"phone_number": orderDetail.phonePay,
						"email": orderDetail.emailPay
					},
					"items": lineItemsAffirm,
					"discounts": {},
					"metadata": {
						"shipping_type": "UPS Ground",
						"mode": "modal"
					},
					"order_id": orderIdSend,
					"currency": "USD",
					"shipping_amount": 0,
					"tax_amount": 0,
					"total": parseInt(amoutTotal)
				};

				window.affirm.checkout(affirmOBjetct);
				window.affirm.checkout.open()
			}
		}

		const paymentPanama = async () => {
			this.enablePay(false);
			let cartDetailAll = JSON.parse(localStorage.cart);
			let cartDetail = cartDetailAll.filter(cart => cart.country === pais);
			let descuentosAplicados = (localStorage.descuentosAplicados !== undefined ? JSON.parse(localStorage.descuentosAplicados) : null);

			let amount = 0;
			let total = 0;
			cartDetail.forEach(item => {
				amount += (item.qty * item.price);
				total += (item.qty * item.price);
			});

			let orderDetail = JSON.parse(localStorage.orderDetail);

			let lineItems = [];

			cartDetail.forEach(item => {
				lineItems.push({
					"originalUnitPrice": item.price,
					"quantity": item.qty,
					"taxable": true,
					"title": item.name
				});
			});

			var myHeaders = new Headers();
			myHeaders.append("Content-Type", "application/json");

			var rawOrder = JSON.stringify({
				"email": orderDetail.email,
				"lineItems": lineItems,
				"billingAddress": {
					"address1": orderDetail.address1Pay,
					"address2": orderDetail.address2Pay,
					"city": orderDetail.cityPay,
					"firstName": orderDetail.firstNamePay,
					"lastName": orderDetail.lastNamePay,
					"zip": orderDetail.zipPay,
					"phone": orderDetail.phonePay,
					"province": orderDetail.provincePay,
					"country": orderDetail.countryPay
				},
				"shippingAddress": {
					"address1": orderDetail.address1,
					"address2": orderDetail.address2,
					"city": orderDetail.city,
					"firstName": orderDetail.firstName,
					"lastName": orderDetail.lastName,
					"zip": orderDetail.zip,
					"phone": orderDetail.phone,
					"province": orderDetail.province,
					"country": orderDetail.country
				}
			});

			var requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: rawOrder
			};
			let giftCardCode = (localStorage.giftCardCode !== undefined ? JSON.parse(localStorage.giftCardCode) : null);
			let giftCardCodeExist = false;

			if (giftCardCode !== null) {
				fetch(urlGiftUp + giftCardCode.code,
					{
						method: 'GET',
						headers: headerGiftUp
					})
					.then(response => response.text())
					.then(result => {
						let giftCardCodeJson = JSON.parse(result)
						let giftValue = giftCardCodeJson.remainingValue;
						amount -= parseFloat(giftValue);
						giftCardCodeExist = true;
					})
					.catch(error => console.log(error));
			}


			if (descuentosAplicados != null) {
				let totalD = 0;
				if (descuentosAplicados.all === true) {
					if (descuentosAplicados.tipo_descuento === "value") {
						totalD += parseFloat(descuentosAplicados.valor);
					}
					else if (descuentosAplicados.tipo_descuento === "percentage") {
						let porcentaje = parseFloat(parseFloat(descuentosAplicados.valor) / 100);
						totalD = parseFloat(total * porcentaje);
					}
				}
				else {
					descuentosAplicados.productos.forEach(prodCode => {
						cartDetail.forEach(item => {
							if (prodCode === item.code) {
								if (descuentosAplicados.tipo_descuento === "value") {
									totalD += parseFloat(descuentosAplicados.valor);
								}
								else if (descuentosAplicados.tipo_descuento === "percentage") {
									let totalItem = (item.qty * item.price);
									let porcentaje = parseFloat(parseFloat(descuentosAplicados.valor) / 100);
									totalD = parseFloat(totalItem * porcentaje);
								}
							}
						});

					});
				}

				amount -= parseFloat(totalD);
			}


			await fetch(urlNewOrder, requestOptions)
				.then(response => response.json())
				.then(result => {
					let orderIdSend = result.data.draftOrderCreate.draftOrder.legacyResourceId;
					localStorage.setItem('orderId', result.data.draftOrderCreate.draftOrder.legacyResourceId);

					amount = parseFloat(amount).toFixed(2);

					let bodyData = {
						cclw: PAcclw,
						amount: amount,
						taxAmount: 0.0,
						email: orderDetail.email,
						phone: orderDetail.phonePay,
						address: orderDetail.address1Pay,
						concept: "Orden ID: " + orderIdSend,
						description: "Stile Personale",
						lang: "ES",
						additionalData: { "sessionKount": uuidv4() },
						cardInformation: {
							cardNumber: orderDetail.cardNumber,
							expMonth: orderDetail.expMonth,
							expYear: orderDetail.expYear,
							cvv: orderDetail.cardCvv,
							firstName: orderDetail.firstName,
							lastName: orderDetail.lastName,
							cardType: "VISA"
						}
					};

					let requestOptions = {
						method: 'POST',
						headers: headerPA,
						body: JSON.stringify(bodyData),
						redirect: 'follow'
					};

					fetch(urlPanama, requestOptions)
						.then(response => response.json())
						.then(result => {

							let sendRedirect = result.data.returnUrl;

							if (result.data.status === 0) {
								alert("Su tarjeta fue rechazada");
								this.enablePay(true);
								return false;
							}
							if (result.success === false) {
								alert(result.headerStatus.description);
								this.enablePay(true);
								return false;
							}

							if (result.success === true) {
								let myHeadersSale = new Headers();
								myHeadersSale.append("Content-Type", "application/json");

								let requestOptionsSale = {
									method: 'POST',
									headers: myHeadersSale,
									redirect: 'follow',
									body: JSON.stringify({
										clienteId: orderDetail.email,
										orderId: orderIdSend,
										orderDetail: orderDetail,
										cart: cartDetail
									})
								};
								fetch(urlNewSale, requestOptionsSale)
									.then(response => response.json())
									.then(result => {
										console.log(result);
										if (giftCardCodeExist) {
											fetch(urlGiftUp + giftCardCode.code + "/redeem-in-full",
												{
													method: 'POST',
													headers: headerGiftUp,
													body: JSON.stringify({ "reason": "Pay Order" }),
													redirect: 'follow'
												}
											)
												.then(response => response.text())
												.then(result => console.log(result))
												.catch(error => console.log('error', error));
										}

										alert("Thank you for your purchase..!");

										localStorage.removeItem("cart");
										localStorage.removeItem("orderDetail");
										localStorage.removeItem("orderId");
										localStorage.removeItem("giftCardCode");
										localStorage.removeItem("orderMX");
										localStorage.removeItem("descuentosAplicados");

										window.open(sendRedirect, "_blank");

										setTimeout(() => {
											window.location.href = window.location.origin;
										}, 500);
									})
									.catch(error => console.log('error', error));
							}



						})
						.catch(error => {
							console.log('error', error);
							this.enablePay(true);
						});
				})
				.catch(error => console.log('error', error));

		}
		return (
			<div className="cart-area margin-top-60 margin-bottom-60">
				<div className="container">
					<div className="row">
						<div className="col-lg-5 offset-lg-1 col-md-5 col-sm-12 col-12 justify-content-center">
							<div className="summary margin-top-20 justify-content-center">
								<h6 className="title">{translate('shippingInfo')}</h6>
								<div className="form-group">
									<input
										type="text"
										name="firstName"
										value={firstName}
										className="form-control col-12"
										placeholder={translate('firstName')}
										onChange={this.handleChange}
										required
									/>
								</div>
								<div className="form-group">
									<input
										type="text"
										className="form-control"
										placeholder={translate('lastName')}
										name="lastName"
										value={lastName}
										onChange={this.handleChange}
										required
									/>
								</div>
								<div className="form-group">
									<input
										type="text"
										className="form-control"
										placeholder={translate('address')}
										name="address1"
										value={address1}
										onChange={this.handleChange}
										required
									/>
								</div>
								<div className="form-group">
									<input
										type="text"
										className="form-control"
										placeholder={translate('aptunit')}
										name="address2"
										value={address2}
										onChange={this.handleChange}
									/>
								</div>
								<div className="form-group">
									<label>{translate('country')}</label>
									<input
										type="text"
										className="form-control"
										name="country"
										value={country}
										onChange={this.handleChange}
										disabled
									/>
								</div>
								<div className="form-group">
									{pais === "en_US" ?
										<>
											<label>{translate('province')}</label>
											<select
												placeholder={translate('province')}
												name="province"
												value={province}
												onChange={this.handleChange}
												className="form-control"
											>
												<option value="" >Select a state</option>
												<option value="AL" > Alabama</option>
												<option value="AK" > Alaska</option>
												<option value="AS" > American Samoa</option>
												<option value="AZ" > Arizona</option>
												<option value="AR" > Arkansas</option>
												<option value="CA" > California</option>
												<option value="CO" > Colorado</option>
												<option value="CT" > Connecticut</option>
												<option value="DE" > Delaware</option>
												<option value="DC" > District of Columbia</option>
												<option value="FM" > Federated States of Micronesia</option>
												<option value="FL" > Florida</option>
												<option value="GA" > Georgia</option>
												<option value="GU" > Guam</option>
												<option value="HI" > Hawaii</option>
												<option value="ID" > Idaho</option>
												<option value="IL" > Illinois</option>
												<option value="IN" > Indiana</option>
												<option value="IA" > Iowa</option>
												<option value="KS" > Kansas</option>
												<option value="KY" > Kentucky</option>
												<option value="LA" > Louisiana</option>
												<option value="ME" > Maine</option>
												<option value="MH" > Marshall Islands</option>
												<option value="MD" > Maryland</option>
												<option value="MA" > Massachusetts</option>
												<option value="MI" > Michigan</option>
												<option value="MN" > Minnesota</option>
												<option value="MS" > Mississippi</option>
												<option value="MO" > Missouri</option>
												<option value="MT" > Montana</option>
												<option value="NE" > Nebraska</option>
												<option value="NV" > Nevada</option>
												<option value="NH" > New Hampshire</option>
												<option value="NJ" > New Jersey</option>
												<option value="NM" > New Mexico</option>
												<option value="NY" > New York</option>
												<option value="NC" > North Carolina</option>
												<option value="ND" > North Dakota</option>
												<option value="MP" > Northern Mariana Islands</option>
												<option value="OH" > Ohio</option>
												<option value="OK" > Oklahoma</option>
												<option value="OR" > Oregon</option>
												<option value="PW" > Palau</option>
												<option value="PA" > Pennsylvania</option>
												<option value="PR" > Puerto Rico</option>
												<option value="RI" > Rhode Island</option>
												<option value="SC" > South Carolina</option>
												<option value="SD" > South Dakota</option>
												<option value="TN" > Tennessee</option>
												<option value="TX" > Texas</option>
												<option value="UT" > Utah</option>
												<option value="VT" > Vermont</option>
												<option value="VI" > Virgin Islands</option>
												<option value="VA" > Virginia</option>
												<option value="WA" > Washington</option>
												<option value="WV" > West Virginia</option>
												<option value="WI" > Wisconsin</option>
												<option value="WY" > Wyoming</option>
												<option value="AE" > Armed Forces Africa</option>
												<option value="AA" > Armed Forces Americas</option>
												<option value="AE" > Armed Forces Canada</option>
												<option value="AE" > Armed Forces Europe</option>
												<option value="AE" > Armed Forces Middle East</option>
												<option value="AP" > Armed Forces Pacific</option>
											</select>
										</>
										:
										<input
											type="text"
											className="form-control"
											placeholder={translate('province')}
											name="province"
											value={province}
											onChange={this.handleChange}
											required
										/>
									}
								</div>
								<div className="form-group">
									<input
										type="text"
										className="form-control"
										placeholder={translate('city')}
										name="city"
										value={city}
										onChange={this.handleChange}
									/>
								</div>
								<div className="form-group">
									<input
										type="text"
										className="form-control"
										placeholder={translate('zip')}
										name="zip"
										value={zip}
										onChange={this.handleChange}
										required
									/>
								</div>
								<div className="form-group">
									<input
										type="text"
										className="form-control"
										placeholder={translate('email')}
										name="email"
										value={email}
										onChange={this.handleChange}
										disabled
									/>
								</div>
								<div className="form-group">
									<input
										type="text"
										className="form-control"
										placeholder={translate('phone')}
										name="phone"
										value={phone}
										onChange={this.handleChange}
										required
									/>
								</div>
							</div>
						</div>
						<div className="col-lg-5 col-md-5 col-sm-12 col-12 d-flex flex-column justify-content-start">
							<div className="summary margin-top-20 justify-content-center">
								<h6 className="title">
									{translate('paymentInfo')}
								</h6>
								<div className="form-group form-check">
									<input type="checkbox" style={{width:"auto"}} className="form-check-input mr-2" checked={sameShippping} onChange={(e) => changePaymentAddress(e)} />
									<label className="form-check-label ml-2">{translate('sameShippingAddress')}</label>
								</div>
								{sameShippping === false && <>
									<div className="form-group">
										<input
											type="text"
											name="firstName"
											value={firstNamePay}
											className="form-control col-12"
											placeholder={translate('firstName')}
											onChange={this.handleChangePay}
											required
										/>
									</div>
									<div className="form-group">
										<input
											type="text"
											className="form-control"
											placeholder={translate('lastName')}
											name="lastName"
											value={lastNamePay}
											onChange={this.handleChangePay}
											required
										/>
									</div>
									<div className="form-group">
										<input
											type="text"
											className="form-control"
											placeholder={translate('address')}
											name="address1"
											value={address1Pay}
											onChange={this.handleChangePay}
											required
										/>
									</div>
									<div className="form-group">
										<input
											type="text"
											className="form-control"
											placeholder={translate('aptunit')}
											name="address2"
											value={address2Pay}
											onChange={this.handleChangePay}
										/>
									</div>
									<div className="form-group">
										<input
											type="text"
											className="form-control"
											placeholder={translate('country')}
											name="country"
											value={countryPay}
											onChange={this.handleChangePay}
										/>
									</div>
									<div className="form-group">
										<input
											type="text"
											className="form-control"
											placeholder={translate('province')}
											name="province"
											value={provincePay}
											onChange={this.handleChangePay}
											required
										/>
									</div>
									<div className="form-group">
										<input
											type="text"
											className="form-control"
											placeholder={translate('city')}
											name="city"
											value={cityPay}
											onChange={this.handleChangePay}
										/>
									</div>
									<div className="form-group">
										<input
											type="text"
											className="form-control"
											placeholder={translate('zip')}
											name="zip"
											value={zipPay}
											onChange={this.handleChangePay}
											required
										/>
									</div>
									<div className="form-group">
										<input
											type="text"
											className="form-control"
											placeholder={translate('phone')}
											name="phone"
											value={phonePay}
											onChange={this.handleChangePay}
											required
										/>
									</div>
								</>
								}
							</div>

							<div className="summary margin-top-20 w-100">
								<h6 className="title">{translate('paymentDetails')}</h6>
								{pais === "es_PA" &&
									<div>
										<div className="form-group">
											<input
												type="text"
												className="form-control"
												placeholder="Numero de tarjeta"
												name="cardNumber"
												value={cardNumber}
												onChange={this.handleChange}
												required
											/>
										</div>

										Fecha de expiracion
										<div className="form-group d-flex">
											<select
												placeholder={"Mes expiracion"}
												name="expMonth"
												value={expMonth}
												onChange={this.handleChange}
												className="form-control"
												style={{ height: 46 }}
											>
												<option value="01">Enero</option>
												<option value="02">Febrero</option>
												<option value="03">Marzo</option>
												<option value="04">Abril</option>
												<option value="05">Mayo</option>
												<option value="06">Junio</option>
												<option value="07">Julio</option>
												<option value="08">Agosto</option>
												<option value="09">Septiembre</option>
												<option value="10">Octubre</option>
												<option value="11">Noviembre</option>
												<option value="12">Diciembre</option>
											</select>
											<input
												type="number"
												className="form-control"
												placeholder="Año"
												name="expYear"
												value={expYear}
												onChange={this.handleChange}
												maxLength={2}
												required
											/>
										</div>
										<div className="form-group">
											<input
												type="number"
												className="form-control"
												placeholder="CVV"
												name="cardCvv"
												maxLength={4}
												value={cardCvv}
												onChange={this.handleChange}
												required
											/>
										</div>
									</div>
								}

								<div className="d-flex justify-content-between  flex-column margin">
									{(giftCardCheck() === false && pais === "en_US") &&
										<div>
											{tabActive === 2 &&
												<div className="row">
													<div className={`col-md-12 `} onClick={() => { this.setState({ tabActive: 1 }) }}>
														<button className="btn btn-secondary w-100 pt-4 pb-4 mb-4">With Credit / Debit Card</button>
													</div>
													<div className={`col-md-12`} onClick={() => { this.setState({ tabActive: 2 }) }}>
														<button className="btn p-0 w-100" id="button-payment" onClick={(e) => {
															this.enablePay(false);
															confirPayAffirm();
															this.setState({ paymentProcess: true })
														}}>
															<img src={"https://cdn-assets.affirm.com/images/buttons/checkout/42x205-blue.svg"} alt="" width="100%" />
														</button>
													</div>
												</div>
											}
											<br />
											{tabActive === 1 &&
												<div>
													<Elements stripe={stripePromise}>
														<PaymentForm />
													</Elements>

													<button className="btn btn-checkout mt-0" id="button-payment" onClick={(e) => {
														this.setState({ tabActive: 2  })
													}}>
														Cancel
													</button>
												</div>

											}
											{/* {tabActive === 2 &&
												<button className="btn p-0 w-100" id="button-payment" onClick={(e) => {
													this.enablePay(false);
													confirPayAffirm();
													this.setState({ paymentProcess: true })
												}}>
													<img src={"https://cdn-assets.affirm.com/images/buttons/checkout/42x205-blue.svg"} alt="" width="100%" />
												</button>
											} */}
										</div>
									}

									{(pais === "es_MX") &&
										<Elements stripe={stripeMXPromise}>
											<PaymentMXForm />
										</Elements>
									}

									{(giftCardCheck() === false && pais === "es_PA") &&
										<button className="btn btn-checkout" id="button-payment" onClick={(e) => {
											this.enablePay(false);
											paymentPanama();
											e.eventPreventDefault();
											this.setState({ paymentProcess: true })
										}}>
											Confirmar Pago
										</button>
									}

									{(giftCardCheck() === true && (pais === "en_US" || pais === "es_PA")) &&
										<button className="btn btn-checkout" id="button-payment" onClick={() => confirmPayGiftCard()}>
											{translate('payOnlyGiftCard')}
										</button>
									}

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}



const mapStateToProps = (state) => {
	return {
		userInfo: state.user.userInfo,
		pais: state.Intl.locale,
		country: state.Intl.locale
	}
}

export default connect(mapStateToProps)(withTranslate(Checkout));
