/**
 * created by Inspire UI @author(dang@inspireui.com)
 * @format
 */

// cart
const CART_ADD_ITEM = "cart/ADD_ITEM";
const CART_REMOVE_ITEM = "cart/REMOVE_ITEM";
const CART_DELETE_ITEM = "cart/DELETE_ITEM";
const CART_EMPTY = "cart/EMPTY";
const CART_FETCHING = "cart/FETCHING";
const CART_CLEAN = "cart/clean";

// checkout
const CHECKOUT_CHECK_FETCHING = "checkout/CHECK_FETCHING";
const CHECKOUT_CHECK_SUCCESS = "checkout/CHECK_SUCCESS";
const CHECKOUT_CHECK_FAILURE = "checkout/CHECK_FAILURE";

const CHECKOUT_CREATE_FETCHING = "checkout/CREATE_FETCHING";
const CHECKOUT_CREATE_SUCCESS = "checkout/CREATE_SUCCESS";
const CHECKOUT_CREATE_FAILURE = "checkout/CREATE_FAILURE";
const CHECKOUT_ADD_FETCHING = "checkout/ADD_FETCHING";
const CHECKOUT_ADD_SUCCESS = "checkout/ADD_SUCCESS";
const CHECKOUT_ADD_FAILURE = "checkout/ADD_FAILURE";
const CHECKOUT_UPDATE_FETCHING = "checkout/UPDATE_FETCHING";
const CHECKOUT_UPDATE_SUCCESS = "checkout/UPDATE_SUCCESS";
const CHECKOUT_UPDATE_FAILURE = "checkout/UPDATE_FAILURE";
const CHECKOUT_REMOVE_FETCHING = "checkout/REMOVE_FETCHING";
const CHECKOUT_REMOVE_SUCCESS = "checkout/REMOVE_SUCCESS";
const CHECKOUT_REMOVE_FAILURE = "checkout/REMOVE_FAILURE";

const CHECKOUT_APPLY_COUPON_FETCHING = "checkout/coupon/APPLY_FETCHING";
const CHECKOUT_APPLY_COUPON_SUCCESS = "checkout/coupon/APPLY_SUCCESS";
const CHECKOUT_APPLY_COUPON_FAILURE = "checkout/coupon/APPLY_FAILURE";

const CHECKOUT_REMOVE_COUPON_FETCHING = "checkout/coupon/REMOVE_FETCHING";
const CHECKOUT_REMOVE_COUPON_SUCCESS = "checkout/coupon/REMOVE_SUCCESS";
const CHECKOUT_REMOVE_COUPON_FAILURE = "checkout/coupon/REMOVE_FAILURE";

const CHECKOUT_UPDATE_SHIPPING_ADDRESS_FETCHING =
  "checkout/shipping/UPDATE_ADDRESS_FETCHING";
const CHECKOUT_UPDATE_SHIPPING_ADDRESS_SUCCESS =
  "checkout/shipping/UPDATE_ADDRESS_SUCCESS";
const CHECKOUT_UPDATE_SHIPPING_ADDRESS_FAILURE =
  "checkout/shipping/UPDATE_ADDRESS_FAILURE";

const CHECKOUT_UPDATE_SHIPPING_LINE_FETCHING =
  "checkout/shipping/UPDATE_LINE_FETCHING";
const CHECKOUT_UPDATE_SHIPPING_LINE_SUCCESS =
  "checkout/shipping/UPDATE_LINE_SUCCESS";
const CHECKOUT_UPDATE_SHIPPING_LINE_FAILURE =
  "checkout/shipping/UPDATE_LINE_FAILURE";

const CHECKOUT_LINK_USER_FETCHING = "checkout/user/LINK_FETCHING";
const CHECKOUT_LINK_USER_SUCCESS = "checkout/user/LINK_SUCCESS";
const CHECKOUT_LINK_USER_FAILURE = "checkout/user/LINK_FAILURE";

const ORDER_FETCHING = "order/FETCHING";
const ORDER_SUCCESS = "order/SUCCESS";
const ORDER_FAILURE = "order/FAILURE";

export {
  // cart
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_DELETE_ITEM,
  CART_EMPTY,
  CART_FETCHING,
  CART_CLEAN,
  // checkout
  CHECKOUT_CHECK_FETCHING,
  CHECKOUT_CHECK_SUCCESS,
  CHECKOUT_CHECK_FAILURE,
  CHECKOUT_CREATE_FETCHING,
  CHECKOUT_CREATE_SUCCESS,
  CHECKOUT_CREATE_FAILURE,
  CHECKOUT_ADD_FETCHING,
  CHECKOUT_ADD_SUCCESS,
  CHECKOUT_ADD_FAILURE,
  CHECKOUT_UPDATE_FETCHING,
  CHECKOUT_UPDATE_SUCCESS,
  CHECKOUT_UPDATE_FAILURE,
  CHECKOUT_REMOVE_FETCHING,
  CHECKOUT_REMOVE_SUCCESS,
  CHECKOUT_REMOVE_FAILURE,
  CHECKOUT_APPLY_COUPON_FETCHING,
  CHECKOUT_APPLY_COUPON_SUCCESS,
  CHECKOUT_APPLY_COUPON_FAILURE,
  CHECKOUT_REMOVE_COUPON_FETCHING,
  CHECKOUT_REMOVE_COUPON_SUCCESS,
  CHECKOUT_REMOVE_COUPON_FAILURE,
  CHECKOUT_UPDATE_SHIPPING_ADDRESS_FETCHING,
  CHECKOUT_UPDATE_SHIPPING_ADDRESS_SUCCESS,
  CHECKOUT_UPDATE_SHIPPING_ADDRESS_FAILURE,
  CHECKOUT_UPDATE_SHIPPING_LINE_FETCHING,
  CHECKOUT_UPDATE_SHIPPING_LINE_SUCCESS,
  CHECKOUT_UPDATE_SHIPPING_LINE_FAILURE,
  CHECKOUT_LINK_USER_FETCHING,
  CHECKOUT_LINK_USER_SUCCESS,
  CHECKOUT_LINK_USER_FAILURE,
  // order
  ORDER_FETCHING,
  ORDER_SUCCESS,
  ORDER_FAILURE,
};
